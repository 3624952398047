
const otpErrorMessages = [
    {
        "code" : "invalid_details",
        "message" : "General error for invalid request data.",
        "retry": true
    },
    {
        "code" : "request_too_soon",
        "message" : "A code was issued less than 3 minutes ago.",
        "retry": true
    },
    {
        "code" : "number_blocked",
        "message" : "The mobile number is blocked due to suspicious activity.",
        "retry": false
    },
    {
        "code" : "too_many_requests",
        "message" : "Maximum number of codes issued to this mobile number in the last hour.",
        "retry": false
    },
    {
        "code" : "too_soon",
        "message" : "A code has already been issued to this mobile number in the last few minutes.",
        "retry": false
    },
    {
        "code" : "max_attempts_exceeded",
        "message" : "Code has been guessed incorrectly too many times.",
        "retry": false
    },
    {
        "code" : "handset_blacklisted",
        "message" : "The mobile number is permanently blacklisted. It is most likely not assigned to a handset.",
        "retry": true
    },
    {
        "code" : "handset_unavailable",
        "message" : "The mobile number is temporarily unavailable. It does either not respond or is switched off.",
        "retry": true
    },
    {
        "code" : "invalid_code",
        "message" : "Please check your code and try again.",
        "retry": true
    },
    {
        "code" : "identifier_not_found",
        "message" : "The identifier is invalid or has expired.",
        "retry": false
    },
    {
        "code" : "cs-otp-wrong-phonenumber-format",
        "message" : "Phone Number is in the wrong international format.",
        "retry": true
    },
    {
        "code" :  "cs-prize-claim-otp-wrong-code",                   
        "message" : "The code you entered is incorrect. ",
        "retry": true
    },
    {
        "code" :  "core-captcha",
        "message" : "Our anti-spam filters have been unable to verify that you're a real user. Please try again.",
        "retry": true
    },
    {
        "code" :  "cs-competition-categories-already-used",
        "message" : "You have already entered the promotion today, come back tomorrow.",
        "retry": false
    },
    {
        "code" :  "cs-prize-claim-otp-wrong-email",
        "message" : "Oops your entry and claim details don't match! Please try again. The email address you entered is not the same as the email address you used to enter the promotion.",
        "retry": true
    },
    {
        "code" :  "cs-entry-otp-wrong-phonenumber",
        "message" : "This email has been used with a different phone number before.",
        "retry": true
    },
    {
        "code" :  "cs-entry-otp-wrong-email",
        "message" : "This phone number has been used with a different email before.",
        "retry": true
    }
    

];


export function getOtpErrorMessages(code) {
    var errorContent = otpErrorMessages.filter(function (entry) {
        return entry.code === code;
    });
    return ((errorContent !== null && errorContent !== undefined) ? errorContent[0] : null);
}
