import React, {useState} from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";

//player images
import keyvisVideo from 'assets/videos/LOGO_ANIM_GOLD_6s_WEB_HEADER_v001_2.mp4';
import keyvisVideoMob from 'assets/videos/LOGO_ANIM_GOLD_6s_WEB_HEADER_v001_2_mob.mp4';

import keyvisPreloadDesk from 'assets/images/campaign/keyvis/keyvis_preload_desk.jpg';
import keyvisPreloadMob from 'assets/images/campaign/keyvis/keyvis_preload_mob.jpg';

import kvHeading from 'assets/images/campaign/keyvis/keyvis_heading.png';
import kvHeading2x from 'assets/images/campaign/keyvis/keyvis_heading@2x.png';
import kvHeadingMob from 'assets/images/campaign/keyvis/keyvis_heading_mob.png';
import kvHeadingMob2x from 'assets/images/campaign/keyvis/keyvis_heading_mob@2x.png';


//roi 
import keyvis_bg_dt from 'assets/images/campaign/keyvis/roi/keyvis_bg_dt.jpg';
import keyvis_bg_dt_2x from 'assets/images/campaign/keyvis/roi/keyvis_bg_dt@2x.jpg';
import keyvis_bg_tab from 'assets/images/campaign/keyvis/roi/keyvis_bg_tab.jpg';
import keyvis_bg_tab_2x from 'assets/images/campaign/keyvis/roi/keyvis_bg_tab@2x.jpg';
import keyvis_bg_mob from 'assets/images/campaign/keyvis/roi/keyvis_bg_mob.jpg';
import keyvis_bg_mob_2x from 'assets/images/campaign/keyvis/roi/keyvis_bg_mob@2x.jpg';

import keyvis_headline_dt from 'assets/images/campaign/keyvis/roi/keyvis_headline_dt.png';
import keyvis_headline_dt_2x from 'assets/images/campaign/keyvis/roi/keyvis_headline_dt@2x.png';
import keyvis_headline_tab from 'assets/images/campaign/keyvis/roi/keyvis_headline_tab.png';
import keyvis_headline_tab_2x from 'assets/images/campaign/keyvis/roi/keyvis_headline_tab@2x.png';
import keyvis_headline_mob from 'assets/images/campaign/keyvis/roi/keyvis_headline_mob.png';
import keyvis_headline_mob_2x from 'assets/images/campaign/keyvis/roi/keyvis_headline_mob@2x.png';


import './KeyVisual.scss';
import useEffectOnce from 'hooks/useEffectOnce';


export default (props) => {

  let [parallaxMargin, setParallaxMargin] = useState(22);
  let [parallaxPercentage, setParallaxPercentage] = useState(80);
  let [offsetParPercentage, setOffsetParPercentage] = useState(90);
  let [strength, setStrength] = useState(100);
  let [isDesk, setIsDesk] = useState(window.matchMedia("only screen and (min-width: 640px)").matches);

  useEffectOnce(() => {
    setIsDesk(window.matchMedia("only screen and (min-width: 640px)").matches);
    handleResize();
  });

  const classes = {
    "keyvis": true,
    "keyvis--roi": !props.video
  }

  const handleResize = () => {
    
    var currIsDesk = window.matchMedia("only screen and (min-width: 640px)").matches;

    setIsDesk(currIsDesk);

    if (currIsDesk) {
      setParallaxMargin(0);
      setParallaxPercentage(140);
      setOffsetParPercentage(140);
      setStrength(100);
    } 
  }


  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  window.addEventListener('resize', handleResize);

  return (
    <section className="layout-wrapper home__banner layout-wrapper--bottom-ellipse">
      <div className={classList(classes)}>

        {props.video ? (   
          <>
            <video 
                key={isDesk ? "1" : "2"}
                preload="auto"
                autoPlay="autoplay" 
                muted 
                loop
                playsInline 
                width="100%"
                height="100%"
                poster={isDesk ? keyvisPreloadDesk : keyvisPreloadMob}
              >
                    <source src={isDesk ? keyvisVideo : keyvisVideoMob} type="video/mp4" />
              </video> 
              
              <picture>
                <source media="(min-width: 640px)" srcSet={kvHeading2x +" 2x, "+ kvHeading + " 1x"} type='image/png' />
                <img src={kvHeadingMob} srcSet={kvHeadingMob2x +" 2x, "+ kvHeadingMob + " 1x"} className='keyvis__heading' alt="Over 200,000 unforgettable prizes and rewars." />
              </picture>
          </>  
        ) : (

      <Parallax
        strength={strength}
        renderLayer={percentage => (
         <>
          <div className="keyvis__bg"
            style={{
              transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
            }}
          >
            <picture>
              <source media="(min-width:992px)" type="image/png" srcSet={keyvis_bg_dt_2x  + " 2x, " + keyvis_bg_dt  + " 1x"}  />
              <source media="(min-width:640px)" type="image/png" srcSet={keyvis_bg_tab_2x  + " 2x, " + keyvis_bg_tab + " 1x"}  />
              <img src={keyvis_bg_mob} srcSet={keyvis_bg_mob_2x  + " 2x, " + keyvis_bg_mob + " 1x"} alt="Win a day to remember: over 200000 unforgettable prizes & rewards" />
            </picture>
          </div>

          <picture>
            <source type="image/png" srcSet={keyvis_headline_dt_2x  + " 2x, " + keyvis_headline_dt  + " 1x"} media="(min-width:992px)" />
            <source type="image/png" srcSet={keyvis_headline_tab_2x  + " 2x, " + keyvis_headline_tab + " 1x"} media="(min-width:640px)" />
            <img src={keyvis_headline_mob} srcSet={keyvis_headline_mob_2x  + " 2x, " + keyvis_headline_mob + " 1x"} alt="Win a day to remember: over 200000 unforgettable prizes & rewards"
              className='keyvis__heading' />
          </picture>
          </>
        )}
        />
        )


      }
  
      </div>
    </section>
  )
}