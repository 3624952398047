import ValidationErrorCollection from './ValidationErrorCollection';

function validate(command) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  //console.log(command);
  required('recaptcha2', 'Please confirm you are not a robot');
  
  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
