import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';
import prizeClaimCaptchaValidator from 'validators/prizeClaimCaptchaValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Link from 'components/Link/Link';

import Form, { createOnChangeHandler } from './Form';
import analyticsService from 'services/analyticsService';

import photoEntryService from 'services/photoEntryService';
import loggerService from 'services/loggerService';

import spinnerImage from 'assets/images/spinner3.gif';

import './Claim.scss';
import './Form.scss';


export default (props) => {
  let settingsContext = useSettingsContext();
  let formClaimRef = useRef();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let [settings, setSettings] = React.useContext(SettingsContext);
  let [token, setToken] = useState(props.token);
  let [otpAvilable, setOtpAvilable] = useState(props.otpAvilable);
  let [otp, setOtp] = useState(props.otpAvilable);
  let [claim, setClaim] = useState();  
  let [sent, setSent] = useState(false);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState(null);
  let [county, setCounty] = useState(null);
  let [country, setCountry] = useState(null);
  let [postcode, setPostcode] = useState(null); 
  let [phasePublicId, setPhasePublicId] = useState('');

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [prizePublicId, setPrizePublicId] = useState("");
  let [prizeCategory, setPrizeCategory] = useState('');
  let [isTier1Prize, setIsTier1Prize] = useState(false);

  let imageLimitInMB = 25;
  let [showUploadIdModal, setShowUploadIdModal] = useState(false);
  let [fileId, setFileId] = useState('');
  let [fileName, setFileName] = useState('');
  let [fileToken, setFileToken] = useState('');
  let [showFileId, setShowFileId] = useState(true);
  let [imageId, setImageId] = useState(null);
  let [imageType, setImageType] = useState('');
  let [imageSize, setImageSize] = useState(null);
  let [file, setFile] = useState(null);
  let [showImgSizeValidation, setShowImgSizeValidation] = useState(false);
  let [showImgTypeValidation, setShowImgTypeValidation] = useState(false);

  let [isSubmitting, setIsSubmitting] = useState(false);

  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  /*
  useEffect(() => {   
    if(claim !== undefined && claim.canClaim !== undefined && claim.canClaim === true)
    {
      window.setTimeout(function(){
        try 
        {
          let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
          if ($grecaptchav2) {
           
              window.grecaptcha.ready(() => {
                  window.grecaptcha.render($grecaptchav2, {
                'sitekey' : '6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB',
                'callback': handleReCaptchaV2,
                  'expired-callback': handleReCaptchaV2Expired
                  });
            });
          }
        }catch{}

      }, 1000);
    
    }
  
}, [claim]);*/

  useEffect(() => {
    document.body.classList.remove("overflow-hidden-mediumdown");
    
    async function fetchData() {

      await prizeClaimService.getPrizeClaim(token)
        .then((data) => {                
            if(data) {                    
                setClaim(data);
                if(data.prize) {
                  setPrizeCategory(data.prize.category.title);
                  setPrizePublicId(data.prize.publicId);   
                  setIsTier1Prize(data.prize.category.publicId === "tier-1");       
                }
                setCountry(data.country);
                setPhasePublicId(data.phasePublicId);
            } 
        })
        .catch(err => console.log(err)) ;
    }

    if (token && !claim) {
      fetchData();
    }
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }
  
  const createCommand = () => {
    let command = {
      firstName,
      lastName,
      address1,
      address2,
      town,
      county,
      country,
      postcode : postcode ? postcode.toUpperCase() : postcode,
      token,
      recaptcha2,
      phaseId: phasePublicId,
      prizePublicId,
      otpAvilable: props.otpAvilable ? props.otpAvilable : false,
      otp: props.otp ? props.otp : "",
      flowId: props.flowId ? props.flowId : null,
      entryFlowToken: props.entryFlowToken ? props.entryFlowToken : null,
      fileToken,
      fileName,
      imageId,
      imageSize,
      imageType
    };

    return command;
  }

  var onUploadFile = (e) => {
    setFileId(URL.createObjectURL(e.target.files[0]));    
    setImageId(e.target.files[0]);
    setImageType(e.target.files[0].type);
    setImageSize(e.target.files[0].size);
    setFileName(e.target.files[0].name);    

    setFile(e.target.files[0]);


    if((e.target.files[0].size / (1024 * 1024)) > imageLimitInMB){
      setShowImgSizeValidation(true);
    } else {
      setShowImgSizeValidation(false);
    }

    if(!["image/png", "image/jpeg", "image/gif", "image/jpeg"].includes(e.target.files[0].type)){
      setShowImgTypeValidation(true);
      console.log("image format not permitted");
    } else {
      setShowImgTypeValidation(false);
    }


    async function fetchData() {
      window.setTimeout(function(){
        try 
        {
          let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
          if ($grecaptchav2) {
           
              window.grecaptcha.ready(() => {
                  window.grecaptcha.render($grecaptchav2, {
                'sitekey' : '6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB',
                'callback': handleReCaptchaV2,
                  'expired-callback': handleReCaptchaV2Expired
                  });
            });
          }
        }catch{}
  
      }, 1000);

     // let result = await photoEntryService.getResult(e.target.files[0], country); 
      await photoEntryService.getResult(e.target.files[0], country)
      .then((result) => {                
          if(result) {                    
            setFileToken(result); 

            setShowFileId(!showFileId);
          } 
      })
      .catch(err => console.log(err)) ;
    }

    fetchData();
    setShowFileId(!showFileId);
    
  }

  var onRetakeUploadFile = (e) => {
    setFileToken('');
    //setFile(null);   
    //setShowFileId(true); 

    // createOnChangeHandler(validation, 'imageSize', setFileId, URL.createObjectURL(e.target.files[0]))
    // createOnChangeHandler(validation, 'imageType', setFileId, URL.createObjectURL(e.target.files[0]))

    // createOnChangeHandler(validation, 'imageSize', setImageSize)
    // createOnChangeHandler(validation, 'imageType', setImageType)
    
    setImageId(e.target.files[0]);
    setFileId(URL.createObjectURL(e.target.files[0]));
    setFileName(e.target.files[0].name);
    setImageType(e.target.files[0].type);
    setImageSize(e.target.files[0].size);
    
    setFile(e.target.files[0]);    

    if((e.target.files[0].size / (1024 * 1024)) > imageLimitInMB){
      setShowImgSizeValidation(true);
    } else {
      setShowImgSizeValidation(false);
    }

    if(!["image/png", "image/jpeg", "image/gif", "image/jpeg"].includes(e.target.files[0].type)){
      setShowImgTypeValidation(true);
      console.log("image format not permitted");
    } else {
      setShowImgTypeValidation(false);
    }

    async function fetchData() {
      //let result = await photoEntryService.getResult(e.target.files[0], country);  
      await photoEntryService.getResult(e.target.files[0], country)
      .then((result) => {                
          if(result) {                    
            setFileToken(result); 

            setShowFileId(false);
            
          } 
      })
      .catch(err => console.log(err)) ;
    }

    fetchData();
  }


  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  
  async function submitForm() {

    let model= createCommand;    

    let service = prizeClaimService.addPrizeClaim;
    let validator = prizeClaimValidator;
    let onValidation= setValidation;
    
  
    if (isSubmitting) return;

    try {
      let isClientValidationSuccessful = runClientValidation(model());

      if (!isClientValidationSuccessful) return;

      setShowUploadIdModal(true); //MB

    } catch (ex) {
      let errors = new ValidationErrorCollection();
      errors.addGenericError();
      onValidation({ errors, acknowledged: false });
      
      loggerService.logError(ex);
    }

    function runClientValidation(command) {
      let clientValidationResult = validator.validate(command, settings);

      onValidation({ errors: clientValidationResult, acknowledged: false });

      return !clientValidationResult.hasErrors;
    }

  }

  const onFormSuccess = () => {
    analyticsService.logEvent("form_submit", "claim_tier1_submit", {
      location: "claim_tier1_page",
      label: "claim tier 1 prize",
      url: prizeCategory
    });

    return setSent(true);
  }

  const onDismissModal = () => {

    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    
    setShowUploadIdModal(false);
    document.body.classList.remove("overflow-hidden-mediumdown");
  }

  const onDismissFileModal = () => {
    setFileId("");    
    setImageId("");
    setFileName("");
    setImageType("");
    setImageSize("");
    setFileToken('');
    setFile(null);   
    setShowFileId(true); 
    setShowImgSizeValidation(false);
    setRecaptcha2(null);

    setShowUploadIdModal(false);
    document.body.classList.remove("overflow-hidden-mediumdown");

    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  const onClickModalButton = () => {     
    submitForm();
    setShowUploadIdModal(true);
  }


  const handleModalFormSubmit = (event) => { 
    let model= createCommand;    
    let captchaValidator = prizeClaimCaptchaValidator;
    let onValidation= setValidation;
    
    if (isSubmitting) return;

    try {
      let isClientValidationSuccessful = runClientValidation(model());
    
      if(isClientValidationSuccessful){
        setTimeout(function(){
          setIsSubmitting(true);
          formClaimRef.current.requestSubmit();
        }, 500);
      } 

    } catch (ex) {
      let errors = new ValidationErrorCollection();
      errors.addGenericError();
      onValidation({ errors, acknowledged: false });
      
      loggerService.logError(ex);
    }

    function runClientValidation(command) {
      let clientValidationResult = captchaValidator.validate(command, settings);
      onValidation({ errors: clientValidationResult, acknowledged: false });
      return !clientValidationResult.hasErrors;
    }
  }


  if (!claim.canClaim) {
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry! | Cadbury - Win a Day to Remember</title>
      </Helmet>
        <section className="layout-general layout-general--full layout-wrapper">
          <div className="layout-container layout-container--slim layout-container--center">
            <h1 className="type-h2">Sorry!</h1>
            <div className="form__intro">
              <p >{claim.error.message}</p>
            </div>  
            <Link 
              analytics={{evnt: "button_click", category: "internal_link", location: "error claim page", label: "home page"}}
              href="/" route lozenge title="Home Page" >Back to Home Page</Link>
          </div>
        </section>
      </>
    )
  }

  return (  
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Claim your prize | Cadbury - Win a Day to Remember</title>
    </Helmet>
    <section className="layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center claim">
        { !sent ? 
          (
            <>
            
            <h1 className="type-h2">Claim your day to remember!</h1>
            <div className="form__intro">
              <p>Congrats again. Now please complete your details to claim your voucher reward, this is essential!</p> 
              <p>Fields marked with * are mandatory</p>
            </div>
                
            
            <Form 
              id="claim"
              submitTitle={isTier1Prize ? "Upload ID" : "Claim"}
              formClass="form form--slim"
              formRef={formClaimRef}
              onSuccess={onFormSuccess}
              command={{
                model: createCommand,
                service: prizeClaimService.addPrizeClaim
              }}
              validation={{
                validator: prizeClaimValidator,
                onValidation: setValidation
              }}
              showModalButton={isTier1Prize}
              onClickModalButton={isTier1Prize && onClickModalButton}
              policyContent={isTier1Prize ? `<p>Please upload your proof of identity in the form of a driving license, passport or utility bill.</p><p>Max file image upload 25MB, accepted file formats JPG, PNG, GIF, JPEG.</p>` : ""}
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken']}
                />
              }

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* First name"
                  id="firstName"
                  onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
                  value={firstName}
                  hasErrors={!!validation.errors.firstName}
                  maxCharLenght ={60}
                />
                <ValidationMessage errors={validation.errors.firstName} />
              </fieldset>
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* Last name"
                  id="lastName"
                  onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
                  value={lastName}
                  hasErrors={!!validation.errors.lastName}
                  maxCharLenght ={60}
                />
                <ValidationMessage errors={validation.errors.lastName} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* Address line 1"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                  maxCharLenght ={150}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="Address line 2"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                  maxCharLenght ={150}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* Town/City"
                  id="town"
                  onChange={createOnChangeHandler(validation, 'town', setTown)}
                  value={town}
                  hasErrors={!!validation.errors.town}
                  maxCharLenght ={60}
                />
                <ValidationMessage errors={validation.errors.town} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                  maxCharLenght ={60}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label={ country === "ROI" ? `* Eircode`:`* Postcode` }
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                  maxCharLenght ={60}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>       

              {/* 
              <fieldset className="form__fieldset form__fieldset--captcha">
                <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
                <div class="g-recaptcha-v2" data-sitekey="6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB"
                  data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                <ValidationMessage errors={validation.errors.recaptcha2} /> 
              </fieldset>
              */}


              {(validation.errors.hasErrors && !validation.acknowledged && 
                !validation.errors.imageSize && 
                !validation.errors.ImageType && 
                !validation.errors.FileToken && 
                !validation.errors.recaptcha2) &&
                <Modal 
                  panel 
                  hideClose
                  icon={AlertIcon}
                  alt="Warning sign"
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['RecaptchaToken']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (
                      <Button title="Back to homepage" href="/" />
                    )
                  }
                </Modal>
              }


              {
              //(showUploadIdModal && !validation.errors.recaptcha2) &&
              ((showUploadIdModal && !validation.errors.hasErrors)
              || (validation.errors.hasErrors && (validation.errors.imageSize || validation.errors.imageType || validation.errors.fileToken || validation.errors.recaptcha2)) ) &&
              <Modal
                panel
                icon={fileId}
                roundedIcon
                borderedIcon
                hideTitle={showFileId}
                hideImage={showFileId}
                onDismiss={onDismissFileModal}
                title="Select ID Upload"                 
              >

                {
                  !showFileId && !showImgSizeValidation && !showImgTypeValidation && fileToken ==='' &&
                  <div className='validation-message'>
                      <img className="spinner" src={spinnerImage} alt="uploading spinner image" />
                  </div>
                }

                <div className='captcha-container'>
                  <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
                  <div class="g-recaptcha-v2" data-sitekey="6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB"
                    data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                  <ValidationMessage errors={validation.errors.recaptcha2} /> 
                </div>


                <label htmlFor="fileId" className={'button button--camera' + (showFileId ? " button--toload" : "")} >
                  <span>
                  {showFileId ? "Files" : "Re-take"}
                  <input type="file"
                    value=""
                    className='sr-only'
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    onChange={showFileId ? onUploadFile : onRetakeUploadFile}
                    id="fileId" name="fileId"></input>
                    </span>
                </label>                      

                {
                  !showFileId ? (<>
                   
                    <Button type='button' className='button--camera' 
                    onClick={handleModalFormSubmit}
                    title={isSubmitting ? 'Submitting...' : 'Submit' }
                    disabled={(showImgTypeValidation || showImgSizeValidation || fileToken === '') ? true : false}
                    />
                  </>
                  ):(
                    <div className='modal__disclaimer'>
                      <p>Please upload your proof of identity in the form of a driving license, passport or utility bill.</p>
                      <p>Max file image upload 25MB, accepted file formats JPG, PNG, GIF, JPEG.</p>
                    </div>
                  )
                }          
                  {
                    showImgSizeValidation &&
                    <div className='validation-message'>
                      The file is too large. The maximum file size is {imageLimitInMB}MB
                    </div>    
                  }

                  {
                    showImgTypeValidation &&
                    <div className='validation-message'>
                      The file format is not permitted. Accepted file formats are JPG, PNG, GIF, JPEG.
                    </div>    
                  }                       

              </Modal>

            }
            </Form>
          
            </>
          ) : (
            <>
              <h1 className="type-h2 type-h2--max">Thank you for claiming.</h1>
              <div className="form__intro">
                <p>Thank you for claiming your Day to Remember and uploading your proof of identity. Our team will verify your details and you will receive another email from us within the next 7 days letting you know if your claim has been verified.</p>
                <p>To find out more about our Cadbury products and other promotions, please click below.</p>
              </div>
              {
                country !== "ROI" ? (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 1 page", label: "cadbury.co.uk"}}
                  to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
              
                ) : (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 1 page", label: "cadbury.ie"}}
                  to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
                )
              }
            </>
          )
        }
        
      </div>
    </section>
    </>
  );
}