import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';
import analyticsService from 'services/analyticsService';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import SelectField from 'components/Forms/SelectField';
import DateInput from 'components/Forms/DateInput';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';

export default (props) => {
  

  let [token, setToken] = useState(props.token);
  let [otpAvilable, setOtpAvilable] = useState(props.otpAvilable);
  let [otp, setOtp] = useState(props.otp);
  let [flowId, setFlowId] = useState(props.flowId);
  let [flowToken, setFlowToken] = useState(props.flowToken);
  let [email, setEmail] = useState(props.email);
  let [readOnlyEmail, setReadOnlyEmail] = useState(true);
  let [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  let [readOnlyPhoneNumber, setReadOnlyPhoneNumber] = useState(true);


  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [city, setCity] = useState('');
  let [county, setCounty] = useState('');
  let [postcode, setPostcode] = useState('');
  let [retailer, setRetailer] = useState(settings.country === "ROI" ? '0': null);
  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [thirdPartyOptIn, setThirdPartyOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);
  const [shuffledRetailerList, setShuffledRetailerList] = useState([]);
  let [showValidationModal, setShowValidationModal] = useState(false);


  let [jet2OptIn, setJet2OptIn] = useState(false);

  let [tpArsenalOptIn, setTpArsenalOptIn] = useState(false);
  let [tpChelseaOptIn, setTpChelseaOptIn] = useState(false);
  let [tpLeedsOptIn, setTpLeedsOptIn] = useState(false);
  let [tpLiverpoolOptIn, setTpLiverpoolOptIn] = useState(false);
  let [tpManUtdOptIn, setTpManUtdOptIn] = useState(false);
  let [tpAstonVillaOptIn, setTpAstonVillaOptIn] = useState(false);
  let [tpSpursOptIn, setTpSpursOptIn] = useState(false);
  let [tpBirminghamCityOptIn, setTpBirminghamCityOptInOptIn] = useState(false);
  let [tpCelticOptIn, setTpCelticOptIn] = useState(false);
  let [tpRangersOptIn, setTpRangersOptIn] = useState(false);
  let [tpGoodwoodOptIn, setTpGoodwoodOptIn] = useState(false);
  let [tpHydeParkOptIn, setTpHydeParkOptIn] = useState(false);
  let [tpOdeonOptIn, setTpOdeonOptIn] = useState(false);
  let [tpJet2holidaysOptIn, setTpJet2holidaysOptIn] = useState(false);
  let [tpF1ArcadeOptIn, setTpF1ArcadeOptIn] = useState(false);
  let [tpSkyOptIn, setTpSkyOptIn] = useState(false);
  let [tpTOCASocialOptIn, setTpTOCASocialOptIn] = useState(false);
  let [tpVisitBritainOptIn, setTpVisitBritainOptIn] = useState(false);
  let [tpVirginExpOptIn, setTpVirginExpOptIn] = useState(false);
  let [tpMerlinEntOptIn, setTpMerlinEntOptIn] = useState(false);

  let [selectedPartners, setSelectedPartners] = useState("");

  let isIreland = settings.country === "ROI";


  useEffect(()=>{
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
    
  }, [validation])


  useEffect(()=>{
    setDoB(null);
  }, [optIn])

  useEffect(()=>{
    setTpArsenalOptIn(false);
    setTpChelseaOptIn(false);
    setTpLeedsOptIn(false);
    setTpLiverpoolOptIn(false);
    setTpManUtdOptIn(false);
    setTpAstonVillaOptIn(false);
    setTpSpursOptIn(false);
    setTpBirminghamCityOptInOptIn(false);
    setTpCelticOptIn(false);
    setTpRangersOptIn(false);
    setTpGoodwoodOptIn(false);
    setTpHydeParkOptIn(false);
    setTpOdeonOptIn(false);
    setTpF1ArcadeOptIn(false);
    setTpSkyOptIn(false);
    setTpTOCASocialOptIn(false);
    setTpVisitBritainOptIn(false);
    setTpVirginExpOptIn(false);
    setTpMerlinEntOptIn(false);
    setSelectedPartners("");
  }, [thirdPartyOptIn])
  
  const createCommand = () => {
    let model = {
      firstName,
      lastName,
      email,
      country: settings.country,
      phoneNumber,
      dob,
      address1,
      address2,
      city,
      county,
      postcode : postcode ? postcode.toString().toUpperCase() : postcode,
      retailerId: retailer,
      terms,
      legalAge,
      isMopupPhase: competitionIsMopup,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      tier1PrizePublicId: !competitionIsMopup ? competitionEntryContext.prize.id : null,
      optIn,
      thirdPartyOptIn,
      recaptcha2,
      phaseId: compPhaseId,
      otpAvilable,
      token,
      otp,
      flowId,
      flowToken,
      jet2OptIn,

      tpArsenalOptIn,
      tpChelseaOptIn,
      tpLeedsOptIn,
      tpLiverpoolOptIn,
      tpManUtdOptIn,
      tpAstonVillaOptIn,
      tpSpursOptIn,
      tpBirminghamCityOptIn,
      tpCelticOptIn,
      tpRangersOptIn,
      tpGoodwoodOptIn,
      tpHydeParkOptIn,
      tpOdeonOptIn,
      tpJet2holidaysOptIn,
      tpF1ArcadeOptIn,
      tpSkyOptIn,
      tpTOCASocialOptIn,
      tpVisitBritainOptIn,
      tpVirginExpOptIn,
      tpMerlinEntOptIn
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  const retailersList = [
    { "value": 2, "name": "Sainsburys"},
    { "value": 3, "name": "Coop"},
    { "value": 4, "name": "Asda"},
    { "value": 5, "name": "Morrisons"},
    { "value": 6, "name": "Tesco"},
    { "value": 7, "name": "Spar"},
    { "value": 8, "name": "Budgens"},
    { "value": 9, "name": "Londis"},
    { "value": 10, "name": "WHSmith"},
    { "value": 11, "name": "Waitrose"},
    { "value": 12, "name": "Nisa"},
    { "value": 13, "name": "Costcutter"},
    { "value": 14, "name": "Independent Retailer"},
  ];

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    setShuffledRetailerList(shuffle(retailersList));

    window.setTimeout(function(){
      document.body.classList.remove("overflow-hidden-mediumdown");
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);

  
    
  });


  const onFormSuccess = (result) => {

    analyticsService.logEvent("signup", "entry_registration_submit", {
      location: "entry_registration_form",
      label: "entry registration success", 
      url: "/registration"
    });
  
    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);   

    // Update csfr token and flag as logged in
    setSettings({...settings, prize: "" });

    let urlData =  `/${result.entryPublicId}`;
    //let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';
    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const handlePartnersChange = (e) => {
    var currentId = e.target.id;
    var currentTitle = e.target.title;
    
    if(e.target.checked){
      if(selectedPartners === "") {
        setSelectedPartners(currentTitle);
      } else {
        setSelectedPartners(selectedPartners + ", " + currentTitle);
      }
      
    } else {
      var partnerIndex = selectedPartners.indexOf(currentTitle);

      if(partnerIndex > -1 ) {
        var removePartner = "";

        if(partnerIndex === 0){
          if(selectedPartners.indexOf(",") > -1 ){
            removePartner = selectedPartners.replace(currentTitle+", ", "");
          } else {
            removePartner = selectedPartners.replace(currentTitle, "");
          }
          
        } else {
          removePartner = selectedPartners.replace(", " + currentTitle, "");
        }
        
        setSelectedPartners(removePartner);
      }
    }

    switch(currentId) {
      case "tpArsenalOptIn":
        setTpArsenalOptIn(e.target.checked);
        break;
      case "tpChelseaOptIn":
        setTpChelseaOptIn(e.target.checked);
        break;
        case "tpLeedsOptIn":
          setTpLeedsOptIn(e.target.checked);
        break;
        case "tpLiverpoolOptIn":
          setTpLiverpoolOptIn(e.target.checked);
        break;
        case "tpManUtdOptIn":
          setTpManUtdOptIn(e.target.checked);
        break;
        case "tpAstonVillaOptIn":
          setTpAstonVillaOptIn(e.target.checked);
        break;
        case "tpSpursOptIn":
          setTpSpursOptIn(e.target.checked);
        break;
        case "tpBirminghamCityOptIn":
          setTpBirminghamCityOptInOptIn(e.target.checked);
        break;
        case "tpCelticOptIn":
          setTpCelticOptIn(e.target.checked);
        break;
        case "tpRangersOptIn":
          setTpRangersOptIn(e.target.checked);
        break;
        case "tpGoodwoodOptIn":
          setTpGoodwoodOptIn(e.target.checked);
        break;
        case "tpHydeParkOptIn":
          setTpHydeParkOptIn(e.target.checked);
        break;
        case "tpOdeonOptIn":
          setTpOdeonOptIn(e.target.checked);
        break;
        case "tpF1ArcadeOptIn":
          setTpF1ArcadeOptIn(e.target.checked);
        break;
        case "tpSkyOptIn":
          setTpSkyOptIn(e.target.checked);
        break;
        case "tpTOCASocialOptIn":
          setTpTOCASocialOptIn(e.target.checked);
        break;
        case "tpVisitBritainOptIn":
          setTpVisitBritainOptIn(e.target.checked);
        break;
        case "tpVirginExpOptIn":
          setTpVirginExpOptIn(e.target.checked);
        break;
        case "tpMerlinEntOptIn":
          setTpMerlinEntOptIn(e.target.checked);
        break;
    }

  }

  

  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Cadbury - Win a Day to Remember</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h2">Your Details</h1>

        <div className="form__intro">
          <p >
          We just need some additional information from you to check that you're eligible to enter and so we can contact you if you're one of the lucky winners!
          </p>
          <p>
            Fields marked with * are mandatory.
          </p>
        </div>
        <Form 
          id="registration"
          submitTitle="Submit"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            {evnt: "button_click", category: "entry_registration_submit", location: "entry_registration_form", label: "email capture", url: email},
            (dob && dob.isValid) &&
            {evnt: "button_click", category: "entry_registration_submit", location: "entry_registration_form", label: "date of birth capture", url: dob.date},
          ]}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
           <TextFieldCharRestricted 
              label="* First name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              maxCharLenght ={60}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="* Last name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
              maxCharLenght ={60}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="* Email"
              id="email"
              type="email"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              readonly={readOnlyEmail}
              maxCharLenght ={150}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="Mobile Phone Number"
              id="phoneNumber"
              info={settings.country === "GB" ? `Please ensure this is a valid UK mobile number` : `Please ensure this is a valid Irish mobile number`}
              type="tel"
              onChange={createOnChangeHandler(validation, 'phoneNumber', setPhoneNumber)}
              value={phoneNumber}
              autoComplete="off"
              hasErrors={!!validation.errors.phoneNumber}
              onDismiss={onDismissModal}
              readonly={readOnlyPhoneNumber}
            />
            <ValidationMessage errors={validation.errors.phoneNumber} />
          </fieldset>

          
          { settings.country !== "ROI" &&
            <fieldset className="form__fieldset"> 
              <SelectField 
                label="* Please select your retailer"
                id="retailerId"
                value={retailer}
                onChange={createOnChangeHandler(validation, 'retailerId', setRetailer)}
                hasErrors={!!validation.errors.retailerId}
                selectAnalytics={{evnt: "form_field", category: "form_field_dropdown", location: "entry_registration_form", url: retailer}}
              >
                <option defaultValue hidden >Select an option</option>

                {
                  shuffledRetailerList.map((p, index) => (
                    <option value={p.name} key={"retailer-"+index}>{p.name}</option>
                  ))
                }
                <option value="1">Other</option>
                
              </SelectField>
              <ValidationMessage errors={validation.errors.retailerId} />
            </fieldset>
          }

          { settings.country === "ROI" &&
            <>
            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                label="* Address line 1"
                info="Please ensure this is a valid Irish address"
                id="address1"
                onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                value={address1}
                hasErrors={!!validation.errors.address1}
                maxCharLenght ={150}
              />
              <ValidationMessage errors={validation.errors.address1} />
            </fieldset>

            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                label="Address line 2"
                id="address2"
                onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                value={address2}
                hasErrors={!!validation.errors.address2}
                maxCharLenght ={150}
              />
              <ValidationMessage errors={validation.errors.address2} />
            </fieldset>

            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                label="* Town/City"
                id="city"
                onChange={createOnChangeHandler(validation, 'city', setCity)}
                value={city}
                hasErrors={!!validation.errors.city}
                maxCharLenght ={60}
              />
              <ValidationMessage errors={validation.errors.city} />
            </fieldset>

            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                label="* County"
                id="county"
                onChange={createOnChangeHandler(validation, 'county', setCounty)}
                value={county}
                hasErrors={!!validation.errors.county}
                maxCharLenght ={60}
              />
              <ValidationMessage errors={validation.errors.county} />
            </fieldset>

            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                label="* Eircode"
                id="postcode"
                onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                value={postcode}
                hasErrors={!!validation.errors.postcode}
                maxCharLenght ={60}
                uppercase
              />
              <ValidationMessage errors={validation.errors.postcode} />
            </fieldset>
            </>
          }

          <fieldset className="form__fieldset">
            <Checkbox 
              id="legalAge" 
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: legalAge}}
            />
            <ValidationMessage errors={validation.errors.legalAge} />            
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
                id="terms" 
                label="* I've read and agree to the Cadbury Win a Day to Remember promotion&nbsp;<a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>Terms and Conditions</a>&nbsp;and consent to the use of my personal data in accordance with the T&C's, <a href='/terms-and-conditions#transparency-statement' rel='noreferrer noopener' target='_blank'>Transparency Statement</a> and <a href='https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
                hasErrors={validation.errors.hasErrors && !terms}
                onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
                checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: terms}}
              />
              <ValidationMessage errors={validation.errors.terms} />    
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="optIn" 
              label="I want to receive emails packed with the latest news, competitions & offers from Cadbury. You can unsubscribe at any time."
              onChange={setOptIn} 
              value={optIn}
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: optIn}}
            />

            {
              optIn && 
              <>
              <br/>
                <div className='form__date-entry'>
                  <label
                    className="textfield__label textfield__label--dob"
                    htmlFor="">
                    If you would like a birthday offer in your inbox, tell us your date of birth. We also use this information to personalise your future experiences with Cadbury <b>(optional)</b>.
                  </label>
                  <br/>
                  
                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
                </>
            }
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
              id="jet2OptIn" 
              label={"Yes, please keep me up to date with Jet2holidays' latest deal and offers! By ticking this box, I confirm that I'm happy to receive marketing emails from Jet2holidays. I understand that I can opt out of these emails at any time by following the unsubscribe link in the email or by contacting <a href='mailto:privacy@jet2.com' rel='noreferrer noopener' target='_blank'>privacy@jet2.com</a>. More information about how my personal information will be used can be found in the Jet2holidays' <a href='https://www.jet2holidays.com/privacy-policy' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."}
              onChange={setJet2OptIn} 
              value={jet2OptIn}
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: jet2OptIn}}
            />
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
              id="thirdPartyOptIn" 
              label="I give Cadbury permission to use my personal information to help ensure future communications, either through partners (including my preferred partners from the drop-down below) or across third party platforms, are more relevant & tailored to me. Cadbury is an affiliated brand of Mondelez International."
              onChange={setThirdPartyOptIn} 
              value={thirdPartyOptIn}
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "entry_registration_form", url: thirdPartyOptIn}}
            />

            {
              thirdPartyOptIn && 
              <fieldset className="form__fieldset form__fieldset--indent">

                <div className='textfield textfield--custom-dd'>
                  <div className="textfield__input-wrapper">
                    <details className='custom-dropdown custom-dropdown--multiple'>
                      <summary className='custom-dropdown__selected'>{selectedPartners ? selectedPartners : "Select preferred partners"}</summary>
                      <div className='custom-dropdown__options'>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpArsenalOptIn" label={"Arsenal FC"} onChange={e => handlePartnersChange(e)} value={tpArsenalOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpChelseaOptIn" label={"Chelsea FC"} onChange={e => handlePartnersChange(e)} value={tpChelseaOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpLeedsOptIn" label={"Leeds United"} onChange={e => handlePartnersChange(e)} value={tpLeedsOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpLiverpoolOptIn" label={"Liverpool FC"} onChange={e => handlePartnersChange(e)} value={tpLiverpoolOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpManUtdOptIn" label={"Manchester United"} onChange={e => handlePartnersChange(e)} value={tpManUtdOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpAstonVillaOptIn" label={"Aston Villa FC"} onChange={e => handlePartnersChange(e)} value={tpAstonVillaOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpSpursOptIn" label={"Tottenham Hotspur FC"} onChange={e => handlePartnersChange(e)} value={tpSpursOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpBirminghamCityOptIn" label={"Birmingham City FC"} onChange={e => handlePartnersChange(e)} value={tpBirminghamCityOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpCelticOptIn" label={"Celtic FC"} onChange={e => handlePartnersChange(e)} value={tpCelticOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpRangersOptIn" label={"Rangers FC"} onChange={e => handlePartnersChange(e)} value={tpRangersOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpGoodwoodOptIn" label={"Goodwood"} onChange={e => handlePartnersChange(e)} value={tpGoodwoodOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpHydeParkOptIn" label={"Hyde Park Winter Wonderland"} onChange={e => handlePartnersChange(e)} value={tpHydeParkOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpOdeonOptIn" label={"ODEON"} onChange={e => handlePartnersChange(e)} value={tpOdeonOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpF1ArcadeOptIn" label={"F1 Arcade"} onChange={e => handlePartnersChange(e)} value={tpF1ArcadeOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpSkyOptIn" label={"Sky"} onChange={e => handlePartnersChange(e)} value={tpSkyOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpTOCASocialOptIn" label={"TOCA Social"} onChange={e => handlePartnersChange(e)} value={tpTOCASocialOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpVisitBritainOptIn" label={"Visit Britain"} onChange={e => handlePartnersChange(e)} value={tpVisitBritainOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpVirginExpOptIn" label={"Virgin Experience Days"} onChange={e => handlePartnersChange(e)} value={tpVirginExpOptIn} simpleDisplay dropdownValue />
                        </div>
                        <div className='custom-dropdown__options__item'>
                          <Checkbox  id="tpMerlinEntOptIn" label={"MERLIN Entertainment"} onChange={e => handlePartnersChange(e)} value={tpMerlinEntOptIn} simpleDisplay dropdownValue />
                        </div>
                            
                      </div>
                    </details>
                    <span className="textfield__focus"></span>
                  </div>
                </div>
              </fieldset>
            }
            
          </fieldset>

          
          <fieldset className="form__fieldset form__fieldset--captcha">
              <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
              <div className="g-recaptcha-v2" data-sitekey="6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB"
              data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
              <ValidationMessage errors={validation.errors.recaptcha2} /> 
          </fieldset>


          { showValidationModal &&
            <Modal 
              panel 
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Mmm - that hasn't worked...">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  <Button title="Back to homepage" href="/" />
                )
              }
            </Modal>
          }
        </Form>

      </div>
    </section>
    
    <SummaryTsandCs />
  </>;
}