import React from 'react';
import './Step.scss';

const Step = (props) => {
  return (
    <article className="step">
      <div className="step__num">
        <span className="step__number">{props.number}</span>
      </div>

      {
        props.image2x ? (
          <img className="step__image" src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x"} alt={props.alt} />
        ) : (
          <img className="step__image" src={props.image} alt={props.alt} />
        )
      }
   
      <h3 className="step__title">{props.title}</h3>
      <p className="step__info" dangerouslySetInnerHTML={{ __html: props.description }}></p>
      
      {
        props.descriptionLink ??
          <p className="step__info" >
            { props.descriptionLink }
          </p>   
      }
      
    </article>
  )
}

export default Step;
