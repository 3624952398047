import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import analyticsService from 'services/analyticsService';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import ActionBar from 'containers/ActionBar/ActionBar';
import Button from 'components/Button/Button';
import prizeList from 'utils/prizes';
import Prize from 'components/Prize/Prize';
import SettingsContext from 'contexts/SettingsContext';
import './PrizeSelection.scss';
import Modal from 'components/Modal/Modal';
import PrizeAccordions from 'components/PrizeAccordions/PrizeAccordions';

import Carousel from 'components/Carousel/Carousel';

export default () => {
  let competitionEntryState = useCompetitionEntryState();
  let history = useHistory();
  let [prize, setPrize] = useState(competitionEntryState.prize);
  const [showPrizeDetailsModal, setShowPrizeDetailsModal] = useState(false);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let isROI = settings.country === "ROI" ? true : false;


  const onContinue = () => {
    if (prize !== null) {
      competitionEntryState.setPrize(prize);
      setSettings({ ...settings, prize: prize.id });

      analyticsService.logEvent("button_click", "prize_selection", {
        location: "prize_selection_page",
        label: "selected prize " + prize.id,
        url: "/prize-select"
      });


      history.push("/registration");
    }
  }

  const handleSelectedPrize = (selPrize) => {
    setPrize(selPrize);

    analyticsService.logEvent("image_click", "prize_selection", {
      location: "prize_selection_page",
      label: "clicked prize " + selPrize.id,
      url: "/prize-select"
    });
  }

  const handlePrizeDetailsModal = () => {
    setShowPrizeDetailsModal(true);
  }

  const onHidePrizeDetailsModal = () => {
    setShowPrizeDetailsModal(false);
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const renderPrizes = (prizes) => {
    return prizes.map((p, index) => {
      return (
                    
          <Prize 
            id={p.id}
            key={"prize-wrapper__" + index}
            tab={index + 1}
            prize={p} 
            model="prize"
            selected={prize && prize.id === p.id} 
            selectable 
            onChange={(selected) => handleSelectedPrize(selected)}
          />
      )
    })
  }

  /* RENDER */
  return <>
    
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prize Selection | Cadbury - Win a Day to Remember</title>
    </Helmet>
    <div className={"layout" + (prize !== null ? " layout--shadow" : "")}>
      
      <section className={"layout-general layout-general--mt0 layout-wrapper overflowx-hidden" + (isROI ? "  layout-general--full-texture " :" layout-general--full-texture  layout-general--full-texture--gb") }>
        <div className="layout-container layout-container--center layout-container--unrestricted  prize-select " >
          <h1 className="type-h2 type-h2--secondary">Drumroll…<br/>which category will you choose from?</h1>
          <p>
              <Button
              type="button"
              title={"Find out more about the prizes"}
              className="button--text-link"
              analytics={{evnt: "button_click", category: "internal_link", location: "prize_selection_page", label: "open prize details modal"}}
              onClick={handlePrizeDetailsModal}
            />
          </p>
          <form>
            
            <Carousel 
              className="prize-select__list"
              params={{
                slidesPerView: 1,
                slidesPerGroup: 1,
                centeredSlides: false,
                //runCallbacksOnInit: true,
                pagination: {
                  //el: '.swiper-pagination',
                  type: 'fraction'
                },
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                  768 : {
                    slidesPerView: 2.5,
                    slidesPerGroup: 2
                  },
                  1200 : {
                    slidesPerView: 3.5,
                    slidesPerGroup: 3
                  },
                  1600 : {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                  }
                }
              }}
              >
              {
                renderPrizes(prizeList)
              }
            </Carousel>
            <div className='prize-select__nav'>
              <button type="button" className='swiper-button-prev'></button>
              <div className='swiper-pagination'></div>
              <button type="button"  className='swiper-button-next'></button>
          </div>
          </form>
          
         
        </div>
       
      </section>
      <ActionBar show={prize !== null}>
        <Button wide title={"Confirm " + (prize != null && prize.title != null ? prize.title : "")} onClick={onContinue} />
      </ActionBar>
    </div>

    {
        showPrizeDetailsModal &&
        <Modal 
          panel 
          panelWide
          prize
          onDismiss={onHidePrizeDetailsModal}
          closeAnalytics={{evnt: "button_click", category: "close_modal", location: "prize_selection_page", label: "close prize details modal"}}
          >
            <PrizeAccordions isROI={isROI} />
        </Modal>
      }
  </>;
}