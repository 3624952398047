import ValidationErrorCollection from './ValidationErrorCollection';


const NUM_LETT_REGEX = /^[a-zA-Z0-9_.-]*$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('otp', 'Please enter your verification code.');
  required('recaptcha2', 'Please confirm you are not a robot.');

  //let otpVal = command.otp.replace(/\s+/g, '');

  if (command.otp) {

    if(command.actionId && command.actionId === 'entry') {
      if(command.otp.length !== 6 ) {
        messages.addError('otp', 'Please enter a valid 6-digit verification code.');
      }
    }

    if(command.actionId && command.actionId === 'claim') {
      if(command.otp.length !== 8 ) {
        messages.addError('otp', 'Please enter a valid 8-digit verification code.');
      }
    }



    if(!NUM_LETT_REGEX.test(command.otp)) {
      messages.addError('otp', 'Please enter a valid verification code.');
    }

  }

  return messages;
  
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
