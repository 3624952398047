import ValidationErrorCollection from './ValidationErrorCollection';


const NUM_LETT_REGEX = /^[a-zA-Z0-9_.-]*$/;
//const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,18})+$/;
// new email regex to avoid Catastrophic Backtracking
const EMAIL_REGEX = /^(?:\w+[.-])*\w+@\w+(?:[.-]\w+)*\.\w{2,18}$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter your first name');
  required('lastName', 'Please enter your last name');
  required('email', 'Please enter your email address');
  required('otp', 'Please enter your verification code.');
  required('recaptcha2', 'Please confirm you are not a robot.');

  //let otpVal = command.otp.replace(/\s+/g, '');

  if (command.otp) {
    if(command.otp.length !== 8 ) {
        messages.addError('otp', 'Please enter a valid 8-digit verification code.');
    }

    if(!NUM_LETT_REGEX.test(command.otp)) {
      messages.addError('otp', 'Please enter a valid verification code.');
    }
  }

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  return messages;

  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
