import React, { useEffect } from 'react';
import Link from 'components/Link/Link';
import useSettingsContext from 'contexts/useSettingsContext';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as FbSvg } from 'assets/images/facebook.svg';
import { ReactComponent as InSvg } from 'assets/images/instagram.svg';
import { ReactComponent as TikTSvg } from 'assets/images/tiktok.svg';
import { ReactComponent as MdlzSvg } from 'assets/images/mondelez-logo.svg';
import ContactUsModal from 'containers/Home/ContactUsModal';
import './Footer.scss';
import Button from 'components/Button/Button';

export default function (props) {
  const location = useLocation();
  let settings = useSettingsContext();
  let isROI = settings.country === "ROI" ? true : false;
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  useEffect(() => {
    if(location.pathname.indexOf("contact-us") > -1) {
      handleContactUsModal();
    }
  }, showContactUsModal);

  const handleContactUsModal = () => {
    settings.setContactModal(true);
    setShowContactUsModal(true);
  }  

  return (<>
      
    <footer className="footer">
      <div className="footer__menu">
        <nav className="footer__menu__links">
          <div className="footer__menu__links__control">
            {props.showTerms &&
              <Link
                analytics={{evnt: "link_click", category: "internal_link", location: "footer", label: "terms and conditions page"}}
                href="/terms-and-conditions"
                target="_blank"
                title="Terms and Conditions" >T&Cs</Link>
            }
            <Link
              analytics={{evnt: "link_click", category: "internal_link", location: "footer", label: "transparency statement page"}}
              href="/terms-and-conditions#transparency-statement"
              target="_blank"
              title="Transparency Statement">Transparency Statement</Link>
            {
              props.showTerms &&
              <Link
                analytics={{evnt: "link_click", category: "internal_link", location: "footer", label: "cookie policy page"}}
                href="/cookie-policy"
                target="_blank"
                title="Cookie Policy" >Cookie Policy</Link>
            }

            <Button
              analytics={{evnt: "link_click", category: "internal_link", location: "footer", label: "contact us modal", url: ""}}
              title="Contact Us"
              onClick={handleContactUsModal}>Contact Us</Button>

          </div>
        </nav>
        <nav className={!isROI ? "footer__menu__social-links" : "footer__menu__social-links footer__menu__social-links--reduced"}>
          {
            !isROI &&
            <Link
              analytics={{evnt: "socialmedia_exit", category: "event_social_action", location: "footer", label: "tiktok"}}
              href="https://www.tiktok.com/@CadburyUK"
              target="_blank"
              title="Cadbury UK on TikTok" >
              <TikTSvg />
            </Link>
          }
          <Link
            analytics={{evnt: "socialmedia_exit", category: "event_social_action", location: "footer", label: "facebook"}}
            href={!isROI ? "https://www.facebook.com/CadburyUK/" : "https://www.facebook.com/CadburyIreland/"}
            target="_blank"
            title={!isROI ? "Cadbury UK on Facebook" : "Cadbury Ireland on Facebook"} >
            <FbSvg />
          </Link>
          <Link
            analytics={{evnt: "socialmedia_exit", category: "event_social_action", location: "footer", label: "instagram"}}
            href={!isROI ? "https://www.instagram.com/cadburyuk/" : "https://www.instagram.com/cadburyireland/?hl=en"}
            target="_blank"
            title={!isROI ? "Cadbury UK on Instagram" : "Cadbury Ireland on Instagram"} >
            <InSvg />
          </Link>
        </nav>

      </div>

      <div className="footer__copyright">
        <p className="footer__copyright__text">&copy; {new Date().getFullYear()} Mondelez UK &amp; Ireland - All Rights Reserved.</p>

        <Link
          analytics={{evnt: "image_click_logo", category: "external_link", location: "footer", label: "mondelez international"}}
          href="http://www.mondelezinternational.com/"
          target="_blank"
          title="Mondelez International"
          className="footer__copyright__logo" >
          <MdlzSvg />
        </Link>
      </div>

    </footer>
    <ContactUsModal
              show={showContactUsModal}
              onDismiss={() => setShowContactUsModal(false)}
            />
      </>
  );
}