import React, { useState } from 'react';
import Accordion from 'components/Accordion/Accordion';
import './PrizeAccordions.scss';
import prizesOrdered from 'utils/prizeAccordions';


const PrizeAccordions = (props) => {

  let defaultFaq = { section: 0, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: values[0],
      index: values[1]
    }

    if (window.location.hash.indexOf("prize") > -1) {
      const section = document.querySelector(window.location.hash);
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
      setAccordion(index);
  }
  


  const renderPrizes = (section) => {
    
    return prizesOrdered.map((prize, index) => {
      let id = { section, index: prize.id };
      //let id = { section, index };
      let key =  `${id.section}-${id.index}`;
      
      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={prize.title}
          answer={prize.fullDetails}
          image={prize.circledImage}
          imageMob={prize.circledImageMob? prize.circledImageMob : prize.circledImage}
          imageAlt={prize.imageAlt}
          isModal={props.isModal}
          className={"accordion--prize" + (!props.isROI ? (" accordion--prize--gb "): (""))}>
        </Accordion>
      )
    })
  }

 
  return (
    (
      <div className="prize-accordions ">
        {
           renderPrizes("prize")
        }
      </div>
    )
  )
}

export default PrizeAccordions;
