import React, { useState } from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlock.scss';
import Button from 'components/Button/Button';

const PrizeBlock = (props) => {
  const [showToggleContent, setShowToggleContent] = useState(false);

  const mainClassMap = {
    "prize-block": true,
    "prize-block--reverse": props.reverse
  };

  const imageClassMap = {
    "prize-block__image": true,
    "prize-block__image--contain": props.containImage
  };

  const handleToggleContent = () => {
      if(!showToggleContent){
        setShowToggleContent(true);
      } else {
        setShowToggleContent(false);
      }
  };

  return (
    (
      <article className={classList(mainClassMap)}>
        <div className='prize-block__container'>
          {props.image &&
            <div className={classList(imageClassMap)}>
              <picture>
                { props.imageLarge &&
                  <source media="(min-width: 768px)" srcSet={props.imageLarge + " 2x, " + props.image + " 1x"}/>
                }
                <img src={props.imageMob} srcSet={props.imageMobLarge + " 2x, " + props.imageMob + " 1x"} alt={props.alt || props.title} />
              </picture>
            </div>
          }

          <div className="prize-block__info">
            {props.title &&
              <h3 className="prize-block__title" dangerouslySetInnerHTML={{ __html: props.title }}></h3>
            }

            {
              props.description ?
                (<div className="prize-block__info-copy" dangerouslySetInnerHTML={{ __html: props.description }}></div>) : (<></>)
            }

            {
              props.toggleInfo &&
              <Button
                type="button"
                title={props.toggleInfo.title}
                className="button--text-link"
                onClick={handleToggleContent}
              />
            }

          </div>
        </div>
       {
        (props.toggleInfo && showToggleContent) &&
        <div className="prize-block__toggle-content" dangerouslySetInnerHTML={{ __html: props.toggleInfo.content }}></div>
       }
      </article>
    )
  )
}

export default PrizeBlock;
