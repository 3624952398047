import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import Registration from './Registration';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';
import useEffectOnce from 'hooks/useEffectOnce';

import OTPEntryVerificationForm from './OTPEntryVerificationForm';
import OTPEntry from 'containers/OneTimePassword/OTPEntry';


export default () => {
  const settings = useSettingsContext();
  const competitionEntryState = useCompetitionEntryContext();
  let compState = settings.competitionStatus.openState;
  const isIreland = settings.country != 'GB';
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let competitionIsMain = compPhaseId === "main";

  let [entryCreatedDate, setEntryCreatedDate] = useState("");
  let [otpEntryStartDate, setOtpEntryStartDate] = useState("");  
  let [pageStage, setPageState] = useState("loading"); 

  let otpEntryAvilable = settings.IsClaimEnabled !== "True" ? true : false;



  useEffectOnce(() => {

    //setEntryCreatedDate(new Date("04/04/2024"));
    //setOtpEntryStartDate(new Date("04/04/2024"));    
    setPageState("fetched");

   /* async function fetchData() {
      prizeClaimService.getPrizeClaim(token).then((data) => {        
        if(data) {
          setClaimCreatedDate(new Date(data.createDate));
          setOtpClaimStartDate(new Date(data.otpStartDate));          
        } 
        setPageState("fetched");

      });
    }

    fetchData();*/

  });



  if(pageStage !== "loading") {    

    if (competitionIsOpen && 
      ((competitionEntryState.batchcode && competitionEntryState.barcode) || isIreland )
      &&
      ((competitionIsMain && competitionEntryState.prize) || competitionIsMopup )
      ) 
    {
        if(otpEntryAvilable && settings.country) {
          return (
            <OTPEntry />
            //<OTPEntryVerificationForm />
          )

        } else {
          return <Registration otpAvilable={false} />
        }


    } else {
      return <Redirect to='/' />
    }



  } else {
    return (
      <div>Loading...</div>
    )
  }
  

}