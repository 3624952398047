import React from 'react';
import Modal from 'components/Modal/Modal';
import Link from 'components/Link/Link.js';
import useSettingsContext from 'contexts/useSettingsContext';

export default ({show, onDismiss, onDismissModal}) => {
    let settings = useSettingsContext();

    function onDismissModal() {
        settings.setContactModal(false);
        
        onDismiss();
        document.body.classList.remove("overflow-hidden-mediumdown");
    }

    if (show) {

        return (
            <Modal
                id={"contactUsModal"}
                panel
                title="Contact Us"
                className="modal modal--extra"
                onDismiss={onDismissModal}
                closeAnalytics={{evnt: "button_click", category: "close_modal", location: "contact_us_modal", label: "close contact us modal"}}
            >
                <p className="modal--extra__intro" tabIndex="0">
                    For support, please send an email to
                    <Link
                        className="modal--extra__link"
                        analytics={{ evnt: "link_click", category: "external_link", location: "contact_us_modal", label: "email link" }}
                        href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A"
                        target="_blank"
                        title="Contact Us"
                        type="button"
                    >
                        consumerservices@cadburywinaday.com
                    </Link>
                    including your full name, email address, mobile number & enquiry details.
                </p>
            </Modal>
        )
    } else {
        return null;
    }
}