import PrizeImage1 from 'assets/images/campaign/prizes/football_alpha.png';
import PrizeImage2 from 'assets/images/campaign/prizes/tickets_alpha.png';
import PrizeImage3 from 'assets/images/campaign/prizes/rollercoaster_alpha.png';
import PrizeImage4 from 'assets/images/campaign/prizes/flag_alpha.png';
import PrizeImage5 from 'assets/images/campaign/prizes/popcorn-alpha.png';
import PrizeImage12x from 'assets/images/campaign/prizes/football_alpha@2x.png';
import PrizeImage22x from 'assets/images/campaign/prizes/tickets_alpha@2x.png';
import PrizeImage32x from 'assets/images/campaign/prizes/rollercoaster_alpha@2x.png';
import PrizeImage42x from 'assets/images/campaign/prizes/flag_alpha@2x.png';
import PrizeImage52x from 'assets/images/campaign/prizes/popcorn-alpha@2x.png';


import SporCardDetail1 from 'assets/images/campaign/logos/logo-banner/arsenal@2x.png';
import SporCardDetail2 from 'assets/images/campaign/logos/logo-banner/avfc@2x.png';
import SporCardDetail3 from 'assets/images/campaign/logos/logo-banner/chelsea@2x.png';


import TravelCardDetail1 from 'assets/images/campaign/logos/jet2holidays.png';

import AdventureCardDetail1 from 'assets/images/campaign/logos/lfc-logo.png';
import AdventureCardDetail2 from 'assets/images/campaign/logos/toca-social.png';

import FestivalsCardDetail1 from 'assets/images/campaign/logos/goodwood-festival.png';

import EntertainmentCardDetail1 from 'assets/images/campaign/logos/odeon.png';


const prizeList = [
  { 
    "id": "sport", 
    "image": PrizeImage1, 
    "image2x" : PrizeImage12x,
    "imageAlt" : "Football grpahic",
    "title": "Sport",
    "description" : `<p>From VIP football match tickets at the biggest clubs to the thrills of F1, it's all to play for!</p>`,
    "cardLinkTitle": "And many more",
    "cardDetails": [
      {
        "detailImage" : SporCardDetail1,
        "detailImageAlt" : "Arsenal FC badge",
        "detailTitle": "Tickets to an Arsenal Women's game at Emirates Stadium"
      },
      {
        "detailImage" : SporCardDetail2,
        "detailImageAlt" : "Aston Villa FC badge",
        "detailTitle": "Tickets to a women's football game at Villa Park"
      },
      {
        "detailImage" : SporCardDetail3,
        "detailImageAlt" : "Chelsea FC badge",
        "detailTitle": "Tickets to a Chelsea Women's football game at the Bridge"
      },
    ],
    "cardDetailsInfo" : `
      <img class="card-details__image" src=${PrizeImage1} alt="" />
      <img class="card-details__image card-details__image--icon" src=${SporCardDetail1} alt="" />

      <h2 class="type-h3">A 1 star family trip away!</h2>
      <p>Relax, you've got this! Cadbury with Jet2holidays are offering a stunning trip that you can choose, complete with flights & accommodation for 2 adults and 2 under 18s. Airport travel and spending money not included.</p>

      <h2 class="type-h3">Ultimate VIP all inclusive summer holiday</h2>
      <p>Cadbury with Jet2holidays are offering a stunning 7 day holiday, with Senior Suite room and all inclusive board to Tenerife, Crete or Turkey, complete with flights & accommodation (for 2 adults and 2 children under 12), out of this world excursions, and even, a go on the Jet2 training flight simulator! Airport travel and spending money not included.</p>
    `
  },
  { 
    "id": "travel", 
    "image": PrizeImage2, 
    "image2x" : PrizeImage22x,
    "imageAlt" : "Pair of plane tickets grpahic",
    "title": "Travel",
    "description" : `<p>From 5 star holidays to all inclusive trips of a lifetime, time to get on board!</p>`,
    "cardLinkTitle": "more information",
    "cardDetails": [
      {
        "detailImage" : TravelCardDetail1,
        "detailImageClass" : "large",
        "detailImageAlt" : "Jet2 Holidays logo",
        "detailTitle": "A 5 star family trip away!"
      },
      {
        "detailImage" : '',
        "detailImageAlt" : "",
        "detailTitle": "Ultimate vip all inclusive summer holiday"
      },
    ],
    "cardDetailsInfo" : `
      <img class="card-details__image" src=${PrizeImage2} alt="" />
      <img class="card-details__image card-details__image--icon" src=${TravelCardDetail1} alt="" />

      <h2 class="type-h3">A 5 star family trip away!</h2>
      <p>Relax, you've got this! Cadbury with Jet2holidays are offering a stunning trip that you can choose, complete with flights & accommodation for 2 adults and 2 under 18s. Airport travel and spending money not included.</p>

      <h2 class="type-h3">Ultimate VIP all inclusive summer holiday</h2>
      <p>Cadbury with Jet2holidays are offering a stunning 7 day holiday, with Senior Suite room and all inclusive board to Tenerife, Crete or Turkey, complete with flights & accommodation (for 2 adults and 2 children under 12), out of this world excursions, and even, a go on the Jet2 training flight simulator! Airport travel and spending money not included.</p>
    `
  },
  { 
    "id": "adventure", 
    "image": PrizeImage3, 
    "image2x" : PrizeImage32x,
    "imageAlt" : "Rollercoster graphic",
    "title": "Adventure",
    "description" : `<p>From chocolate making with stars, to exhilarating stadium experiences and much more, buckle up!</p>`,
    "cardLinkTitle": "And many more",
    "cardDetails": [
      {
        "detailImage" : AdventureCardDetail1,
        "detailImageAlt" : "Liverpool FC logo",
        "detailTitle": "Personal VIP tour of Anfield with Virgil van Dijk"
      },
      {
        "detailImage" : AdventureCardDetail2,
        "detailImageAlt" : "TOCA Social logo",
        "detailTitle": "Go toe-to-toe at TOCA Social with Leah Williamson"
      },
    ],
    "cardDetailsInfo" : `
      <img class="card-details__image" src=${PrizeImage3} alt="Box of popcorn graphic" />
      <img class="card-details__image card-details__image--icon" src=${AdventureCardDetail1} alt="Odeon logo" />

      <h2 class="type-h3">A 3 star family trip away!</h2>
      <p>Relax, you've got this! Cadbury with Jet2holidays are offering a stunning trip that you can choose, complete with flights & accommodation for 2 adults and 2 under 18s. Airport travel and spending money not included.</p>

      <h2 class="type-h3">Ultimate VIP all inclusive summer holiday</h2>
      <p>Cadbury with Jet2holidays are offering a stunning 7 day holiday, with Senior Suite room and all inclusive board to Tenerife, Crete or Turkey, complete with flights & accommodation (for 2 adults and 2 children under 12), out of this world excursions, and even, a go on the Jet2 training flight simulator! Airport travel and spending money not included.</p>
    `
  },
  {  
    "id": "festivals", 
    "image": PrizeImage4, 
    "image2x" : PrizeImage42x,
    "imageAlt" : "Racing flag graphic",
    "title": "Festivals",
    "description" : `<p>From vintage fashion to high speed classic car racing and pedigree dogs, there's something for everyone!</p>`,
    "cardLinkTitle": "And many more",
    "cardDetails": [
      {
        "detailImage" : FestivalsCardDetail1,
        "detailImageAlt" : "Goodwood Festival of Speed logo",
        "detailImageClass" : "medium",
        "detailTitle": "Rev up for the Goodwood Festival of speed"
      },
      {
        "detailImage" : '',
        "detailImageAlt" : "",
        "detailTitle": "Go in style to Goodwood Revival festival"
      },
      {
        "detailImage" : '',
        "detailImageAlt" : "",
        "detailTitle": "Get off and running at a Premier Horseracing meet"
      },
    ],
    "cardDetailsInfo" : `
      <img class="card-details__image" src=${PrizeImage4} alt="Box of popcorn graphic" />
      <img class="card-details__image card-details__image--icon" src=${FestivalsCardDetail1} alt="Odeon logo" />

      <h2 class="type-h3">A 4 star family trip away!</h2>
      <p>Relax, you've got this! Cadbury with Jet2holidays are offering a stunning trip that you can choose, complete with flights & accommodation for 2 adults and 2 under 18s. Airport travel and spending money not included.</p>

      <h2 class="type-h3">Ultimate VIP all inclusive summer holiday</h2>
      <p>Cadbury with Jet2holidays are offering a stunning 7 day holiday, with Senior Suite room and all inclusive board to Tenerife, Crete or Turkey, complete with flights & accommodation (for 2 adults and 2 children under 12), out of this world excursions, and even, a go on the Jet2 training flight simulator! Airport travel and spending money not included.</p>
    `
  },
  { 
    "id": "entertainment", 
    "image": PrizeImage5, 
    "image2x" : PrizeImage52x,
    "imageAlt" : "Box of popcorn graphic",
    
    "title": "Entertainment",
    "description" : `<p>3, 2, 1… Action! Win 4 exclusive cinema tickets to ODEON and walk the purple carpet like a movie megastar!</p>`,
    "cardLinkTitle": "more information",
    "cardDetails": [
      {
        "detailImage" : EntertainmentCardDetail1,
        "detailImageAlt" : "Odeon logo",
        "detailImageClass" : "large",
        "detailTitle": "Walk the purple carpet with odeon and watch a movie screening of Paddington In Peru!"
      }
    ],
    "cardDetailsInfo" : `
      <img class="card-details__image" src=${PrizeImage5} alt="Box of popcorn graphic" />
      <img class="card-details__image card-details__image--icon" src=${EntertainmentCardDetail1} alt="Odeon logo" />

      <h2 class="type-h3">A 5 star family trip away!</h2>
      <p>Relax, you've got this! Cadbury with Jet2holidays are offering a stunning trip that you can choose, complete with flights & accommodation for 2 adults and 2 under 18s. Airport travel and spending money not included.</p>

      <h2 class="type-h3">Ultimate VIP all inclusive summer holiday</h2>
      <p>Cadbury with Jet2holidays are offering a stunning 7 day holiday, with Senior Suite room and all inclusive board to Tenerife, Crete or Turkey, complete with flights & accommodation (for 2 adults and 2 children under 12), out of this world excursions, and even, a go on the Jet2 training flight simulator! Airport travel and spending money not included.</p>
    `
  }
  
]

export default prizeList;