import api from './api';

function requestOTP(data) {
    return api
        .post('/otp/trigger', data);
}

function validateOTP(data) {
    return api
        .post('/otp/validate', data);
}

function resendOTP(data) {
    return api
        .post('/otp/resend', data);
}

export default {
    requestOTP,
    validateOTP,
    resendOTP
};

