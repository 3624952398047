import React from 'react';
import classList from 'react-classlist-helper';
//import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  const classMap = {
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  return (
    <section className={`${classList(classMap)}`} >
      <div className="layout-container layout-container--center layout-container--wide layout-container--bottom-space">
        <div className='form__privacy form__privacy--reduced'>
          <div className='sumtsandcs'>
            <div className='title'>Summary Terms and Conditions:</div>
            <p>Open to residents of UK & ROI, 18+ only. 10:00 05/08/2024 - 09:59 04/11/2024. Purchase required
              for UK only, no purchase necessary for ROI. Internet, email & mobile required (+ address for ROI).
              Scan QR code on promotional packs or visit <a href="https://winaday.cadbury.co.uk" target="_blank" rel="noopener noreferrer" className='link'>winaday.cadbury.co.uk</a> / <a href="https://winaday.cadbury.ie" target="_blank" rel="noopener noreferrer" className='link'>winaday.cadbury.ie</a>, register your details, select your region + if in UK, enter barcode & batch code found on pack. You will receive
              a one-time passcode to verify entry via SMS. Level 1 prizes: A chance to ‘Win a Day to Remember’
              100s of experiences & tickets to be won each week, winners drawn at random via prize draws. Level
              2 rewards: A chance to win one of 1,000s of voucher rewards, allocated randomly via open winning
              moments throughout the Promotion Period. All Level 2 rewards are available but may not all be won.
              Level 3: Every entrant will receive a Jet2holidays £75 discount voucher awarded on first entry only
              (UK flight departure & arrival only). Max 1 x Level 1 prize per person, 20 x Level 2 voucher rewards
              per person & 1 x Level 3 Jet2holidays £75 discount voucher per person in total. 1 entry per person
              per day & max 20 entries per person in total. Prizes & rewards subject to entry/claim verification & full
              T&Cs. Smartphone may be required to claim. Level 2 voucher rewards may be subject to additional
              consumer contribution or P&P for redemption. All prizes & rewards are subject to applicable partner
              rules/regulations/guidance. Final draw: Enter: 10:00 04/11/2024 - 09:59 06/05/2025. Prize: 1x £/€250
              cash prize. All prizes & rewards are subject to availability, exclusions & C-19 rules (where applicable).
              Jet2holidays discount vouchers valid for new bookings in specified hotels only between 07/08/2024 &
              23/12/2024 (travel before 31/10/2025), child restrictions apply. For full <a href="/terms-and-conditions" className='link'>T&Cs</a>, prize, reward &
              Jet2holidays discount voucher details see website. Promoter: UK: Mondelez Europe Services GmbH
              – UK Branch, Cadbury House, Sanderson Road, Uxbridge, UB8 1DH. ROI: Mondelez Europe
              Services GmbH – Ireland Branch, Malahide Road, Coolock, Dublin 5</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SummaryTsandCs;
