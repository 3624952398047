import CircledAdventure from 'assets/images/campaign/prizes/circled-rollercoster.svg';
import CircledEntertainment from 'assets/images/campaign/prizes/circled-popcorn.svg';
import CircledSport from 'assets/images/campaign/prizes/circled-football.svg';
import CircledFestivals from 'assets/images/campaign/prizes/circled-flag.svg';
import CircledTravel from 'assets/images/campaign/prizes/circled-tickets.svg';

import CircledEntertainmentMob from 'assets/images/campaign/prizes/circled-popcorn-mob.svg';
import CircledAdventureMob from 'assets/images/campaign/prizes/circled-rollercoster-mob.svg';
import CircledSportMob from 'assets/images/campaign/prizes/circled-football-mob.svg';
import CircledFestivalsMob from 'assets/images/campaign/prizes/circled-flag-mob.svg';
import CircledTravelMob from 'assets/images/campaign/prizes/circled-tickets-mob.svg';

const prizeList = [
  {
    "id": "adventure",
    "imageAlt": "Rollercoster graphic",
    "circledImage": CircledAdventure,
    "circledImageMob": CircledAdventureMob,
    "title": "Adventure",
    "fullDetails": [{
      "detailTitle": "Go chocolate making with Leah Williamson",
      "detailDesc": `<p>Not just any chocolate, but a Cadbury chocolate making experience with legendary Arsenal star, Leah Williamson. Spend the day with one guest and Leah creating your very own Cadbury Bar! Accommodation and food excluded.</p>`
    },
    {
      "detailTitle": "Personal VIP tour of Arsenal with Leah Williamson",
      "detailDesc": `<p>Enjoy an unprecedented tour of Emirates Stadium with Arsenal superstar, Leah Williamson as your tour guide. Prize is for one winner plus 1 guest. Travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Personal VIP stadium tour with Virgil van Dijk",
      "detailDesc": `<p>Enjoy an unprecedented Liverpool FC stadium or training ground tour with Virgil van Dijk as your tour guide. Prize is for one winner plus 1 guest. Travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Go toe-to-toe at TOCA Social with Leah Williamson",
      "detailDesc": `<p>Get an exclusive Q&A session with Arsenal star, Leah Williamson, and access to play in a private area of TOCA Social London. Travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "An exclusive Q&A with Virgil van Dijk",
      "detailDesc": `<p>Get an exclusive Q&A session with Virgil van Dijk, and access to play in a private area of an interactive activity venue for you and a guest! Travel and accommodation excluded.</p>`
    }
    ]
  },
  {
    "id": "entertainment",
    "imageAlt": "Box of popcorn graphic",
    "circledImage": CircledEntertainment,
    "circledImageMob": CircledEntertainmentMob,
    "title": "Entertainment",
    "fullDetails": [{
      "detailTitle": "Walk the purple carpet at ODEON",
      "detailDesc" : `<p>Be treated like royalty when you watch an exclusive viewing of 'Paddington in Peru'. Food/refreshments, travel and accommodation excluded.</p>`
    }]
  },
  {
    "id": "festivals",
    "imageAlt": "Racing flag graphic",
    "circledImage": CircledFestivals,
    "circledImageMob": CircledFestivalsMob,
    "title": "Festivals",
    "fullDetails": [{
      "detailTitle": "Immerse yourself in the greatest celebration of motorsport and car culture - Goodwood Festival of Speed",
      "detailDesc": `<p>Win two of the hottest, fastest tickets to the unmissable Festival of Speed for you and a friend. Travel, accommodation, food and drinks excluded. </p>`
    },
    {
      "detailTitle": "Rev up for the Goodwood revival",
      "detailDesc": `<p>Experience the magic of all things vintage fashion and historic motorsport at the Goodwood Revival. Get your best vintage glad rags ready! Travel, accommodation, food and drinks excluded.</p>`
    },
    {
      "detailTitle": "Spellbinding horseracing experience at 'Glorious Goodwood'",
      "detailDesc": `<p>Tickets for you and a thoroughbred friend to a highlight in the UK's flat racing calendar, the Qatar Goodwood Festival. Travel, accommodation, food and drinks excluded.</p>`
    },
    {
      "detailTitle": "A dog event like no other - Goodwoof",
      "detailDesc": `<p>Woof woof! Here's a treat. Win tickets for you and your best friend (does not have to be furry) to the newest dog event in town, Goodwoof. Travel, accommodation, food and drinks excluded.</p>`
    },
    {
      "detailTitle": "Win Winter Wonderland",
      "detailDesc": `<p>Explore the magic of Hyde Park Winter Wonderland at your leisure with tickets for you and 3 other friends. You even get a free hot chocolate each to enjoy! Travel and accommodation excluded.</p>`
    }
    ],
  },
  {
    "id": "sport",
    "imageAlt": "Football grpahic",
    "circledImage": CircledSport,
    "circledImageMob": CircledSportMob,
    "title": "Sport",
    "fullDetails": [{
      "detailTitle": "Tickets to a women's football game at Emirates Stadium",
      "detailDesc": `<p>Get your hands on 4 x General Admission tickets to an Arsenal Women's football game at Emirates Stadium! Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Tickets to a women's football game at Villa Park",
      "detailDesc": `<p>Get your hands on 4 x General Admission tickets to a pulsating Villa Women's football game at Villa Park stadium! Food/refreshments, travel and accommodation excluded.</p>`
    },
    {

      "detailTitle": "Tickets to Chelsea women's football game at The Bridge",
      "detailDesc": `<p>Get your hands on 4 x General Admission tickets to a Chelsea Women's football game at Stamford Bridge. Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Tickets to Liverpool FC Women's football game",
      "detailDesc": `<p>Get your hands on 4 x General Admission tickets to a Liverpool Women's football game. Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Tickets to Man United Women's home game at Old Trafford",
      "detailDesc": `<p>Get your hands on 4 x General Admission tickets to a Manchester United Women's football game at Old Trafford. Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Show me the money - £1000",
      "detailDesc": `<p>Spend £1000 on whatever experience you like, from some of the best sporting and music events across the globe. Redeemable when booking a experience through Engage Hospitality <a href='https://engagehospitality.co.uk/' target='_blank' rel='noopener noreferrer'>https://engagehospitality.co.uk/</a> Food, drinks, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Tickets to watch your favourite club play",
      "detailDesc": `<p>As long as one of your favourite teams are one of these: Arsenal, Birmingham City, Chelsea, Leeds, Liverpool, Man United, Spurs, Celtic, or Rangers! If so get 2X General Admission tickets to see them. If these are not one of your teams, this one's not for you! Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Play on the pitch at Emirates Stadium",
      "detailDesc": `<p>You read that right. One winner plus 3 guests will get to get on the hallowed turf at Emirates Stadium for a truly unique fan experience. Travel, kit and accommodation not included.</p>`
    },
    {
      "detailTitle": "'Under The Bridge' movie night at Chelsea",
      "detailDesc": `<p>See Chelsea like never before, watch a movie right beneath the famous Stamford Bridge stadium. Event tickets for you and a mate (plus Cadbury choc bar of course). Food/refreshments and accommodation not included.</p>`
    },
    {
      "detailTitle": "Exclusive away match screening at Elland Road",
      "detailDesc": `<p>You and a friend can experience all the thrills of a live match with other Leeds fans as you watch the mighty Leeds play away on giant screens within Elland Road stadium! Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Watch Liverpool FC play away from home at Anfield!",
      "detailDesc": `<p>Access to an exclusive away match screening at Anfield You and a friend can experience all the thrills of a live match with other Liverpool fans as you watch a screening of the mighty Reds playing away within Anfield stadium! Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": "Old Trafford stadium tour!",
      "detailDesc": `<p>2 tickets for you and a friend to enjoy the freedom of Manchester United's hallowed ground with Cadbury. Food/refreshments, travel and accommodation excluded.</p>`
    },
    {
      "detailTitle": `Take on iconic F1<sup>&#174;</sup> tracks at F1 <sup>&#174;</sup> Arcade`,
      "detailDesc": `<p>Experience the glitz, glamour and adrenaline of Formula 1&#174; with exclusive racing sessions for Cadbury winners with food and beverages included! Jump in the driver's seat and take on some of F1's most historic tracks, from Silverstone to Suzuka!</p>
            <p>Exclusive driving sessions for Cadbury winners and your own private room with food and beverages included!</p>`
    }
    ],
  },
  {
    "id": "travel",
    "imageAlt": "Pair of plane tickets grpahic",
    "circledImage": CircledTravel,
    "circledImageMob": CircledTravelMob,
    "title": "Travel",
    "fullDetails": [{
      "detailTitle": "A 5 star family Jet2Holiday!",
      "detailDesc": `<p>Relax, you've got this! Cadbury and Jet2holidays are offering the chance to win a 5* all inclusive family holiday to Greece, Turkey or Tenerife, including the full Jet2holidays package with return <a href='https://www.jet2.com/' target='_blank' rel='noopener noreferrer'>Jet2.com</a> flights from a choice of 12 UK airports, 22kg baggage per person, in-resort transfers and ABTA and ATOL protection.</p>`
    },
    {
      "detailTitle": "Ultimate VIP all-inclusive summer Jet2Holiday ",
      "detailDesc": `<p>Cadbury and Jet2holidays are offering the chance to win a 5* all-inclusive family holiday to Greece, Turkey or Tenerife, including the full Jet2holidays package with return <a href='https://www.jet2.com/' target='_blank' rel='noopener noreferrer'>Jet2.com</a> flights from a choice of 12 UK airports, 22kg baggage per person, in-resort transfers and ABTA and ATOL protection. You'll even get a trip to the <a href='https://www.jet2.com/' target='_blank' rel='noopener noreferrer'>Jet2.com</a> training flight simulator! Airport travel and spending money not included.</p>`
    }, {
      "detailDesc": `<p>There are no Travel Prizes to be won in Draw Week 6 (entries between 10:00 09/09/24 & 09:59 16/09/24) and Draw Week 8 (entries between 10:00 23/09/24 & 09:59 30/09/24) of the promotion. If you select the entry category Travel in one of these entry weeks your entry will automatically be rolled into the next Travel Weekly Draw.</p>`
    }],
  },
]

export default prizeList;