import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import Step from 'components/Step/Step';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

import KeyVisual from 'containers/KeyVisuals/KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How1_alt from 'assets/images/campaign/how-1--alt.png';
import How1_alt_2x from 'assets/images/campaign/how-1--alt@2x.png';
import How2 from 'assets/images/campaign/how-2.png';
import How2_2x from 'assets/images/campaign/how-2@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How3Euro from 'assets/images/campaign/how-3-euro.png';
import How3Euro_2x from 'assets/images/campaign/how-3-euro@2x.png';
import How4 from 'assets/images/campaign/how-4--roi.png';
import How4_2x from 'assets/images/campaign/how-4--roi@2x.png';
import How4GB from 'assets/images/campaign/how-4.png';
import How4GB_2x from 'assets/images/campaign/how-4@2x.png';

import How5 from 'assets/images/campaign/how-5.png';
import How5_2x from 'assets/images/campaign/how-5@2x.png';
import How6 from 'assets/images/campaign/how-6--roi.png';
import How6_2x from 'assets/images/campaign/how-6--roi@2x.png';
import How6GB from 'assets/images/campaign/how-6.png';
import How6GB_2x from 'assets/images/campaign/how-6@2x.png';


//PRIZES
import Voucher from 'assets/images/campaign/prizes/voucher.png';
import Voucher2x from 'assets/images/campaign/prizes/voucher@2x.png';
import VoucherDesk from 'assets/images/campaign/prizes/voucher-desktop.png';
import VoucherDesk2x from 'assets/images/campaign/prizes/voucher-desktop@2x.png';
import VoucherJet2 from 'assets/images/campaign/prizes/voucher-jet2.png';
import VoucherJet22x from 'assets/images/campaign/prizes/voucher-jet2@2x.png';
import VoucherJet2Desk from 'assets/images/campaign/prizes/voucher-jet2-desktop.png';
import VoucherJet2Desk2x from 'assets/images/campaign/prizes/voucher-jet2-desktop@2x.png';

import analyticsService from 'services/analyticsService';
import CountrySelect from './CountrySelect';

import './Home.scss';

import prizeList from 'utils/prizes';
import LogoBanner from 'components/LogoBanner/LogoBanner';

import PrizeCard from 'components/Prize/PrizeCard';
import Carousel from 'components/Carousel/Carousel';
import PrizeAccordions from 'components/PrizeAccordions/PrizeAccordions';
import Modal from 'components/Modal/Modal';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let history = useHistory();
  const location = useLocation();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsNotStarted = compState === "NotStarted";
  let competitionIsMopUp = compPhaseId === "mop-up";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);
  const [showPrizeDetailsModal, setShowPrizeDetailsModal] = useState(false);
  const [showDetailsInfoModal, setShowDetailsInfoModal] = useState(false);
  const [detailsInfoModalContent, setDetailsInfoModalContent] = useState(null);
  const [modalFocusTrap, setModalFocusTrap] = useState(false);
  
  

  let isROI = settings.country === "ROI" ? true : false;

  let currency = (settings.country === "ROI" ? "€" : (settings.country === "GB" || settings.country === "NI") ? "£" : "£/€");


  useEffect(() => {
    if(settings.otConsentChanged !== undefined && settings.otConsentChanged){
      setModalFocusTrap(true);
    }
  }, [settings.otConsentChanged]);

  const handleCountrySelected = (data) => {
    sessionStorage && sessionStorage.setItem('cadbury200.country', data.country);
    settingsState.setCountry(data.country);
    setShowCountryModal(false);
  }

  const handleEnterNow = async (e, comp) => {
    
    if (settings.country === "GB" || settings.country === "NI") {
      analyticsService.logEvent("button_click", "site_modal", {
        location: comp,
        label: "proof of purchase modal",
        url: ""
      });

      setShowEntryModal(true);
    } else {
      var roiUrl = "/prize-select";
      var roiLabel = "prize selection page";

      if(competitionIsMopUp){
        roiUrl = "/registration";
        roiLabel = "entry registration page";
      }

      analyticsService.logEvent("button_click", "internal_link", {
        location: comp,
        label: roiLabel,
        url: roiUrl
      });      
      history.push(roiUrl);
    }
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }
/*
  const handleDetailsInfoModal = (e) => {
    var curr_prize = e.currentTarget.id.split("_")[1];

    var probContent = prizeList.filter(function (entry) {
        return entry.id === curr_prize;
    });
    
    setDetailsInfoModalContent(probContent[0].cardDetailsInfo);
    setShowDetailsInfoModal(true);
  }*/

  const onHideDetailsInfoModal = () => {
    setShowDetailsInfoModal(false);
    setDetailsInfoModalContent(null);
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const handlePrizeDetailsModal = () => {
    setShowPrizeDetailsModal(true);
  }

  const onHidePrizeDetailsModal = () => {
    setShowPrizeDetailsModal(false);
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }


  const renderEntryCta = (title, component) => {
    if (!settings.country) return null;

    return <Button
      wide
      type="submit"
      title={title ? title : "Enter Now"}
      onClick={e => handleEnterNow(e, component)}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
        {
          !competitionIsMopUp ? (
            <div className="home__intro home__intro--wide home__intro--padding">
              <div className="home__intro-body">
                {
                  isROI ? (
                    <p>
                      To celebrate, we wanted to give a heartfelt thanks to you, the nation's Cadbury lovers for being by our side, so we've created our <b>biggest ever prize and reward giveaway</b>.
                    </p>
                  ) : (
                    <p>
                      To celebrate our 200th Anniversary, we wanted to give a heartfelt thanks to you, the nation's Cadbury lovers for being by our side, so we've created our <b>biggest ever prize and reward giveaway</b>.
                    </p>
                  )
                }
                <p>We're giving you the chance to win extraordinary days out with family and friends and create new memories you'll always cherish.</p>
                <p><strong>Plus you will bag a Jet2holidays £75 discount voucher with your first entry!</strong></p>
              </div>

              <div className="home__cta">
                {renderEntryCta("Enter now", "home_entry")}
              </div>
            </div>
          ) : (
            <div className="home__intro home__intro--slim">
              <h2 className='type-h3 type-h3--white'>Win a day to remember has now finished, but don't worry!</h2>
              
              <div className="home__intro-body home__intro-body--slim">
                <p>We've got an EPIC prize draw with the chance to win a {currency}250 cash prize.</p>
                
              </div>
              <div className="home__cta">
                {renderEntryCta("Enter now", "home_entry")}
              </div>
            </div>
          )
        }
          
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <h2 className='type-h3'>Coming 5th august 2024</h2>
            <div className='home__intro-body'>
              {
                isROI ? (
                  <p>
                  We're giving back to the nation with our biggest ever prize and reward giveaway. Everyone is in with a shot of winning a day they'll cherish forever.
                  </p>
                ) : (
                  <p>
                    To celebrate our 200th Anniversary, we're giving back to the nation with our biggest ever prize and reward giveaway. Everyone is in with a shot of winning a day they'll cherish forever.
                  </p>
                )
              }
             
              <p>Get ahead of the rest, sign up to receive Cadbury emails for a reminder of when to enter and more details on how you can win.</p>
            </div>
            <div className="home__cta">
            {
              isROI ? (
                <Link 
                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "holding page introduction", label: "cadbury.ie newsletter sign up"}}
                to="https://www.cadbury.ie/newsletter-sign-up/" lozenge title="Sign up now" >Sign up now</Link>
            
              ) : (
                <Link 
                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "holding page introduction", label: "cadbury.co.uk newsletter sign up"}}
                to="https://www.cadbury.co.uk/newsletter-sign-up/" lozenge title="Sign up now" >Sign up now</Link>
              )
            }
            </div>
          </div>

        </>

      case 'Closed':
        return <>
          <div className="home__intro home__intro--slim">

            <h2 className='type-h3 type-h3--white'>Win a day to remember has now finished, but don't worry!</h2>
            <div className="home__intro-body">
              {
                isROI ? (
                  <p>
                    Check out Cadbury.ie for more competitions and information.
                    </p>
                ) : (
                  <p>
                    Check out Cadbury.co.uk for more competitions and information.
                  </p>
                )
              }
            </div>
          </div>
          <div className="home__cta">
            {
              isROI ? (
                <Link 
                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "closed competition introduction", label: "cadbury.ie site"}}
                to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
              ) : (
                <Link 
                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "closed competition introduction", label: "cadbury.co.uk site"}}
                to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
              )
            }
          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Cadbury - Win a Day to Remember</title>
      </Helmet>
      {competitionIsOpen &&
        <>
          <CodeEntry
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push(!competitionIsMopUp ? "/prize-select":"/registration") } 
          />
        </>
      }

      <CountrySelect
        show={showCountryModal}
        onCountrySelected={handleCountrySelected}
        focusTrapActive={modalFocusTrap}
      />

      {
        (competitionIsOpen && !competitionIsMopUp && showPrizeDetailsModal) &&
        <Modal 
          panel 
          panelWide
          prize
          onDismiss={onHidePrizeDetailsModal}
          closeAnalytics={{evnt: "button_click", category: "close_modal", location: "home_carousel_prize_details", label: "close prize details modal"}}
          >
            <PrizeAccordions isROI={isROI} isModal />
        </Modal>
      }

      <h1 className="visually-hidden">Cadbury - Win a Day to Remember</h1>

        <KeyVisual 
          video={!isROI ? true : false}
          country={settings.country}
        />
          
        <section className={"layout-wrapper home__entrybar relative " }>
        <div className={"layout-container layout-container--bottom-space layout-container--center " + (!competitionIsMopUp  ? "layout-container--entry" : "layout-container--pb0")}>
            {renderIntroMessage(compState)}
        </div>
        </section>

        
        {(competitionIsOpen && !competitionIsMopUp) &&
          <>
            <LogoBanner country={settings.country}
            />

            <section className='layout-wrapper home__prizes'>
              <div className="layout-container layout-container--center">
                <div className="home__prizes__intro-container">
                  <div className='home__prizes__intro'>
                    <h2 className="type-h1">Our top tier days out prizes</h2>
                    <p>Each week we've got <strong>THOUSANDS</strong> of exciting days out and experiences up for grabs across 5 exciting categories.</p>
                  </div>
                  <div className='home__prizes__nav'>
                    <button type="button" className='swiper-button-prev'></button>
                    <div className='swiper-pagination'></div>
                    <button type="button"  className='swiper-button-next'></button>
                  </div>
                </div>
                <div className="home__carousel">
                  <Carousel 
                    className="home__prize-list"
                    params={{
                      spaceBetween: 30,
                      slidesPerView: 1,
                      centeredSlides: false,
                      pagination: {
                        el: '.swiper-pagination',
                        type: 'fraction'
                      },
                      navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      },
                      runCallbacksOnInit: true,
                      slidesPerGroup: 1,
    
                      breakpoints: {
                        768: {
                          slidesPerView: 2.5,
                          spaceBetween: 30,
                          slidesPerGroup: 2,
                        },
                        1200: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                          slidesPerGroup: 3
                        }
                      }
                    }}
                    >
                    { 
                      prizeList.map((prize, index) => {
                        return (
                          <PrizeCard 
                          key={prize.id+"_"+index} 
                          id={prize.id}
                          alt={prize.alt}
                          isROI={isROI}
                          linkTitle={prize.cardLinkTitle}
                          linkUrl={"/prize-details#prize-"+prize.id}
                          //onClick={handleDetailsInfoModal}
                          {...prize} />
                        )
                      })
                    }
                  </Carousel>

                  {/*showDetailsInfoModal &&
                    <Modal 
                      panel 
                      className="modal--details-info"
                      onDismiss={onHideDetailsInfoModal}>
                      <div className="card-details" dangerouslySetInnerHTML={{ __html: detailsInfoModalContent }}></div>
                      </Modal>
                  */}
                </div>
                
                <div className='home__prizes__footer'>
                  <div className='type-h3 type-h3--white'>
                    To see more details of our grand prizes view our  
                    <Button
                      type="button"
                      title={"prize details"}
                      className="button--text-link"
                      analytics={{evnt: "button_click", category: "internal_link", location: "home_carousel_prize_details", label: "open prize details modal"}}
                      onClick={handlePrizeDetailsModal}
                    />
                  </div>
                </div>
              </div>
            </section>
          
          <section className={"layout-wrapper home__prize-block-section layout-wrapper--bottom-ellipse " + (!competitionIsMopUp ? 'layout-wrapper--bottom-ellipse--secondary' : '') }>
            <div className="layout-container layout-container--center">

              <div className="home__prize-blocks">
              
                    <PrizeBlock
                      imageMob={Voucher}
                      imageMobLarge={Voucher2x}
                      image={VoucherDesk}
                      imageLarge={VoucherDesk2x}
                      title="Instant rewards and vouchers"
                      description={`<p>Every single time you enter, you have the chance to <strong>WIN</strong> one of thousands of vouchers and rewards.</p>`}
                    />


                    <PrizeBlock
                      imageMob={ VoucherJet2}
                      imageMobLarge={VoucherJet22x}
                      image={VoucherJet2Desk}
                      imageLarge={VoucherJet2Desk2x}
                      title={`Your 1<sup>st</sup> entry gets a £75 Jet2holidays discount voucher!`}                 
                      description={`<p>Yes, you read that right! You will bag a <strong>Jet2holidays £75 discount voucher with your first entry!</strong> This will be emailed to you upon successful completion of your first entry. Vouchers are limited to one per person and will be issued after your first entry only<br> (don't forget to check your junk folder). </p>`}
                      alt={`£75 Jet2 holiday voucher`}
                      reverse
                   />

                
              </div>
            </div>
          </section>
          </>
        }

      {
        (competitionIsOpen) &&
        <section className={"layout-wrapper home__howitworks " + (!competitionIsMopUp ? "bg-secondary" : "")} id="how-to-play">
          <div className="layout-container layout-container--center">
            {
            (!competitionIsMopUp) ? (
              <>
              <h2 className="type-h1 type-h1--xl">How to enter </h2>
              <p className="home__howitworks__intro">It's easy to enter, just follow these simple steps:</p>
            </>
            ) : (
              <h2 className="type-h1 type-h1--xl">How does it work?</h2>
            )
        
            }
            <div className={isROI || competitionIsMopUp? "home__steps home__steps--wide": "home__steps"}>
            {
                (!competitionIsMopUp) ? (

                    (isROI) ?
                      (
                        renderSteps([
                          { number: 1, title: "Scan the QR code on pack or visit winaday.cadbury.ie", image: How5, image2x: How5_2x, alt: "Cadbury products"},
                          { number: 2, title: "Select your favourite level 1 prize category (this is the hardest bit!)", image: How4, image2x: How4_2x, alt: "A selection of level 1 prizes." },
                          { number: 3,  title: "Enter the one-time passcode sent to mobile", image: How1, image2x: How1_2x, alt: "One-time passcode entry screen on mobile." },
                          { number: 4, title: "Find out on-screen if you've won an epic voucher reward", image: How6, image2x: How6_2x, alt: "Win a day to remember" }
                        ])
    
                      ) : (
                        renderSteps([
                          { number: 1, title: "Buy a Promotional Product", image: How5, image2x: How5_2x, alt: "Cadbury products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                          { number: 2, title: "Enter the barcode and batch code, and your personal details", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Cadbury pack" },
                          { number: 3, title: "Select your favourite level 1 prize category (this is the hardest bit!)", image: (isROI ? How4 : How4GB), image2x: (isROI? How4_2x: How4GB_2x), alt: "A selection of level 1 prizes." },
                          { number: 4, title: "Enter the one-time passcode sent to mobile", image: How1, image2x: How1_2x, alt: "One-time passcode entry screen on mobile." },
                          { number: 5, title: "Find out on-screen if you've won an epic voucher reward", image: (isROI ? How6 : How6GB), image2x: (isROI ? How6_2x : How6GB_2x), alt: "Win a day to remember" }
                        ])
                      )
                      
                     
                  ) : (
                    
                      (isROI) ?
                        (
                          renderSteps([
                            { number: 1, title: "Scan the QR code on pack or visit winaday.cadbury.ie", image: How5, image2x: How5_2x, alt: "Cadbury products"},
                            { number: 2,  title: "Enter the one-time passcode sent to mobile", image: How1_alt, image2x: How1_alt_2x, alt: "One-time passcode entry screen on mobile." },
                            { number: 3, title: "Follow the instructions to enter our final prize draw!", image: How3Euro, image2x: How3Euro_2x, alt: "€250"},
                          ])
      
                        ) : (
                          renderSteps([
                            { number: 1, title: "Buy a participating Cadbury product", image: How5, image2x: How5_2x, alt: "Cadbury products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                            { number: 2, title: "Enter the barcode and batch code of your product", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Cadbury pack" },
                            { number: 3,  title: "Enter the one-time passcode sent to mobile", image: How1_alt, image2x: How1_alt_2x, alt: "One-time passcode entry screen on mobile." },
                            { number: 4, title: "Follow the instructions to enter our final prize draw!", image: How3, image2x: How3_2x, alt: "£250"},
                          ])
                        )
      
                    
                  )
            }
            
            </div>
          {
            !competitionIsMopUp && (
              isROI ? (
                <p>The prize draw for our epic level 1 prizes will take place weekly!</p>
              ) : (
                <p>The prize draw for our epic level 1 prizes will take place weekly! So keep on entering with each pack you purchase.</p>
              )
            )
          }

            

            <div className="home__cta">
              {renderEntryCta("Enter Now", "home_howto_steps")}
            </div>
          </div>
        </section>
      }

      {
        (competitionIsNotStarted || competitionIsOpen) &&
        <SummaryTsandCs className={(competitionIsOpen && !competitionIsMopUp ? "bg-secondary" : "")} />
      }

    </div >
  );
}