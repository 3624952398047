import React, {useRef} from 'react';
import classList from 'react-classlist-helper';
import './Accordion.scss';
import timer from 'utils/timer';

export default (props) => {
  let elementRef = useRef();

  const classMap = {
    "accordion": true,
    "accordion--open": props.open,
    [props.className]: !!props.className,
  };

  const handleOnClick = () => {
    if(props.onClick){
      props.onClick();
    
      if(!props.open && !props.isModal){ 
          timer.delay(100).then(() => {
            var heightTop = elementRef.current.offsetTop - 200 ;

            window.scrollTo({
              top: heightTop,
              behavior: 'smooth'
            })
          });
       
      }
    }
  }

  return (
    <div id={props.id} className={classList(classMap)} ref={elementRef}>
      <button className="accordion__toggle" type="button" tabIndex="0" onClick={handleOnClick}>

        {
          (props.image && props.imageMob) &&
          <div className='accordion__image'>
            <picture>
                <source media="(min-width: 769px)" srcSet={props.image} type="image/png" />
                <img src={props.imageMob} alt={props.imageAlt} />
            </picture>
          </div>
        }
  
        <p>{props.title}</p>
        <div className="accordion__icon">
          <span></span>
          <span></span>
        </div>
      </button>
     

      {
       (!Array.isArray(props.answer)) ? (
        <div className="accordion__body" dangerouslySetInnerHTML={{ __html: props.answer }} ></div>
        ) : (
          <div className="accordion__body">
            {
              props.answer.map((item, index) => {
                return <>
                
                  {item.detailImage && (
                    item.detailImage2x ? (
                      <img src={item.detailImage} srcSet={item.detailImage2x + " 2x, " + item.detailImage + " 1x"} className="accordion__logo" alt="" />
                    ) : (
                      <img class="accordion__logo" src={item.detailImage} alt="" />
                    )
                  )}

                  <div className='type-h3 type-h3--white' dangerouslySetInnerHTML={{ __html: item.detailTitle }}></div>
                  <div dangerouslySetInnerHTML={{ __html: item.detailDesc }}></div>
                </>
              })
            }
          </div>
        ) 
      }
    </div>
  );
}