import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Claim from './Claim';
import useSettingsContext from 'contexts/useSettingsContext';
import OTPClaim from 'containers/OneTimePassword/OTPClaim';
import useEffectOnce from 'hooks/useEffectOnce';
import Button from 'components/Button/Button';
import prizeClaimService from 'services/prizeClaimService';

export default () => {
  let { token } = useParams();
  //let token  = "test";
  let settings = useSettingsContext();
  let [claimCreatedDate, setClaimCreatedDate] = useState("");
  let [otpClaimStartDate, setOtpClaimStartDate] = useState("");
  let [prizeCategory, setPrizeCategory] = useState("");
  let [pageStage, setPageState] = useState("loading"); 
  let [tier1ClaimReopen, setTier1ClaimReopen] = useState(false);
  //let [pageStage, setPageState] = useState("fetched"); 

  let otpClaimAvilable = settings.otpClaimEnabled === "True" ? true : false;
  //let otpClaimAvilable = true;


  useEffectOnce(() => {
    async function fetchData() {
      await prizeClaimService.getPrizeClaim(token).then((data) => {        
        if(data) {
          setClaimCreatedDate(new Date(data.createDate));
          setOtpClaimStartDate(new Date(data.otpStartDate));
          setPrizeCategory(data.prize.category.publicId);
          setTier1ClaimReopen(data.tier1ClaimReopen);          
        } 
        setPageState("fetched");

      })
      .catch((err) => {
        setPageState("error");
      });
    }

    fetchData();

  });


  if(pageStage === "fetched") {    
    //if(otpClaimAvilable && token && !tier1ClaimReopen) {
    if(otpClaimAvilable && token && prizeCategory === "tier-2") {
      return (
        <OTPClaim 
          token={token}
        />
      )
    } else if (token) {
      return (
        <Claim 
          otpAvilable={false}
          token={token}
        />
      )
    } else {
    return <Redirect to='/' />
    }
  } else if (pageStage === "error") {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Error | Cadbury - Win a Day to Remember</title>
          </Helmet>
          <section className="layout-general layout-general--full layout-wrapper">
            <div className="layout-container layout-container--center ">
              <h1 className="type-h2">Error!</h1>
              <p className="thankyou__para">
                Sorry, something went wrong.
              </p>
              <Button title="Back to homepage" href="/" className="mt-20" />
            </div>
          </section>
        </>
      )
  } else if (pageStage === "loading") {
    return (
      <div>Loading...</div>
    )
  }

}
