import React from 'react';
import { Helmet } from 'react-helmet';
import './PrizeDetails.scss';
import SettingsContext from 'contexts/SettingsContext';

import LogoBanner from 'components/LogoBanner/LogoBanner';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import PrizeAccordions from 'components/PrizeAccordions/PrizeAccordions';

import PrizeBlock from 'components/Prize/PrizeBlock';
//PRIZES
import Voucher from 'assets/images/campaign/prizes/voucher.png';
import Voucher2x from 'assets/images/campaign/prizes/voucher@2x.png';
import VoucherDesk from 'assets/images/campaign/prizes/voucher-desktop.png';
import VoucherDesk2x from 'assets/images/campaign/prizes/voucher-desktop@2x.png';
import VoucherJet2 from 'assets/images/campaign/prizes/voucher-jet2.png';
import VoucherJet22x from 'assets/images/campaign/prizes/voucher-jet2@2x.png';
import VoucherJet2Desk from 'assets/images/campaign/prizes/voucher-jet2-desktop.png';
import VoucherJet2Desk2x from 'assets/images/campaign/prizes/voucher-jet2-desktop@2x.png';
import KeyVisual from 'containers/KeyVisuals/KeyVisual';


export default (props) => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let isROI = settings.country === "ROI" ? true : false;
  let currency = (settings.country === "ROI" ? "€" : (settings.country === "GB" || settings.country === "NI") ? "£" : "£/€");
  
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Prize Details | Cadbury - Win a Day to Remember</title>
    </Helmet>

    <KeyVisual 
          video={!isROI ? true : false}
          country={settings.country}
        />

    <section className={"layout-wrapper home__entrybar "}>
      <div className="layout-container layout-container--slim layout-container--center">
        <div className="home__intro">

          <h2 className='type-h2'>Our top tier days out prizes</h2>
          <div className="home__intro-body">
            <p>
            Below are all of our incredible celebratory days out that you can win. From completely exclusive meets with sporting stars and 'purple carpet' cinema premieres, to unique racing events for all the family, there are so many to choose from.
            </p>
          </div>
        </div>
        
      </div>
    </section>

    <LogoBanner country={settings.country} />

    <section className={"layout-wrapper "} >
      <div className="layout-container layout-container--center ">
        <PrizeAccordions isROI={isROI} />
      </div>
    </section>

    
    <section className={"layout-wrapper home__prize-block-section " }>
      <div className="layout-container layout-container--center">
      <h2 className="type-h2">There are also thousands of vouchers & rewards to be won</h2>
        <div className="home__prize-blocks">
        
          <PrizeBlock
            imageMob={Voucher}
            imageMobLarge={Voucher2x}
            image={VoucherDesk}
            imageLarge={VoucherDesk2x}
            title="Instant rewards and vouchers"
            description={`<p>Every single time you enter, you have the chance to <strong>WIN</strong> one of thousands of vouchers and rewards.</p>`}
            toggleInfo={{
              "title" : "Find out what rewards and vouchers you could win",
              "content" : `
                <ul>
                  <li>	2 free entry tickets to Alton Towers Resort	</li>
                  <li>	2 free entry tickets to Cadbury World 	</li>
                  <li>	2 free entry tickets to Chessington World of Adventures	</li>
                  <li>	2 x F1<sup>&#174;</sup> Arcade racing tickets!	</li>
                  <li>	2 free entry tickets to LEGOLAND<sup>&#174;</sup> Discovery Centre	</li>
                  <li>	2 free entry tickets to LEGOLAND<sup>&#174;</sup> Windsor Resort	</li>
                  <li>	2 free entry tickets to The London Eye	</li>
                  <li>	2 free entry tickets to Madame Tussauds	</li>
                  <li>	A free ODEON cinema ticket when you buy another at full price 	</li>
                  <li>	2 free entry tickets to SEA LIFE	</li>
                  <li>	2 free entry tickets to Shrek's Adventure	London</li>
                  <li>	A £4.99 Sky Store movie voucher	</li>
                  <li>	2 free entry tickets to The Dungeons attraction 	</li>
                  <li>	2 free entry tickets to Thorpe Park	</li>
                  <li>	50% off box hire at TOCA Social London or Birmingham venues 	</li>
                  <li>	20% off at Virgin Experience Days	</li>
                  <li>	A £15 discount voucher off a Visit Britain shop experience, supplied by partner Golden Tours!	</li>
                  <li>	2 free entry tickets to Warwick Castle	</li>
                </ul>
              `
            }}
          />


          <PrizeBlock
            imageMob={ VoucherJet2}
            imageMobLarge={VoucherJet22x}
            image={VoucherJet2Desk}
            imageLarge={VoucherJet2Desk2x}
            title={`Your 1<sup>st</sup> entry gets a £75 Jet2holidays discount voucher!`}                  
            description={`<p>Yes, you read that right! You will bag a <strong>Jet2holidays £75 discount voucher with your first entry!</strong> This will be emailed to you upon successful completion of your first entry. Vouchers are limited to one per person and will be issued after your first entry only<br> (don't forget to check your junk folder). </p>`}
            alt={`£75 Jet2 holiday voucher`}
            reverse
          />

          
        </div>
      </div>
    </section>

    <SummaryTsandCs />
    </>
  );
}