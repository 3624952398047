import React, {useState} from 'react';
import classList from 'react-classlist-helper';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './LogoBanner.scss';

import altontowers from 'assets/images/campaign/logos/logo-banner/altontowers.png' 
import altontowers2x from 'assets/images/campaign/logos/logo-banner/altontowers@2x.png' 
import arcade from 'assets/images/campaign/logos/logo-banner/arcade--black.png' 
import arcade2x from 'assets/images/campaign/logos/logo-banner/arcade--black@2x.png' 
import arsenal from 'assets/images/campaign/logos/logo-banner/arsenal.png' 
import arsenal2x from 'assets/images/campaign/logos/logo-banner/arsenal@2x.png' 
import avfc from 'assets/images/campaign/logos/logo-banner/avfc.png' 
import avfc2x from 'assets/images/campaign/logos/logo-banner/avfc@2x.png' 
import cadworld from 'assets/images/campaign/logos/logo-banner/cadburyworld.png' 
import cadworld2x from 'assets/images/campaign/logos/logo-banner/cadburyworld@2x.png' 
import celtic from 'assets/images/campaign/logos/logo-banner/celtic.png' 
import celtic2x from 'assets/images/campaign/logos/logo-banner/celtic@2x.png' 
import chelsea from 'assets/images/campaign/logos/logo-banner/chelsea.png' 
import chelsea2x from 'assets/images/campaign/logos/logo-banner/chelsea@2x.png' 
import chessington from 'assets/images/campaign/logos/logo-banner/chessington.png' 
import chessington2x from 'assets/images/campaign/logos/logo-banner/chessington@2x.png' 
import dungeons from 'assets/images/campaign/logos/logo-banner/dungeons.png' 
import dungeons2x from 'assets/images/campaign/logos/logo-banner/dungeons@2x.png' 
import goodwood from 'assets/images/campaign/logos/logo-banner/goodwood.png' 
import goodwood2x from 'assets/images/campaign/logos/logo-banner/goodwood@2x.png' 
import greatbritain from 'assets/images/campaign/logos/logo-banner/greatbritain.png' 
import greatbritain2x from 'assets/images/campaign/logos/logo-banner/greatbritain@2x.png' 
import jet2 from 'assets/images/campaign/logos/logo-banner/jet2.png' 
import jet22x from 'assets/images/campaign/logos/logo-banner/jet2@2x.png' 
import leeds from 'assets/images/campaign/logos/logo-banner/leeds.png' 
import leeds2x from 'assets/images/campaign/logos/logo-banner/leeds@2x.png' 
import legoland from 'assets/images/campaign/logos/logo-banner/legoland.png' 
import legoland2x from 'assets/images/campaign/logos/logo-banner/legoland@2x.png' 
import legolandwindsor from 'assets/images/campaign/logos/logo-banner/legolandwindsor.png' 
import legolandwindsor2x from 'assets/images/campaign/logos/logo-banner/legolandwindsor@2x.png' 
import lfc from 'assets/images/campaign/logos/logo-banner/lfc.png' 
import lfc2x from 'assets/images/campaign/logos/logo-banner/lfc@2x.png' 
import londoneye from 'assets/images/campaign/logos/logo-banner/londoneye.png'
import londoneye2x from 'assets/images/campaign/logos/logo-banner/londoneye@2x.png'  
import madametussauds from 'assets/images/campaign/logos/logo-banner/madametussauds.png' 
import madametussauds2x from 'assets/images/campaign/logos/logo-banner/madametussauds@2x.png' 
import mufc from 'assets/images/campaign/logos/logo-banner/mufc.png' 
import mufc2x from 'assets/images/campaign/logos/logo-banner/mufc@2x.png' 
import odeon from 'assets/images/campaign/logos/logo-banner/odeon.png' 
import odeon2x from 'assets/images/campaign/logos/logo-banner/odeon@2x.png' 
import sealife from 'assets/images/campaign/logos/logo-banner/sealife.png' 
import sealife2x from 'assets/images/campaign/logos/logo-banner/sealife@2x.png' 
import shreks from 'assets/images/campaign/logos/logo-banner/shreks.png' 
import shreks2x from 'assets/images/campaign/logos/logo-banner/shreks@2x.png' 
import skystore from 'assets/images/campaign/logos/logo-banner/skystore.png' 
import skystore2x from 'assets/images/campaign/logos/logo-banner/skystore@2x.png' 
import spurs from 'assets/images/campaign/logos/logo-banner/spurs.png' 
import spurs2x from 'assets/images/campaign/logos/logo-banner/spurs@2x.png' 
import thorpe from 'assets/images/campaign/logos/logo-banner/thorpe.png' 
import thorpe2x from 'assets/images/campaign/logos/logo-banner/thorpe@2x.png' 
import toca from 'assets/images/campaign/logos/logo-banner/toca.png' 
import toca2x from 'assets/images/campaign/logos/logo-banner/toca@2x.png' 
import ved from 'assets/images/campaign/logos/logo-banner/VED.png' 
import ved2x from 'assets/images/campaign/logos/logo-banner/VED@2x.png' 
import warwick from 'assets/images/campaign/logos/logo-banner/warwick.png' 
import warwick2x from 'assets/images/campaign/logos/logo-banner/warwick@2x.png' 
import winterwonderland from 'assets/images/campaign/logos/logo-banner/winterwonderland.png' 
import winterwonderland2x from 'assets/images/campaign/logos/logo-banner/winterwonderland@2x.png'
import blank from 'assets/images/campaign/logos/logo-banner/blank.png'




export default (props) => {

  //If making changes to these logos, you will need to change them in each array. 
  //Each array contains 2 blank images, but in different places for each array.
  //This has been done to make the slides divisible by 3 and to combat functionality/visual issues 

  const [slides, setSlides] = useState([]);

    var mobArr = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item logo-banner__item--mid-left" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item logo-banner__item--ode" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item logo-banner__item--sea" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item  logo-banner__item--left logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="logo-banner__item logo-banner__item--th" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--ved" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    <img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
  ];
  
  var mobArr_ROI = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item logo-banner__item--mid-left" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item logo-banner__item--ode" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item logo-banner__item--sea" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item  logo-banner__item--left logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left " src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--left" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
   // <img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item logo-banner__item--left" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
  ];

  var tabArr_ROI = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item logo-banner__item--good" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item logo-banner__item--jet" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item logo-banner__item--lee" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item logo-banner__item--lwr" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--lfc" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--mt" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item logo-banner__item--mu" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="logo-banner__item logo-banner__item--ss" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--sh" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--th" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--ved" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    //<img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="logo-banner__item logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item  logo-banner__item--ww" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
    <img className="blank" src={blank}/>,
  ];

  var tabArr = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item logo-banner__item--good" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item logo-banner__item--jet" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item logo-banner__item--lee" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item logo-banner__item--lwr" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--lfc" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--mt" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item logo-banner__item--mu" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="logo-banner__item logo-banner__item--ss" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--sh" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--th" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--ved" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    <img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="logo-banner__item logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item  logo-banner__item--ww" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
  ];

  var deskArr = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="logo-banner__item" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
   
    <img className="logo-banner__item logo-banner__item--left" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    
    <img className="logo-banner__item logo-banner__item--left" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item " src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
    <img className="blank" src={blank}/>,
    
  ];

  var deskArr_ROI = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item" src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="logo-banner__item" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,

    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    <img className="blank" src={blank}/>,
    //<img className="logo-banner__item" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="logo-banner__item logo-banner__item--far-left" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="blank" src={blank}/>,
    <img className="logo-banner__item logo-banner__item--far-left" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
    
    
  ];

  var deskLargeArr = [

    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item logo-banner__item--ars" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item logo-banner__item--av" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item logo-banner__item--cel" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item logo-banner__item--che" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwa" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item logo-banner__item--good" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item logo-banner__item--lee" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item " src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item logo-banner__item--lwr logo-banner__item--right" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--lfc" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item logo-banner__item--eye" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--mt logo-banner__item--right" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item logo-banner__item--mu" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item logo-banner__item--ode" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item logo-banner__item--sea" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    
    <img className="logo-banner__item logo-banner__item--sky" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--sh" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item logo-banner__item--tp" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="logo-banner__item logo-banner__item--th" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--ved" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
    <img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="logo-banner__item logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item logo-banner__item--ww" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
    <img className="blank" src={blank} alt=""/>
  ];

  var deskLargeArr_ROI = [
    <img className="logo-banner__item" src={altontowers} srcSet={altontowers2x + " 2x, " + altontowers + " 1x"} alt="Alton Towers Resort Logo" />,
    <img className="logo-banner__item logo-banner__item--ars" src={arsenal} srcSet={arsenal2x + " 2x, " + arsenal + " 1x"} alt="Arsenal FC badge" />,
    <img className="logo-banner__item logo-banner__item--av" src={avfc} srcSet={avfc2x + " 2x, " + avfc + " 1x"} alt="Aston Villa FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwo" src={cadworld} srcSet={cadworld2x + " 2x, " + cadworld + " 1x"} alt="Cadbury World logo" />,
    <img className="logo-banner__item logo-banner__item--cel" src={celtic} srcSet={celtic2x + " 2x, " + celtic + " 1x"} alt="Celtic FC badge" />,
    <img className="logo-banner__item logo-banner__item--che" src={chelsea} srcSet={chelsea2x + " 2x, " + chelsea + " 1x"} alt="Chelsea FC badge" />,
    <img className="logo-banner__item logo-banner__item--cwa" src={chessington} srcSet={chessington2x + " 2x, " + chessington + " 1x"} alt="Chessington World of Adventures Resort logo" />,
    <img className="logo-banner__item logo-banner__item--dun" src={dungeons} srcSet={dungeons2x + " 2x, " + dungeons + " 1x"} alt="The Dungeons logo" />,
    <img className="logo-banner__item logo-banner__item--arc" src={arcade} srcSet={arcade2x + " 2x, " + arcade + " 1x"} alt="F1 Arcade Logo" />,
    <img className="logo-banner__item logo-banner__item--good" src={goodwood} srcSet={goodwood2x + " 2x, " + goodwood + " 1x"} alt="Goodwood Festival of Speed logo" />,
    <img className="logo-banner__item" src={jet2} srcSet={jet22x + " 2x, " + jet2 + " 1x"} alt="Jet2 Holidays logo" />,
    <img className="logo-banner__item logo-banner__item--lee" src={leeds} srcSet={leeds2x + " 2x, " + leeds + " 1x"} alt="Leeds United FC badge" />,
    <img className="logo-banner__item " src={legoland} srcSet={legoland2x + " 2x, " + legoland + " 1x"} alt="Legoland Discovery Centre logo" />,
    <img className="logo-banner__item logo-banner__item--lwr logo-banner__item--right" src={legolandwindsor} srcSet={legolandwindsor2x + " 2x, " + legolandwindsor + " 1x"} alt="Legoland Windsor Resort logo" />,
    <img className="logo-banner__item logo-banner__item--lfc" src={lfc} srcSet={lfc2x + " 2x, " + lfc + " 1x"} alt="Liverpool FC logo" />,
    <img className="logo-banner__item logo-banner__item--eye" src={londoneye} srcSet={londoneye2x + " 2x, " + londoneye + " 1x"} alt="lastminute.com London Eye logo" />,
    <img className="logo-banner__item logo-banner__item--mt logo-banner__item--right" src={madametussauds} srcSet={madametussauds2x + " 2x, " + madametussauds + " 1x"} alt="Madame Tussauds logo" />,
    <img className="logo-banner__item logo-banner__item--mu" src={mufc} srcSet={mufc2x + " 2x, " + mufc + " 1x"} alt="Manchester United FC badge" />,
    <img className="logo-banner__item logo-banner__item--ode" src={odeon} srcSet={odeon2x + " 2x, " + odeon + " 1x"} alt="ODEON logo" />,
    <img className="logo-banner__item logo-banner__item--sea" src={sealife} srcSet={sealife2x + " 2x, " + sealife + " 1x"} alt="Sea Life logo" />,
    <img className="blank" src={blank} alt=""/>,
    <img className="logo-banner__item logo-banner__item--sky" src={skystore} srcSet={skystore2x + " 2x, " + skystore + " 1x"} alt="SKY logo" />,
    <img className="logo-banner__item logo-banner__item--sh" src={shreks} srcSet={shreks2x + " 2x, " + shreks + " 1x"} alt="Shreks Adventure London logo" />,
    <img className="logo-banner__item logo-banner__item--tp" src={thorpe} srcSet={thorpe2x + " 2x, " + thorpe + " 1x"} alt="Thorpe Park logo" />,
    <img className="logo-banner__item logo-banner__item--toc" src={toca} srcSet={toca2x + " 2x, " + toca + " 1x"} alt="Toca Social logo" />,
    <img className="logo-banner__item logo-banner__item--th" src={spurs} srcSet={spurs2x + " 2x, " + spurs + " 1x"} alt="Tottenham Hotspur FC badge" />,
    <img className="logo-banner__item logo-banner__item--ved" src={ved} srcSet={ved2x + " 2x, " + ved + " 1x"} alt="Virgin Experience Days logo" />,
   // <img className="logo-banner__item logo-banner__item--gb" src={greatbritain} srcSet={greatbritain2x + " 2x, " + greatbritain + " 1x"} alt="GREAT logo" />,
    <img className="logo-banner__item logo-banner__item--wa" src={warwick} srcSet={warwick2x + " 2x, " + warwick + " 1x"} alt="Warwick Castle logo" />,
    <img className="logo-banner__item logo-banner__item--ww" src={winterwonderland} srcSet={winterwonderland2x + " 2x, " + winterwonderland + " 1x"} alt="Hyde Park Winter Wonderland logo" />,
    <img className="blank" src={blank} alt=""/>
  ];



  const classMap = {
    "logo-banner": true,
    "logo-banner--roi" : props.country === "ROI",
    [props.className]: !!props.className,
  };

  
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 10,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    pauseOnHover: false,
    onInit: () => setSlides(props.country !== "ROI" ? deskLargeArr : deskLargeArr_ROI),
  
    responsive: [
      {
        breakpoint: 1400,
        
        settings: {
          beforeChange: (current, next) => setSlides(props.country !== "ROI" ? deskArr : deskArr_ROI),
          slidesToShow: 8,
          slidesToScroll: 8,
        }
      },
      {
        breakpoint: 1300,
        
        settings: {
          beforeChange: (current, next) => setSlides(props.country !== "ROI" ? tabArr : tabArr_ROI),
          slidesToShow: 6,
          slidesToScroll: 6,
        }
      },
      {
        breakpoint: 767,
        settings: {
          beforeChange: (current, next) => setSlides(props.country !== "ROI" ? mobArr : mobArr_ROI),
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };

 
  return (
    <div className={classList(classMap)} key={props.country}>
      <Slider
        {...settings}
        className='logo-banner__carousel'
      >
         {slides.map(slide => {
          return (
            <>
            {slide}
            </>
          );
        })}
        </Slider>
      
    </div>
  )
  
}