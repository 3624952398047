import React, { useRef, useState } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './CustomDropdownField.scss';

export default (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isOpened, setIsOpened] =  useState(false);

  const inputRef = useRef();
  const classMap = {
    "custom-dropdown": true,
  };


  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }

    if(props.defaultCountry){
      var defCountry = (props.defaultCountry !== "ROI" ? "UK" : "ROI");
      var defaultOpt = props.options.filter(function (opt) {
        return opt.id === defCountry;
      });

      if(defaultOpt !== null && defaultOpt !== undefined){
        setSelectedValue(defaultOpt[0].value);
        setSelectedImage(defaultOpt[0].image !== undefined ? defaultOpt[0].image : "");
      }
    }


  });

  function onChange(event) {
    var currVal = event.target.value;

    var curr_id = event.target.id.split("_")[1];

    var probContent = props.options.filter(function (entry) {
        return entry.id === curr_id;
    });

    setSelectedValue(currVal);
    if(probContent[0].image !== undefined && probContent[0].image !== null){
      setSelectedImage(probContent[0].image);
    }

    document.getElementsByTagName("details")[0].removeAttribute("open");

    props.onChange(currVal);
  }


 

  return (
    <details className={classList(classMap)} >
        <summary className='custom-dropdown__selected'>
          {
            selectedImage &&
            <img src={selectedImage} alt={""} />

          }
          <span>{selectedValue}</span>
          
        </summary>
        <div className='custom-dropdown__options'>
        {
            props.options.map((opt, index) => {
              return <div key={"country_"+index} className={"custom-dropdown__options__item " + (selectedValue === opt.value ? "isSelected" : "")}>
                  
                  <input 
                    className="sr-only"
                    type="radio" 
                    id={"selectedValue_"+opt.id} 
                    name="selectedValue"
                    onChange={onChange}
                    title={props.label}
                    value={opt.value}
                    tabIndex={index}
                  />
                  <label htmlFor={"selectedValue_"+opt.id}  >
                   {
                      (opt.image && opt.image !== "") &&
                      <img src={opt.image} alt={opt.imageAlt} />
                   }
                    <span htmlFor={opt.label}>{opt.value}</span>
                  </label>
              </div>
            })
        }
    </div>
    
      
    </details>
  );
}
