import ValidationErrorCollection from './ValidationErrorCollection';

// new email regex to avoid Catastrophic Backtracking
const EMAIL_REGEX = /^(?:\w+[.-])*\w+@\w+(?:[.-]\w+)*\.\w{2,18}$/;
const NUMBERS_REGEX = /^(?:[+\d].*\d|\d)$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }
  required('inputEmail', 'Please enter your email address');
  required('inputEmailConfirm', 'Please enter your email address confirmation');
  required('inputPhoneNumber', 'Please enter your mobile phone number');
  
  required('recaptcha2', 'Please confirm you are not a robot');

  required('terms', 'Please confirm you agree to our terms & conditions and Privacy Policy');
  required('legalAge', 'Please confirm you are over 18');


  if (command.inputEmail && !EMAIL_REGEX.test(command.inputEmail)) {
    messages.addError('inputEmail', 'Please enter a valid email address');
  }

  if (command.inputEmail && command.inputEmailConfirm && command.inputEmail.toLowerCase() !== command.inputEmailConfirm.toLowerCase()) {
    messages.addError('inputEmailConfirm', 'Please confirm your email address');
  }
  
  let mobileStrippedOfWhitespace = command.inputPhoneNumber.replace(/\s+/g, '');
  if (mobileStrippedOfWhitespace !== '' && (mobileStrippedOfWhitespace.split('').length < 13  || !NUMBERS_REGEX.test(mobileStrippedOfWhitespace))) {
    messages.addError('inputPhoneNumber', 'Please enter a valid mobile phone number');
    
  } 
  

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
