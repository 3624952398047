import React from 'react';

import timer from 'utils/timer';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';
import codeService from 'services/codeService';

import CodeEntryForm from 'containers/Forms/CodeEntryForm';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import AlertIcon from 'assets/images/alert.png';
import BarcodeHelpImage from 'assets/images/campaign/how-2.png';
import BarcodeHelpImage2 from 'assets/images/campaign/how-2@2x.png';

const MAX_ATTEMPTS = 3;

export default (props) => {
  let [numAttempts, setNumAttempts] = React.useState(1);
  let [status, setStatus] = React.useState('Input');
  let competitionEntryState = useCompetitionEntryContext();
  let settings = useSettingsContext();

  async function onCodeSubmit(codes) {
    setStatus('Uploading');
    let isValid = await codeService.validateCodeEntry(codes);

    if (isValid) {
      setStatus('Valid');
      await timer.delay(2000);
      competitionEntryState.setCodes(codes);
      if (props.onEntry) {
        props.onEntry();
        document.body.classList.remove("overflow-hidden-mediumdown");
      }
    } else if (numAttempts >= MAX_ATTEMPTS) {
      setNumAttempts(1);
      setStatus('TooManyAttempts');
    } else {
      setNumAttempts(numAttempts + 1);
      setStatus('NotFound');
    }
  }

  function onDismissModal () {
    props.onDismiss();
    document.body.classList.remove("overflow-hidden-mediumdown");
  }


  function renderStatus() {
    if (props.show) {
      switch (status) {
        case 'Input': return (
          <Modal 
            panel
            icon={BarcodeHelpImage}
            icon2x={BarcodeHelpImage2}
            alt="Barcode and batch code on a Cadbury product"
            roundedIcon
            borderedIcon
            onConfirm={onDismissModal}
            onDismiss={onDismissModal}
            closeAnalytics={{evnt: "button_click", category: "close_modal", location: "proof_of_purchase_modal", label: "close proof of purchase modal"}}
            >
            <CodeEntryForm 
              onSubmit={onCodeSubmit} 
            />
          </Modal>
        )
        case 'NotFound': return (
          <Modal 
            panel 
            error
            icon={AlertIcon} 
            alt="Warning sign"
            onConfirm={onDismissModal}
            title="Oops - that hasn't worked">
            <p class="modal__intro">
            Please check you’ve entered all fields correctly.
            </p>
            <Button title="Try again" onClick={() => setStatus('Input')} />
            <Link route title="See our FAQs" to="/faqs" 
            analytics={{evnt: "link_click", category: "internal_link", location: "code entry modal - not found code", label: "faqs page"}}
            onClick={onDismissModal}>See our FAQs</Link>
          </Modal>
        )
        case 'TooManyAttempts': return (
          <Modal 
            panel 
            error
            icon={AlertIcon} 
            alt="Warning sign"
            onConfirm={onDismissModal}
            title="Too many attempts">
            <p>
              Hmm, we don't recognise that code! Try again, or contact us if there's a problem. 
            </p>
            <Link lozenge route title="See our FAQs" to="/faqs" 
            analytics={{evnt: "button_click", category: "internal_link", location: "code entry modal - too many attempts", label: "faqs page"}}
            onClick={onDismissModal}>See our FAQs</Link> 

            <Link title="Contact customer services" 
            analytics={{evnt: "link_click", category: "external_link", location: "code entry modal - too many attempts", label: "external contac us email"}}
            to="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A">Contact Customer Services</Link>
          </Modal>
        )
        case 'Uploading': return (
          <Modal loading />
        )
        case 'Valid': return (
          <Modal success
          />
        )
      }
    } else {
      return null;
    }
  }

  return renderStatus();
}