import api from './api';


//import filePathHelper from 'utils/filePathHelper';

const MAX_IMAGE_SIZE = 800;
const MAX_FILE_SIZE_IN_KB = 0; // 0 indicates "always resize"
// Change DEBUG_FILE to load a response from one of the debug json files.
// null || 'logograb-fail.json' || 'logograb-success.json'
const DEBUG_FILE =  null;

/**
 * Determines if the specified file contains a valid logo
 * and returns the result.
 * @param {*} file A single file from a file input element. 
 */

async function getResult(file, country) {

    var formData = new FormData();
    var fileSizeInKb = file.size / 1024;

    formData.append('file', file);
    formData.append('country', country)

    // if (file && fileSizeInKb > MAX_FILE_SIZE_IN_KB) {
    //     // Note: png is only supported file type in spec, but others are typically supported.
    //     // Jpg is by far better at compressing images than the png encoder so let's use that.
    //     let filename = getFileNameWithoutExtension(file.name);
    //     let fileType = 'image/jpeg';//file.type;
    //     let img = await readFileIntoImgElement(file);
    //     let imageAsBase64 = resizeFileDataAsBase64(img, file, fileType);
    //     let u8Image = b64ToUint8Array(imageAsBase64);

    //     // must specifiy a file name or it could be blocked by application firewall.
    //     //formData.append('file', new Blob([u8Image], { type: fileType }), filename + '.jpg');
    //     formData.append('file', file);
    //     formData.append('country', country)
    // } else {
    //     formData.append('file', file);
    //     formData.append('country', country)
    // }

    var result = await makeApiCall(formData)
    return result;
}

function getFileNameWithoutExtension(filename) {
    return filename.substr(0, filename.lastIndexOf('.')) || filename;
}

function makeApiCall(formData) {
    if (DEBUG_FILE) {
        let path = ''; //filePathHelper.getPublicFile('/data/' + DEBUG_FILE);
        return api.get('photo-validate', path);
    } else {
        return api.post('photo-validate', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

function readFileIntoImgElement(file) {

    return new Promise(readFileResolver);

    function readFileResolver(resolve, reject) {
        if (!file) {
            resolve(null);
        }

        let reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.onerror = onError;
        reader.readAsDataURL(file);

        function onReaderLoad(e) {

            let img = document.createElement("img");
            img.onload = onImageLoad;
            img.onerror = onError;
            img.src = e.target.result;

            // ensure file is loaded in the dom element before proceeding (intermittent Edge bug)
            function onImageLoad() {
                resolve(img);
            }
        }

        function onError(err) {
            resolve(null);
        }
    }
}

function resizeFileDataAsBase64(img, file, fileType) {

    if (!img || !file) return null;

    let canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;

    if (!width || !height) {
        return null;
    }

    // Calculate scaled dimentions with the correct aspect ratio
    if (width > height) {
        width *= MAX_IMAGE_SIZE / height;
        height = MAX_IMAGE_SIZE;
    } else {
        height *= MAX_IMAGE_SIZE / width;
        width = MAX_IMAGE_SIZE;
    }

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    let dataUrl = canvas.toDataURL(fileType);

    return dataUrl;
}

function b64ToUint8Array(b64Image) {
    var img = atob(b64Image.split(',')[1]);
    var img_buffer = [];
    var i = 0;
    while (i < img.length) {
        img_buffer.push(img.charCodeAt(i));
        i++;
    }
    return new Uint8Array(img_buffer);
}

export default {
    getResult
};