import api from './api';

const GA_NAMESPACE = 'compsvr.';

function logEvent(event, category, data) {

    if (!event || !category) {
        throw new Error('analyticsService.logEvent requires an event and category parameter.');
    }

    data = Object.assign({
        location:'',
        label:'',
        url:'',
    }, data, {
        event: GA_NAMESPACE + event,
        category: category
    });

/*
    data = Object.assign({
        action:'',
        label:'',
        value:'',
    }, data, {
        event: GA_NAMESPACE + event,
        category: category
    });
*/
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);

    // console.log('GA', data.event, data.category, data);
}

function logDataLayerVariable(key, value) {
    window.dataLayer.push({ [key]: value });
}

function logPageView(pageName) {
    logEvent('pageview', 'PageView', {
        action: pageName
    });

    // Track the homepage hits so we can accurately measure 
    // GA lost traffic through ad blockers and privacy browsers 
    if (pageName === '/') {
        api.post('activities/landing');
    }

    if (pageName === '/faqs') {
        api.post('activities/faq');
    }

    if (pageName === '/registration') {
        api.post('activities/registration');
    }
}


export default {
    logEvent,
    logDataLayerVariable,
    logPageView
};