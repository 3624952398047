import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classList from 'react-classlist-helper';
import FocusTrap from 'focus-trap-react';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import Link from 'components/Link/Link';
import Logo from 'assets/images/themes/cadbury/cadbury200-logo.png';
import Logo2x from 'assets/images/themes/cadbury/cadbury200-logo@2x.png';

import LogoROI from 'assets/images/themes/cadbury/cadburyROI-logo.png';
import LogoROI2x from 'assets/images/themes/cadbury/cadburyROI-logo@2x.png';

import analyticsService from 'services/analyticsService';

import './Header.scss';

let lastScroll = 0;
let direction = 0;
let menuOpen = false;
let ticking = false;

export default function (props) {
  const settings = useSettingsContext();
  const history = useHistory();
  const [hide, setHide] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [competitionIsOpen, setCompetitionIsOpen] = useState(false);
  const [competitionIsMopUp, setCompetitionIsMopUp] = useState(false);
  const menuStateInput = useRef();

  let compState = "", compPhaseId = "";

  useEffect(() => {
    if(settings.competitionStatus !== undefined && settings.competitionStatus !== null){
      compState = settings.competitionStatus.openState;
      compPhaseId = settings.competitionStatus.currentPhasePublicId;
      setCompetitionIsOpen(compState === "Open");
      setCompetitionIsMopUp(compPhaseId === "mop-up");
    }
  }, [settings.competitionStatus]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!navOpen) {
      document.getElementsByTagName('body')[0].removeAttribute("style");
    } else {
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
    }
  }, [navOpen]);

  useEffect(() => {
    if (menuStateInput && menuStateInput.current) {
      menuStateInput.current.addEventListener("keyup", onKeyUp);
      return () => menuStateInput.current.removeEventListener("keyup", onKeyUp);
    }
  }, []);

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll();
        ticking = false;
      });
      ticking = true;
    }
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const handleScroll = async () => {
    let scroll = getScroll();
    direction = (scroll > lastScroll) ? 1 : -1;

    if (menuStateInput.current) {
      menuOpen = await getMenuState();
    }

    setHide(direction === 1 && !menuOpen && scroll > 150);
    lastScroll = scroll;
  }

  const getMenuState = async () => {
    return menuStateInput.current.checked;
  }

  const getScroll = () => {
    return window.scrollY || window.pageYOffset;
  }

  const handleClick = () => {
    menuStateInput.current.click();
  }

  const onInputChange = (e) => {
    setNavOpen(e.currentTarget.checked);

    if(e.currentTarget.checked) {
      analyticsService.logEvent("menu_click", "top_menu", {
        location: "menu",
        label: "open menu",
        url: ""
      });
    } else {
      analyticsService.logEvent("menu_click", "top_menu", {
        location: "menu",
        label: "close menu",
        url: ""
      });
    }
  }


  const classMap = {
    "header": true,
    "header--hide": hide,
    "header--navopen": navOpen,
    [props.className]: !!props.className,
  };

	return (
		<header className={classList(classMap)}>
       
			{
        (props.is500 !== undefined && props.is500) ? (
          <Link className="header__logo" 
          analytics={{ evnt: "image_click_logo", category: "logo", location: "site_header", label: "" }}
          to="/"
          >
            {
              (settings.country === "ROI") ? ( 
                <img src={LogoROI} srcSet={LogoROI2x + " 2x, " + LogoROI + " 1x"} className="header__svg" alt="Cadbury yours always" />
              ) : (
                <img src={Logo} srcSet={Logo2x + " 2x, " + Logo + " 1x"} className="header__svg" alt="Cadbury yours for 200 years" />
              )
            }
            
          </Link>
        ) : (
          <Link route className="header__logo" 
          analytics={{ evnt: "image_click_logo", category: "logo", location: "site_header", label: "" }}
          to="/">
            {
              (settings.country === "ROI") ? ( 
                <img src={LogoROI} srcSet={LogoROI2x + " 2x, " + LogoROI + " 1x"} className="header__svg" alt="Cadbury yours always" />
              ) : (
                <img src={Logo} srcSet={Logo2x + " 2x, " + Logo + " 1x"} className="header__svg" alt="Cadbury yours for 200 years" />
              )
            }
          </Link>
        )
      }
      
      { props.enableMenu && 
       
          <FocusTrap active={navOpen}>
             <nav className="header__nav">
            <div className="header__menuToggle">
              <input type="checkbox" ref={menuStateInput} onChange={onInputChange} />
              <span className='header__menuToggle__focus'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <div className="header__menu">
                <ul className="header__menu__nav">
                  <li><Link 
                      analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "home page"}}
                      to="/"
                      route
                      title="Home"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        Home
                  </Link></li>

                 {
                  (competitionIsOpen && !competitionIsMopUp) &&
                  <li><Link 
                      analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "prize details page"}}
                      to="/prize-details"
                      route
                      title="Prize Details"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        Prize Details
                  </Link></li>
                 }

                  <li><Link 
                      analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "frequently asked questions page"}}
                      to="/faqs"
                      route
                      title="Frequently Asked Questions"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        FAQ<span className="lowercase">s</span>
                  </Link></li>

                  <li><Link 
                      analytics={{evnt: "menu_click", category: "top_menu", location: "menu", label: "terms and conditions page"}}
                      to="/terms-and-conditions"
                      route
                      title="Terms and Conditions"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        T&amp;C<span className="lowercase">s</span>
                  </Link></li>

                </ul>
                <div className="header__menu__footer">
                  {
                    settings.country !== "ROI" ? (
                      <Link 
                        analytics={{evnt: "link_click", category: "top_menu", location: "menu", label: "cadbury.co.uk"}}
                        cancelFocus={!navOpen} className="type-hpara" 
                        href="https://cadbury.co.uk/ " onClick={handleClick}
                        target="_blank" >
                          cadbury.co.uk
                      </Link>
                    ) : (
                      <Link 
                        analytics={{evnt: "link_click", category: "top_menu", location: "menu", label: "cadbury.ie"}}
                        cancelFocus={!navOpen} className="type-hpara" 
                        href="https://www.cadbury.ie/ " onClick={handleClick}
                        target="_blank" >
                          cadbury.ie
                      </Link>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="header__overlay" onClick={handleClick}></div>
            </nav>
          </FocusTrap>
        
      }
		</header>
	);
}