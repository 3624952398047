import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import CountrySelectForm from 'containers/Forms/CountrySelectForm';

export default (props) => {
  const onCountrySelected = (country) => {
    props.onCountrySelected(country);
    document.body.classList.remove("overflow-hidden-mediumdown");
  }



  if (props.show) {
    return (
      <Modal panel isCountryModal className="modal--country" 
          focusTrapActive={(props.focusTrapActive !== null && props.focusTrapActive !== undefined) ? props.focusTrapActive : true} >
        <CountrySelectForm 
          onSubmit={onCountrySelected}
          focus={props.show}
        />
      </Modal>
    )
  } else {
    return null;
  }
}