import React, {useState, useEffect} from 'react';
import classList from 'react-classlist-helper';

import './Prize.scss';

import {ReactComponent as PrizeSelSport} from 'assets/images/campaign/prizeSelection/football.svg';
import {ReactComponent as PrizeSelTravel} from 'assets/images/campaign/prizeSelection/tickets.svg';
import {ReactComponent as PrizeSelAdventure} from 'assets/images/campaign/prizeSelection/rollercoaster.svg';
import {ReactComponent as PrizeSelFestivals} from 'assets/images/campaign/prizeSelection/flag.svg';
import {ReactComponent as PrizeSelEntretaiment} from 'assets/images/campaign/prizeSelection/popcorns.svg';


const Prize = (props) => {
  const [currClass, setCurrClass] = useState("");
  
  const classMap = {
    "prize": true,
    "prize--selectable": props.selectable,
  };

  const imageClassMap = {
    "prize__image": true,
    "prize__image--contain": props.containImage,
  };

  
  useEffect(() => {
    if(props.selected === true){
      setCurrClass("checked");
    } else {
      setCurrClass("");
    }
    
  }, [props.selected]);

  const onChange = (event, id) => {
    if (props.onChange) {
      props.onChange(props.prize);
    }
  }

  return (
    <div className={classList(classMap) + " prize--"+ props.prize.id + " " + currClass} key={"prize_" + props.prize.id}>
      { props.model &&
        <input 
          className="prize__radio" 
          type="radio" 
          id={"prize_" + props.prize.id} 
          name={props.model} 
          value={props.prize.id} 
          checked={props.selected} 
          onChange={(e) => onChange(e, props.prize.id)} 
          
        />
      }
      <div className='prize__border'></div>
      <div htmlFor={"prize_" + props.prize.id}  className={classList(imageClassMap)}  >
          {/*<div className='prize__image__circle'></div>
          <picture>    
            <source media="(min-width: 769px)" srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} type="image/png" />
            <img src={props.prize.image} srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} alt={props.prize.title} />  
      </picture> */}

      {
        {
          'sport': <PrizeSelSport />,
          'travel': <PrizeSelTravel />,
          'adventure': <PrizeSelAdventure />,
          'festivals': <PrizeSelFestivals />,
          'entertainment' : <PrizeSelEntretaiment />
        }[props.prize.id]
      }
        
      </div>
      <h3 className="prize__title"><span>{props.prize.title}</span></h3>
      {
        (props.prize.description !== null && props.prize.description !== "") ??
        <div className="prize__info" dangerouslySetInnerHTML={{__html: props.prize.description}}></div>
      }
      
    </div>
  )
}

export default Prize;
