import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import SettingsContext from 'contexts/SettingsContext';
import Home from './Home/Home';
import RegistrationRouteGuard from './Forms/RegistrationRouteGuard';
import ClaimRouteGuard from './Forms/ClaimRouteGuard';
import TermsAndConditionsRouteGuard from './Policies/TermsAndConditionsRouteGuard';
import CookiePolicy from './Policies/Cookies';
import Thankyou from './Thankyou/Thankyou';
import FourOhFour from './Errors/FourOhFour';
import PrizeSelectionRouteGuard from './PrizeSelection/PrizeSelectionRouteGuard';
import FAQsRouteGuard from './FAQs/FAQsRouteGuard';
import PrizeDetails from './PrizeDetails/PrizeDetails';


export default (props) => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopUp = compPhaseId === "mop-up";

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route 
        exact 
        key="home"
        path="/">
        <Home />
      </Route>
      <Route 
        exact 
        key="prize-select"
        path="/prize-select">
        <PrizeSelectionRouteGuard />
      </Route>
       <Route 
        exact 
        key="registration"
        path="/registration">
        <RegistrationRouteGuard />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou/:entryPublicId">
        <Thankyou />
      </Route>
       {/*<Route 
        exact 
        key="thankyou"
        path="/thankyou">
        <Thankyou />
  </Route> */}
      <Route 
        exact 
        key="faqs"
        path="/faqs">
      <FAQsRouteGuard />
      </Route>
      <Route 
        exact 
        key="terms-and-conditions"
        path="/terms-and-conditions">
        <TermsAndConditionsRouteGuard />
      </Route>
      <Redirect exact from="/cookies" to="/cookie-policy" />
      <Route 
        exact 
        key="cookie-policy"
        path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route 
        exact 
        key="contact-us"
        path="/contact-us">
        <Home />
      </Route>
      {
        (competitionIsOpen && !competitionIsMopUp) &&
        <Route 
          exact 
          key="prize-details"
          path="/prize-details">
          <PrizeDetails />
        </Route>
      }
     <Route 
        exact 
        key="claim"
        path="/claim/:token">
        <ClaimRouteGuard />
      </Route>
    {/* <Route 
        exact 
        key="claim"
        path="/claim">
        <ClaimRouteGuard />
      </Route>*/}
      <Route>
        <FourOhFour />
      </Route>
    </AnimatedSwitch>
  );
}
