import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Link from 'components/Link/Link';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';
import FadeIn from 'components/FadeIn/FadeIn';

import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';

//winner voucher 200
import WinnerVoucher200Mob from 'assets/images/campaign/prizes/thankyou-voucher-mob.png'
import WinnerVoucher200Mob_2x from 'assets/images/campaign/prizes/thankyou-voucher-mob@2x.png'
import WinnerVoucher200 from 'assets/images/campaign/prizes/thankyou-voucher-desk.png'
import WinnerVoucher200_2x from 'assets/images/campaign/prizes/thankyou-voucher-desk@2x.png'


import './Thankyou.scss';


export default () => {
  let { entryPublicId } = useParams();
  let prizePublicId = "voucher-200";

  let [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);
  //let [entry, setEntry] = useState("");

  let [showNps, setShowNps] = useState(null);

  let [currency, setcurrency] = useState('');
  let [prizeText, setPrizeText] = useState('');

  useEffect(() => {
    switch (settings.country) {
      case "ROI":
        setcurrency("€");
        setPrizeText('AllGifts.IE voucher!');
        break;
      case "GB":
      case "NI":
      case "UK":
        setcurrency("£");
        setPrizeText('Lifestyle voucher!');
        break;
      default:
        setcurrency("£/€");
        setPrizeText('voucher!');
        break;
    }
  }, [settings.country]);



  useEffect(() => {
    async function fetchData() {
      await competitionEntryService.getCompetitionEntry(entryPublicId)
      .then((result) => {       
        if (result) {       
          setEntry(result);
          if(result.nps !== null) {              
            setShowNps(false);
          } else {              
            timer.delay(2500).then(() => setShowNps(true));
          }
          
          var email = competitionEntryContext.email;
          //Had to go here, as not triggered on last page
          if (email != null) {
            // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)

            analyticsService.logEvent("signup", "entry_registration", {
              location: "thankyou_page",
              label: "email capture",
              url: email
            });

            analyticsService.logEvent("signup", "entry_registration", {
              location: "thankyou_page",
              label: "registration success"
            });

            if (result.hasWon) {
              analyticsService.logEvent('signup', 'entry_registration', {
                location: "thankyou_page",
                label: "prize",
                url: result.prizePublicId
              });
            }
          }
        }
      })
      .catch(err => console.log(err) );
    }

    if (entryPublicId && !entry) {
      fetchData();
    }

    
 
  }, [entry]);

 
  if (competitionIsOpen && ((entry !== null && !entry.entryPublicId) || !entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }


  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  const renderWinner = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Congratulations! | Cadbury - Win a Day to Remember</title>
        </Helmet>

        <section  className="layout-general layout-wrapper layout-wrapper--bottom-ellipse thankyou thankyou--keyvis">
          <div className='thankyou__keyvis'>
            <FadeIn className='thankyou__container'>
             <picture>
                <source media="(min-width: 640px)" srcSet={WinnerVoucher200_2x + " 2x, " + WinnerVoucher200 + " 1x"}  />
                <img className="thankyou__img fade-in fade-in--visible" src={WinnerVoucher200Mob} srcSet={WinnerVoucher200Mob_2x + " 2x, " + WinnerVoucher200Mob + " 1x"} alt="£/€10 voucher" />
             </picture>
            </FadeIn>
          </div>
        </section>
        <section className="layout-wrapper">
          <div className="layout-container layout-container--center layout-container--slim">

            <div className='thankyou__container'>
              <h2 className="type-h2">Congratulations</h2>
              
              <div className="thankyou__para">
                <p>You've won <b>{entry.prizePublicId !== "VISB" ? entry.prizeTitle : "a £15 discount voucher off a Visit Britain shop experience, supplied by partner Golden Tours!"}</b></p>
                <p>
                  To claim your reward, you need to validate your entry. You'll be sent a winning confirmation email to the email address you registered with. <strong>You have 7 days from the date the email was sent to claim your voucher reward.</strong> If you don't do it in this time, you will forfeit this prize, so check your junk folder! 
                </p>
                <p>
                You have also been entered into the weekly draw to win one of our epic <strong>DAYS TO REMEMBER</strong> level 1 prizes, from your chosen category. So fingers crossed for the second <strong>WIN!</strong> Plus if this is your first time entering, you will have also received your <strong>Jet2holidays</strong> discount voucher via email. 
                </p>
                <p>
                Please note some voucher rewards may require additional consumer contribution or postage and packaging fees may apply upon voucher redemption.
                </p>
                <p>To find out more about our Cadbury products and other promotions, please click below.</p>
              </div>
              <div className='thankyou__cta'>
              {
                settings.country !== "ROI" ? (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page winner", label: "cadbury.co.uk"}}
                  to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
              
                ) : (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page winner", label: "cadbury.ie"}}
                  to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
                )
              }
              </div>
            </div>
          </div>

          {renderNpsBanner(entryPublicId)}
        </section>
      </>
    );

  }

  const renderLoser = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry | Cadbury - Win a Day to Remember</title>
        </Helmet>

        <section className="layout-general layout-general--full  layout-wrapper">
          <div className="layout-container layout-container--center ">
            <h1 className="type-h2">You're not a winner…yet.</h1>
            <div className="thankyou__para">
              <p>You haven't won one of our level 2 rewards <strong>BUT</strong> you've still been entered into the next weekly draw to <strong>WIN A DAY TO REMEMBER!</strong> Plus if this is your first time entering, you will have also received your <strong>Jet2holidays</strong> discount voucher via email.</p>
              <p>So all to play for.</p>
              <p>Fingers crossed you bag the big one.</p>
              <p>And remember, you can enter again tomorrow, until the daily max entry limit has been reached. Good luck!</p>
              <p>To find out more about our Cadbury products and other promotions, please click below.</p>
            </div>
            <div className='thankyou__cta'>
              {
                settings.country !== "ROI" ? (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page loser", label: "cadbury.co.uk"}}
                  to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
              
                ) : (
                  <Link 
                  analytics={{evnt: "button_click", category: "main_cadbury_site", location: "thank you page loser", label: "cadbury.ie"}}
                  to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
                )
              }
              </div>
          </div>

          {renderNpsBanner(entryPublicId)}
        </section>
      </>
    );
  }

  const renderMopUp = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Thank you | Cadbury - Win a Day to Remember</title>
        </Helmet>

        <section className="layout-general layout-wrapper layout-general--full ">
          <div className="layout-container layout-container--center">
            <h1 className="type-h2">Thank you for entering</h1>
            <p className="thankyou__para">
              We'll be in touch if you're a lucky winner.
            </p>
            <div className='thankyou__cta'>
              <Link title="Back to homepage" 
              analytics={{evnt: "button_click", category: "internal_link", location: "thank you page - mop up phase", label: "home page"}}
              lozenge route to="/">Back to home</Link>
            </div>
          </div>
        </section>
      </>
    )
  }


  return (
    <>
    {
      (entryPublicId && !entry) ? (
        <>
          <section className="layout-general layout-general--full layout-wrapper">
            <div className="layout-container layout-container--center ">
              <h1 className="type-h2">Loading!</h1>
              <div className='thankyou__cta'>
                <Link title="Back to homepage" 
                analytics={{evnt: "button_click", category: "internal_link", location: "thank you page - loading", label: "home page"}}
                lozenge route to="/">Back to home</Link>
              </div>
            </div>
          </section>
        </>
      ) : (
        competitionIsMopup ? (
          renderMopUp()
        ) : (
        entry.hasWon ? (
          renderWinner()
          ) : (
          renderLoser()
          )
        )
      )
    }
    </>

  );
}
