import React, { useState } from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import codesValidator from 'validators/codesValidator';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ValidationMessage from 'components/Validation/ValidationMessage';
import analyticsService from 'services/analyticsService';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Form, { createOnChangeHandler } from './Form';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import "./CodeEntryForm.scss";

export default (props) => {
  let settingsContext = useSettingsContext();
  const [barcode, setBarcode] = useState(null);
  const [batchcode, setBatchcode] = useState(null);
  const [productType, setProductType] = useState(null);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const createCommand = () => {
    return {
      barcode,
      batchcode,
      productType
    }
  }

  const onFormSuccess = () => {
    analyticsService.logEvent("button_click", "code_entry_sumbit", {
      location: "proof_of_purchase_modal",
      label: "codes success"
    });
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("button_click", "code_entry_sumbit", {
      location: "proof_of_purchase_modal",
      label: "codes fail",
      url: error
    });
  }

  const service = (command) => {
    props.onSubmit(command);
  }

  return (
    <Form 
      id="codes"
      formClass="code-entry"
      autoComplete="off"
      submitTitle="Enter now"
      onSuccess={onFormSuccess}
      onFail={onFormFail}
      command={{
        model: createCommand,
        service: service
      }}
      validation={{
        validator: codesValidator,
        onValidation: setValidation
      }}
      submitAnalytics={{evnt: "button_click", category: "code_entry_submit", location: "proof_of_purchase_modal", label: "submit code entry form"}}
    > 
      <h2 className='type-h3 type-h3--white'>Enter your barcode & batch code here</h2>
      <div className="code-entry__copy">
        <p>Hint: They’re found on the back of pack.</p>
      </div>
      <div className="code-entry__inputs">
          <div className="code-entry__codes">
            <fieldset className="form__fieldset">
              <TextFieldCharRestricted 
                focus
                id="barcode" 
                placeholder="e.g 7622300743659" 
                label="* Barcode"
                type="number"
                maxCharLenght ={20}
                onChange={createOnChangeHandler(validation, 'barcode', setBarcode)}
                hasErrors={!!validation.errors.barcode}
              />
              <ValidationMessage errors={validation.errors.barcode} />  
            </fieldset>
            
            <fieldset className="form__fieldset">
              <TextFieldCharRestricted
                id="batchcode" 
                placeholder="e.g 0B00B62021" 
                label="* Batch code"
                maxCharLenght ={19}
                onChange={createOnChangeHandler(validation, 'batchcode', setBatchcode)}
                hasErrors={!!validation.errors.batchcode}
              />
              <ValidationMessage errors={validation.errors.batchcode} />
            </fieldset>
            
          </div>    
      </div>


      <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
    </Form>
  )
}