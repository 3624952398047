import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Cadbury - Win a Day to Remember</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
       
        <h1 className="type-h1 type-h1--xl">FAQ<span className="lowercase">s</span></h1>

      
       {  <div className="faqs">
          <div className="faqs__section">
      
          
            <h2 className="type-h3">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1.	What is the Cadbury 'Win a Day to Remember' promotion? ",
                answer: `
                <p>
                Win a Day to Remember marks our 200<sup>th</sup> celebratory Birthday and with more generosity than ever we have over 200,000 unforgettable prizes and rewards to be won. It is an on-pack promotion that gives you a chance to win amazing days to remember from match days to Cadbury chocolate making experiences. There are also thousands of voucher reward codes up for grabs, some awarding entry tickets to a Merlin attraction, a SKY movie voucher or a discount voucher from a selected partner website - Good Luck!                   </p>`
                },{
                question: "2.	What is the 'Win a Day to Remember' Final Draw?",
                answer: `
                <p>
                If you purchase a promotional product during the Final Draw period 	(10:00 4<sup>th</sup> November 2024 to 09:59 6<sup>th</sup> May 2025), head to the 	website 	and follow the on-screen instructions, you'll be entered into our Final 	prize draw and could be in for a chance to win £/€250. For further information please see the T&Cs page <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
                <p>
                Entrants in ROI do not need a promotional product barcode to enter, in line with the NPN process.
                </p>
              `},{
                question: "3. Who is the promotion open to? ",
                answer: `
                <p>
                The Win a Day to Remember promotion is open to anyone who lives in the UK or ROI excluding the Channel Islands and the Isle of Man and is over the age of 18.                  </p>
              `},{
                question: "4.	Where can I purchase a 'Win a Day to Remember' promotional pack?",
                answer: `
                <p>
                You can purchase a Win a Day to Remember participating product in supermarkets and newsagents nationwide from July 2024. The Win a Day to Remember promotion opens for entry at 10:00 on 5<sup>th</sup> August 2024.                </p>
              `},{
                question: "5.	Which Cadbury products are included in the promotion? ",
                answer: `
                <p>
                You'll find the Win a Day to Remember logo on many Cadbury tablets and bars. 
                </p>
                <p>
                A wide range of Cadbury chocolate, Cadbury biscuits, Maynards Bassetts sweets and Trebor mints are included in the promotion. For a full list of participating products please visit the T&Cs page <a href="/terms-and-conditions#products-list" target="_blank" rel="noopener noreferrer">here</a> and go to Appendix 1.
                </p>
              `},{
                question: "6.	When can I enter? ",
                answer: `
                <p>
                The main promotion period is open from 10:00 on 5<sup>th</sup> August 2024 and closes at 09:59 on 4<sup>th</sup> November 2024. All entries received between 10:00 on 4<sup>th</sup> November 2024 and 09:59 on 6<sup>th</sup> May 2025 will be automatically entered into a final prize draw to win £250/€ equivalent.                </p>
              `},{
                question: "7. How can I enter? ",
                answer: `
                <p>
                To enter from the UK, simply buy any Promotional Product, head to  <a href="https://winaday.cadbury.co.uk/" target="_blank" rel="noopener noreferrer">winaday.cadbury.co.uk</a>, select your region, enter barcode & batch code found on pack. Enter your details, including your mobile number, then you will receive a one-time passcode to verify entry via SMS.                 </p>
                </p>
                <p>
                Select which of our epic level 1 prize categories you'd like the chance to win a prize from and enter your details. If your entry is valid, you will find out immediately on screen if you have won a Level 2 voucher reward. Level 2 voucher rewards are allocated randomly and are not linked to the Level 1 prize draw category selected.                 
                </p>
                <p>
                On your first valid entry only, you will receive a Jet2holidays £75 discount voucher (UK flights and departures only, further terms and exclusions apply).                </p>
                <p>
                The barcode and batch code can be found on the back of your participating Promotional Product. If you'd like to review the full terms and conditions and product details, please click <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p>
                To enter from the Republic of Ireland, simply head to <a href="https://winaday.cadbury.ie/" target="_blank" rel="noopener noreferrer">winaday.cadbury.ie</a> and select your region. Enter your details, including your mobile number, then you will receive a one-time passcode to verify entry via SMS. Select which of our epic level 1 prize categories you'd like the chance to win a prize from and enter your details. If your entry is valid, you will find out immediately on screen if you have won a Level 2 voucher reward. On your first valid entry only, you will receive a Jet2holidays £75 discount voucher (UK flights and departures only, further terms and exclusions apply).                </p>
              `},{
                question: "8.	Do I need internet access?",
                answer: `
                <p>
                You will need internet access to enter the promotion and claim your prize or voucher reward code if you are a winner. You also need a valid email address and mobile phone to enter the promotion.                </p>
              `},{
                question: "9.	Why do I need a mobile phone number to enter the promotion",
                answer: `
                <p>
                To verify your entry into the promotion we'll send you a One-Time Passcode (OTP) to your mobile phone. You will need to submit this code at entry stage to find out instantly if you have won a Level 2 voucher reward and also be placed into the Level 1 weekly prize draw for the prize category you selected.                `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h3">Prizes / Voucher Reward Promotional Questions</h2>
            {
              renderFaqs(2, [{
                question: "10.	How do I claim my prize / VOUCHER REWARD?",
                answer: `
                <p>
                If you have won a Level 1 prize, you will need to click on the secure link in the email within 7 days. We will verify your proof of identity or we will let you know by email if there has been a problem with your claim and how you can rectify it if possible. Once your claim has been verified you will be contacted by the Promoter's agency, MKTG, within a further 14 days who will organise your prize.                 
                </p>
                <p>
                If you have won a Level 2 voucher reward, then you will receive an 8-digit one time passcode to the email address you registered upon entry along with a link to claim.                 
                </p>
                <p>
                You will need to click on the secure claim link and enter the 8-digit code featured in your email along with your email address, which must match the email address you registered upon entry.                 
                </p>
                <p>
                You will need to claim your Level 2 voucher reward within 7 days from the date you received the email featuring the secure link to claim.                 
                </p>
                <p>
                Once the claim form has been completed and verified you will receive your voucher reward code by email within 7 days.                 
                </p>
                <p>
                There is no need to claim your £75 Jet2holidays discount voucher. This will automatically be sent to the email address that you registered with upon making a valid entry.                 
                </p>
              `},{
                question: "11.	What prizes and rewards are available in the 'Win a Day to Remember' promotion?",
                answer: `
                <p>
                There are some incredible prizes and rewards up for grabs.                
                </p>
                <p>
                <strong>Level 1</strong> - There are over 5,000 prizes up for grabs. The prizes will be distributed across 13 weekly prize draws. There are 5 prize draw categories to choose from: 
                </p>
                <ul>
                <li>Adventure</li>
                <li>Entertainment</li>
                <li>Festivals</li>
                <li>Sport</li>
                <li>Travel</li>
                </ul>
                <p>
                See our entry page to give you a taster of what's on offer. <br> <a href="https://winaday.cadbury.co.uk/" target="_blank" rel="noopener noreferrer">winaday.cadbury.co.uk</a> 
                </p>
                <p>
                Please note in week 6 (10:00 09/09/2024 - 09:59 16/09/2024) and week 8 (10:00 23/09/2024 - 09:59 30/09/2024) of the promotion there will be no Travel prize draw. Entries received into the Travel category between these dates will be rolled into the following weeks' Travel prize draw.
                </p>
                <p>
                <strong>Level 2</strong> - We have 133,250 voucher reward codes which will be allocated randomly and awarded via open winning moments throughout the promotional period. Voucher codes awarded are not linked to the Level 1 prize draw category that you selected. 
                </p>
                <p>
                Vouchers up for grabs are:
                </p>
                <p>
                2,250 x F1 Arcade racing tickets for games at F1 Arcade. Each reward consists of two codes which allows 2 people to drive at the same time. Reward does not include food, refreshments, travel or accommodation.
                </p>
                <p>
                50,000 x Merlin voucher codes. Reward includes 2 x free admission tickets to a Merlin attraction for 2 adults or 1 adult and 1 child. Vouchers are split as follows: 
                </p>
                <ul>
                <li>7,500 pairs of tickets to Alton Towers Resort</li>
                <li>875 pairs of tickets to Cadbury World</li>
                <li>7,500 pairs of tickets to Chessington World of Adventures</li>
                <li>875 pairs of tickets to Legoland Discovery Centre</li>
                <li>7,500 pairs of tickets to Legoland Windsor Resort</li>
                <li>3,500 pairs of tickets to London Eye</li>
                <li>2,625 pairs of tickets to Madame Tussauds</li>
                <li>5,000 pairs of tickets to Sea Life </li>
                <li>500 pairs of tickets to Shrek's Adventure! London</li>
                <li>2,500 pairs of tickets to The Dungeons</li>
                <li>7,500 pairs of tickets to Thorpe Park</li>
                <li>4,125 pairs of tickets to Warwick Castle</li>
                </ul>
                <p>
                10,000 x free ODEON cinema tickets when another is purchased at full price.
                </p>
                <p>
                50,000 x Sky Movie vouchers worth £/€4.99. 
                </p>
                <p>
                1,000 x 50% off box hire at TOCA Social London or Birmingham venues. 
                </p>
                <p>
                5,000 x 20% off selected Virgin Experience Days. 
                </p>
                <p>
                15,000 x £15 discount voucher off a VisitBritain shop experience, supplied by partner Golden Tours. 
                </p>
                <p>
                Reward vouchers must be redeemed online on the relevant partner redemption website that is featured in your winning email. 
                </p>
                <p>
                For full information on the prizes and rewards and their terms and conditions, you can visit the <a href="/prize-details" target="_blank" rel="noopener noreferrer">prizes page</a> & <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">T&Cs</a>
                </p>
              `},{
                question: "12.	How do I know if I'm a winner?",
                answer: `
                <p>
                If you have provisionally won a Level 2 voucher reward you will be notified instantly on screen upon completion of the online entry form.                 
                </p>
                <p>
                You'll then be contacted via email with a one-time passcode to claim your voucher reward, so keep an eye out and don't forget to check your junk folder! Upon claim verification, your voucher reward will be sent to you via email within 7 days.
                </p>
                <p>
                If you are a provisional Level 1 winner, we'll contact you via email within 7 days of the relevant weekly Prize Draw. You will be required to complete your full name, address details and upload proof of identity in the form of a driving licence, passport or utility bill. Your details submitted upon entry, claim and your proof of identity must match for your win to be verified and your prize to be fulfilled. If you do not upload proof of identity that matches you will be asked to resubmit your proof of identity to match your entry and claim details. If you cannot requalify your claim within 48 hours, then you will forfeit your prize. 
                </p>
                <p>
                Once your claim has been verified, you will receive a further congratulatory email after verification and within 7 days of the prize draw claim. You will then be contacted by the Promoter's agency, MKTG, within a further 14 days to organise your prize. All Level 1 prizes are subject to individual <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.
                </p>
              `}
              ,{
                question: "13.	I've only just seen my winning email. Can I still claim my prize / voucher reward?",
                answer: `
                <p>
                Claim links are valid for a maximum of 7 days only from the date your winning email was sent. If 7 days has passed, then the claim will have expired and unfortunately your prize or voucher reward will have been forfeited.                 
                </p>
              `}
              ,{
                question: "14.	I won a Level 2 voucher reward instantly on screen, but I didn't receive an email?",
                answer: `
                <p>
                Please check your spam/junk folder, if you still can't locate your email contact our customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>          
                </p>
              `}
              ,{
                question: "15.	Can I change my Level 1 prize category?",
                answer: `
                <p>
                No, unfortunately once you have selected your prize draw category you cannot change your selection. For each new valid entry, you can choose a different Level 1 prize draw category if you wish.                
                </p>
              `}
              ,{
                question: "16.	What if the event that I've won tickets to has been postponed? Will my ticket still be valid?",
                answer: `
                <p>
                If the event that you have selected to attend is postponed, the Promoter's agency, MKTG, will communicate this via email. Please note that the Promoter is not responsible for any force majeure event which would postpone or cancel your event tickets, however we will use reasonable endeavours to notify you of any postponement/ cancellation.                </p>
              `}
              ,{
                question: "17.	Can I change my Level 2 reward?",
                answer: `
                <p>
                No, Level 2 voucher rewards are randomly distributed via instant winning moments. Unfortunately, you cannot change the voucher reward you have been awarded.                
                </p>
              `}
              ,{
                question: "18.	My eVoucher is not working?",
                answer: `
                <p>
                Please check your voucher code carefully and try re-entering it on the correct voucher partner site.                 
                </p>
                <p>
                Please ensure that you are using your voucher reward code from your winning email and not the 8 digit one time passcode from your claim email.                 
                </p>
                <p>
                If there is still an issue with your voucher code working at any of the partner redemption sites, in the first instance please contact our customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>                
                <p>      
                You will also need to provide them with the name of the partner that you won a reward with i.e. Alton Towers and what error message you are seeing when you are trying to enter the code.         
                </p>
              `},{
                question: "19.	Is there a limit on the number of prizes and rewards I can win? ",
                answer: `
                <p>
                Yes, prizes are limited to a maximum of 1 Level 1 prize per person, 20 Level 2 rewards per person and 1 Level 3 Jet2holidays £75 discount voucher per person. Unfortunately, if you exceed your maximum prize limit, we will not be able to award your prize/reward.                </p>
              `},{
                question: "20.	I haven't received my prize or voucher reward, where is it?",
                answer: `
                <p>
                Level 1 winners will receive an email within 14 days after their prize claim has been verified from the Promoter's agency, MKTG.
                </p>
                <p>
                Level 2 winners, providing their claim has been verified, will receive their voucher reward code by email within 7 days.
                </p>
                <p>
                Level 3 Jet2holidays £75 discount vouchers will be sent via email after the participant's first valid entry. 
                </p>
                <p>
                Please ensure you check your junk / spam folders for your claim and win emails. 
                </p>
                <p>
                If the timeframe above has passed and you have not received your claim email or your prize/reward email, please contact our customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a> stating which email you are missing and we will look into your query for you. 
                </p>
              `},{
                question: "21.	How long will my prize / voucher reward take to arrive?",
                answer: `
                <p>
                Level 1 prizes are dependent on the prize you have won and the timeframe for receipt of your prize will be detailed in the email awarding your prize.                 
                </p>
                <p>
                Level 2 voucher rewards will be sent via email within 7 days of your claim being verified. 
                </p>
                <p>
                Level 3 £75 Jet2holiday discount vouchers will be sent via email directly after a participant has made their first entry. 
                </p>
              `},{
                question: "22. Can I swap or transfer my prize / reward?",
                answer: `
                <p>
                Level 1 - Unfortunately, you cannot swap your prize. However, it is possible to transfer to a friend or family member at the Promoter's and the Promoter's agency, MKTG's, discretion. This must be done after the point of a valid claim by the winner. The winner must contact the Promoter's agency, MKTG, at <a href="mailto:cadburywinaday@mktg.com" target="_blank" rel="noopener noreferrer">cadburywinaday@mktg.com</a> with the reason they would like to request the transfer. Please note that experiences or tickets must not be resold.                  
                </p>
                <p>
                Level 2 & 3 - Unfortunately, you cannot swap your reward and reward vouchers must not be transferred or resold. 
                </p>
              `},{
                question: "23. Is there a deadline for me to claim my prize / voucher reward?",
                answer: `
                <p>
                Please find the deadlines for each tier of prizes and rewards below:                 
                </p>
                <p>
                <strong>Level 1</strong> - You will have 7 days to claim your Level 1 prize from the date that your winning email was sent. You will need to provide Proof of Identity in the form of a valid driving licence, passport or utility bill to claim your prize. 
                </p>
                <p>
                Your details submitted upon entry, claim and your proof of identity must match for your win to be verified and your prize to be fulfilled. If you do not upload proof of identity that matches you will be asked to resubmit your proof of identity to match your entry and claim details. If you cannot requalify your claim within 48 hours, then you will forfeit your prize. 
                </p>
                <p>
                Verified Level 1 winners will receive a further congratulatory email after verification. Winners will then be contacted by the Promoter's agency, MKTG, within a further 14 days to organise their Level 1 prize. 
                </p>
                <p>
                <strong>Level 2</strong> - You will have 7 days to claim your Level 2 voucher reward from the date that you received your email featuring your secure claim link. You will need to claim by clicking on the secure claim link in the email that you receive after you have won. You will need to enter the 8-digit code featured in the email along with confirming your email address that you registered upon entry.
                </p>
                <p>
                <strong>Level 3</strong> - You will be sent your Level 3 £75 Jet2holidays discount voucher directly after your first valid entry. To redeem your voucher, you must follow the instructions in your email. Jet2holidays and voucher specific T&Cs apply. 
                </p>
                <p>
                If you have not received your claim email, please ensure you check your junk / spam folders for your claim and win emails. 
                </p>
                <p>
                If the timeframe above has passed and you have not received your claim email, please contact our customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a> 
                </p>
              `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h3">Entry Mechanic Questions</h2>
            {
              renderFaqs(3, [{
                question: "24.	I entered the wrong email address or mobile number. What do I do?",
                answer: `
                <p>
                Please take care when entering your email address and mobile number as each need to be unique to an entry for it to be valid. Once your mobile number and email have been used to enter the promotion they cannot be used, changed or unlinked from each other.                 
                </p>
                <p>
                It's the entrant's responsibility to ensure they have entered their email address and mobile number correctly. 
                </p>
                <p>
                Any one time passcodes, prizes or rewards sent to an incorrect mobile number or email address due to the details being entered incorrectly will not be able to be resent and will become invalid. 
                </p>
              `},{
                question: "25.	What is a One Time Passcode?",
                answer: `
                <p>
                We have two types of One Time Passcode in the Cadbury Win a Day to Remember promotion.                 
                </p>
                <p>
                The first code you receive will be a One Time Passcode entry verification code. This is a 6-digit code and will be sent to the mobile phone number that you registered upon entry. Entry One Time Passcodes are valid for 10 minutes and you have 3 attempts only to enter your code to verify your entry. 
                </p>
                <p>
                If your code is not being accepted check the following: 
                </p>
                <ul>
                <li>
                Check you have entered the correct 6- digit numeric code sent to your mobile number 
                </li>
                <li>
                Check your mobile number is correct and that your phone can receive messages
                </li>
                <li>
                You may have entered an incorrect code too many times or maybe you didn't receive your code?
                </li>
                <li>
                You can click a button on the entry screen after 3 minutes to resend your code
                </li>
                </ul>
                <p>
                If you are still experiencing problems please contact our consumer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>
                </p>
                <p>
                You will only receive a second One Time Passcode if you are a lucky winner of a Level 2 voucher reward. This One Time Passcode is 8 digits long and will have been sent to the email address you registered upon entry. You will need to enter this code along with the email address you registered upon entry to claim your Level 2 voucher reward code. You have 7 days to claim your Level 2 voucher reward from the date we sent you your email. 
                </p>
                <p>
                If you have won a Level 2 voucher reward and not received your One Time Passcode to your email address to claim your Level 2 voucher reward in the first instance, please check your junk / spam folders. If you still can't locate your code please contact our consumer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>
                </p>
              `},{
                question: "26.	What codes do I need to enter to participate",
                answer: `
                <p>
                If you live in England, Scotland, Wales or Northern Ireland you must enter both the barcode and batch code as part of your entry, which can be found on the back of your promotional Cadbury product. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found on the back of your pack located directly under the “Best Before” date.                
                </p>  
                <p>
                No purchase necessary (NPN) for Republic of Ireland residents only. See <a href="/terms-and-conditions#clause-12" target="_blank" rel="noopener noreferrer">clause 12 (D)</a> in the terms and conditions for how to enter.                
                </p>
              `},{
                question: "27.	What if I cannot read my barcode or batch code?",
                answer: `
                <p>
                In some circumstances the batch code can be illegible or difficult to read. Please contact our customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>                
                </p>
              `},{
                question: "28.	My barcode or batch code isn't working, am I entering the right codes?",
                answer: `
                <p>
                It can be confusing as bars often feature many codes on the back of the pack for production reasons. Please double check you are entering the correct code within the required form field.
                The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found on the back of your pack located directly under the “Best Before” date.
                </p>
              `}
              ,{
                question: "29.	I've already entered my barcode & batch code; can I enter it again for another chance to win?",
                answer: `
                <p>
                Each entry needs to be accompanied by a new purchase (UK only), as the same code cannot be entered multiple times. Entries are limited to one entry per day per person and there is a maximum of 20 entries per person during the entire Promotion Period.                
                </p>
              `}
              ,{
                question: "30.	My barcode or batch code has been declined online and I get an error message, what can I do?",
                answer: `
                <p>
                If the error message is 'This barcode / batch code is not recognised', please check that you're trying to enter the right code. Bars often feature many codes on the back of the pack for production reasons. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found on the back of your pack located directly under the “Best Before” date.                
                </p>
                <p>
                If the error message is 'This barcode / batch code has already been used', remember users are limited to one entry per person/email address per day. Only your first entry each day will be counted. Entries are limited to a maximum of twenty entries per person over the promotion period. 
                </p>
                <p>
                If you've not entered the codes before, please contact the customer care team at <a href="mailto:consumerservices@cadburywinaday.com?subject=Win%20a%20Day%20to%20Remember&body=Your%20full%20name%3A%0D%0A%0D%0AEmail%20address%3A%0D%0A%0D%0AMobile%20number%3A%0D%0A%0D%0AYour%20enquiry%3A" target="_blank" rel="noopener noreferrer">consumerservices@cadburywinaday.com</a>. 
                </p>
              `}
              ])
            }
          </div>
         
        </div>}  
      </div>
    </section>
  </>;
}