import React, { useRef } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './TextField.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {
  const inputRef = useRef();
  const classMap = {
    "textfield": true,
    "textfield--select": true,
    "textfield--error": props.hasErrors,
  };

  function onChange(event) {
    if (props.onChange) {

      if(props.selectAnalytics){
        var index = event.nativeEvent.target.selectedIndex;
        var analytics = props.selectAnalytics;

        analyticsService.logEvent(analytics.evnt, analytics.category, {
          location: analytics.location ? analytics.location : "",
          label: analytics.label ? analytics.label : event.nativeEvent.target[index].text,
          url: analytics.url ? analytics.url : ""
        });
        props.onChange(event.target.value);

      } else {
        props.onChange(event.target.value);
      }
    }
  }

  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  return (
    <div className={classList(classMap)}>
      <label
        className="textfield__label"
        htmlFor={props.id}>
        {props.label}
      </label>
      <div className="textfield__input-wrapper">
        <select
          className="textfield__input textfield__input--select"
          type="text" 
          id={props.id} 
          name={props.id}
          ref={inputRef}
          placeholder={props.placeholder}
          onChange={onChange}
          readOnly={props.readonly}
          disabled={props.readonly}
        >
          {props.children}
        </select>
        <span className="textfield__focus"></span>
      </div>
      
    </div>
  );
}
