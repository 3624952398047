import React, { useState, useEffect, useRef } from 'react';

import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import OTPConfirmValidatior from 'validators/OTPConfirmValidatior';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';

import Button from 'components/Button/Button';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';
import otpService from 'services/otpService';


import './Form.scss';
import OTPInput from 'components/Forms/OTPInput';

export default (props) => {
  let settingsContext = useSettingsContext();
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [otp, setOtp] = useState('');
  let [actionId, setActionId] = useState(props.actionId);
  let [otpLength, setOtpLength] = useState(props.otpLength);
  
  let [flowId, setFlowId] = useState();
  let [flowToken, setFlowToken] = useState();
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);
  let [showValidationModal, setShowValidationModal] = useState(false);

  let competitionEntryContext = useCompetitionEntryContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsMopup = compPhaseId === "mop-up";

  let otpConfirmRef = useRef();


  useEffect(()=>{
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
  }, [validation])

  
  useEffect(()=>{
    if(props.clearData){
      resetValues();
    }
  }, [props.clearData])
  

  
  const createCommand = () => {
    let model = {
      otp,
      flowId: props.flowId ? props.flowId : null,
      entryFlowToken: props.entryFlowToken ? props.entryFlowToken : "",
      actionId,
      country: props.country ? props.country : settings.country,
      recaptcha2,
      competitionEntryId: props.competitionEntryId ? props.competitionEntryId : null,
      inputVal: props.inputVal ? props.inputVal : "",
      cxId: props.cxId ? props.cxId : "",
      email: props.email? props.email : null,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      tier1PrizeCategory: competitionEntryContext.prize? competitionEntryContext.prize.id : null,
      //tier1PrizeCategory: !competitionIsMopup ? competitionEntryContext.prize.id : null,
    };

    return model;
  }


  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
              window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
              });
          });
        }
      }catch{}
  }, 1000);
  });

  async function resetValues()  {

    if((validation.errors.hasErrors && (validation.errors.otp && validation.errors.otp !== undefined)) || props.clearData) {
      otpConfirmRef.current.reset();
      setOtp("");
    }
    
    setRecaptcha2(null);
    window.grecaptcha.reset();
  }

  const onFormSuccess = (result) => {
    if(props.onFormSuccess) {
      props.onFormSuccess();
    }
  }

  const onDismissModal = () => {
    
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    resetValues();
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  const service = (command) => {
    props.onSubmit(command);
  }
  
  /* RENDER */
  return <>  

    <Form 
      id="onetimepasswordconfirm"
      submitTitle="Confirm"
      onSuccess={onFormSuccess}
      formClass="form form--slim"
      command={{
        model: createCommand,
        service: service
        //service: otpService.resendOTP
      }}
      validation={{
        validator: OTPConfirmValidatior,
        onValidation: setValidation
      }}
      disableOTPFields={props.disableOTPFields}
      formRef={otpConfirmRef}
    >
      
      { validation.errors.hasErrors && validation.acknowledged &&
        <ValidationSummary 
          errors={validation.errors}
          handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
        />
      }
      <fieldset className="form__fieldset">
        {
          otpLength === 8 &&
          <label className="textfield__label">8 digit claim verification pass code</label>
        }
        <OTPInput
          onChange={createOnChangeHandler(validation, 'otp', setOtp)}
          value={otp}
          readonly={props.disableOTPFields}
          clearData={props.clearData}
          hasErrors={(!!validation.errors.otp)}
          otpLength={otpLength}
        />
        <ValidationMessage errors={validation.errors.otp} />
        {
          otpLength === 8 &&
          <label className="textfield__label">You can find this code in your email</label>
        }
      </fieldset>

      <fieldset className="form__fieldset form__fieldset--captcha">
        <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
        <div className="g-recaptcha-v2" data-sitekey="6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB"
        data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
        <ValidationMessage errors={validation.errors.recaptcha2} /> 
      </fieldset>

    </Form>

    { showValidationModal &&
        <Modal 
          panel 
          hideClose
          show={showValidationModal}
          icon={AlertIcon}
          alt="Warning sign"
          onDismiss={onDismissModal}
          onConfirm={onDismissModal}
          title="Oops">
          <ValidationSummary 
            modal
            defaultMessage="Please check you have entered all fields correctly."
            errors={validation.errors}
            handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
          />
          { !validation.errors.globalErrors.length ? (
              <Button title="Try again" onClick={onDismissModal} />
            ) : (
              <Button title="Back to homepage" href="/" />
            )
          }
        </Modal>
      }

  </>;
}