import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import OTPEntryVerificationValidatior from 'validators/OTPEntryVerificationValidatior';

import Button from 'components/Button/Button';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';


import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';
import PhoneInput from 'components/Forms/PhoneInput';
import Checkbox from 'components/Forms/Checkbox';


export default (props) => {
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [inputEmail, setInputEmail] = useState("");
  let [inputEmailConfirm, setInputEmailConfirm] = useState("");
  let [inputPhoneNumber, setInputPhoneNumber] = useState("");

  let [showValidationModal, setShowValidationModal] = useState(false);

  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);

  let otpEntryRef = useRef();

  useEffect(()=>{
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
  }, [validation]);

  useEffect(()=>{
    if(props.clearData){
      resetValues();
    }
  }, [props.clearData])

  useEffect(()=>{
    if(props.clearCaptcha){
      resetCaptcha();
    }
  }, [props.clearCaptcha])
  
  
  const createCommand = () => {
    let model = {
      inputEmail,
      inputEmailConfirm,
      inputPhoneNumber,
      country: settings.country,
      recaptcha2,
      terms,
      legalAge,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      tier1PrizeCategory: competitionEntryContext.prize? competitionEntryContext.prize.id : null,
    };

    return model;
  }


  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);
  });

  async function resetValues()  {
    if((validation.errors.hasErrors && (validation.errors.otp && validation.errors.otp !== undefined)) || props.clearData) {
      otpEntryRef.current.reset();
      setInputEmail("");
      setInputEmailConfirm("");
      setInputPhoneNumber("");
    }
    
    setRecaptcha2(null);
    window.grecaptcha.reset();
  }

  async function resetCaptcha()  {
    setRecaptcha2(null);
    window.grecaptcha.reset();
  }

  const onFormSuccess = (result) => {
    if(props.onFormSuccess) {
      props.onFormSuccess();
    }    
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    resetValues();
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }


  const service = (command) => {
    props.onSubmit(command);
  }

  /* RENDER */
  return <>  

<Helmet>
      <meta charSet="utf-8" />
      <title>Entry Verification | Cadbury - Win a Day to Remember</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center otp-validation">
        <h1 className="type-h1">Entry verification</h1> 
        
      
        <div className="form__intro">
            <p>We need to send you a one time passcode to your mobile to qualify your entry. </p>
            <p>Please confirm your details below:</p>
          </div>
        <Form 
          id="onetimepasswordtrigger"
          submitTitle="Confirm"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: service
            //service: otpService.requestOTP
          }}
          validation={{
            validator: OTPEntryVerificationValidatior,
            onValidation: setValidation
          }}
          formRef={otpEntryRef}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label={"* Email"}
              id="inputEmail"
              type={"email"}
              onChange={createOnChangeHandler(validation, 'inputEmail', setInputEmail)}
              value={inputEmail}
              hasErrors={!!validation.errors.inputEmail}
              autoComplete="off"
              maxCharLenght ={150}
            />
            <ValidationMessage errors={validation.errors.inputEmail} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label={"* Email Confirm"}
              id="inputEmailConfirm"
              type={"email"}
              onChange={createOnChangeHandler(validation, 'inputEmailConfirm', setInputEmailConfirm)}
              value={inputEmailConfirm}
              hasErrors={!!validation.errors.inputEmailConfirm}
              autoComplete="off"
              maxCharLenght ={150}
            />
            <ValidationMessage errors={validation.errors.inputEmailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <PhoneInput
                label={"* Mobile Phone Number"}
                onChange={createOnChangeHandler(validation, 'inputPhoneNumber', setInputPhoneNumber)}
                value={inputPhoneNumber}
                hasErrors={!!validation.errors.inputPhoneNumber}
                country={settings.country}
                />
                <ValidationMessage errors={validation.errors.inputPhoneNumber} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="legalAge" 
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
              checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "otp_entry_varification_form", url: legalAge}}
            />
            <ValidationMessage errors={validation.errors.legalAge} />            
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
                id="terms" 
                label="* I've read and agree to the Cadbury Win a Day to Remember promotion&nbsp;<a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>Terms and Conditions</a>&nbsp;and consent to the use of my personal data in accordance with the T&C's, <a href='/terms-and-conditions#transparency-statement' rel='noreferrer noopener' target='_blank'>Transparency Statement</a> and <a href='https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
                hasErrors={validation.errors.hasErrors && !terms}
                onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
                checkboxAnalytics={{evnt: "form_field", category: "form_field_checkbox", location: "otp_entry_varification_form", url: terms}}
              />
              <ValidationMessage errors={validation.errors.terms} />    
          </fieldset>


          <fieldset className="form__fieldset form__fieldset--captcha">
            <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
            <div className="g-recaptcha-v2" data-sitekey="6LfPTuMpAAAAAKBl6Kn-M_MvI9HLGknyuBu_RIoB"
            data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
            <ValidationMessage errors={validation.errors.recaptcha2} /> 
          </fieldset>

          { showValidationModal &&
            <Modal 
              panel 
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Oops">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  <Button title="Back to homepage" href="/" />
                )
              }
            </Modal>
          }
        </Form>

        </div>
    </section>
       
    
  </>;
}