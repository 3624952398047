import React, { useState } from 'react';
import classList from 'react-classlist-helper';
import Button from 'components/Button/Button';
import './PrizeCard.scss';
import Link from 'components/Link/Link';


const PrizeCard = (props) => {
  const [focusClass, setFocusClass] = useState(false);

  const classMap = {
    "prize-card": true,
    "prize-card--gb": !props.isROI
  };

  const handleShowFocus = () => {
    setFocusClass(true);
  }

  const handleHideFocus = () => {
    setFocusClass(false);
  }

  

  return (

    <article className={classList(classMap) + (focusClass ? " focus-active " : "")}>
        <div className={"prize-card__image " + (props.id === "entertainment" ? "prize-card__image--top" : "")}>
          <img src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x"}  alt={props.alt || props.title} />
        </div>
        <div className="prize-card__info">
          <h3 className="prize-card__title">{props.title}</h3>
          <span dangerouslySetInnerHTML={{__html: props.description}}></span>
          <Button
              type="button"
              title="Show details"
              className="button--text-link prize-card__info__cta" 
              onClick={handleShowFocus}          
          />
        </div>

        {
          props.cardDetails &&
          <div className='prize-card__overlay'>
            <Button
                type="button"
                title="X"
                className="button--text-link prize-card__overlay__close" 
                onClick={handleHideFocus}          
            />
            <div className='prize-card__overlay__info'>
              {
                 props.cardDetails.map((detail, index) => {
                  return (
                    <div className='prize-card__overlay__item'>
                      {
                        (detail.detailImage && detail.detailImage !== "") &&
                        <img className={detail.detailImageClass} src={detail.detailImage} alt={detail.detailImageAlt} />
                      }

                      {
                        (detail.detailTitle && detail.detailTitle !== "") &&
                        <p>{detail.detailTitle}</p>
                      }
                    </div>
                  )
                })
              }

            </div>
            <Link
              title={props.linkTitle}
              className="prize-card__overlay__cta"
              to={props.linkUrl}
              target="_blank"
              analytics={{evnt: "link_click", category: "internal_link", location: "prize_carousel_card", label: props.id+" prize details"}}
            >{props.linkTitle}</Link>

          </div>
        }
      </article>

    
  )
}

export default PrizeCard;
