import React, { useEffect, useState } from 'react';
import classList from 'react-classlist-helper';
import './TextField.scss';

import CustomDropdownField from './CustomDropdownField';

import ukFlag from "assets/images/flag-UK.svg";
import roiFlag from "assets/images/flag-IRE.svg";


export default (props) => {

  const [countryCode, setCountryCode] = useState(props.country !== "ROI" ? "+44" : "+353");
  const [countryCodeSelected, setCountryCodeSelected] = useState(props.country !== "ROI" ? "uk" : "roi");
  const [charAllowed, setCharAllowed] = useState(props.country !== "ROI" ? "10" : "9");
  const [phone, setPhone] = useState("");

  const classMap = {
    "textfield": true,
    "textfield--phone": true,
    "textfield--error": props.hasErrors,
  };

  const options = [
    {
      id: 'UK',
      label: 'UK',
      value: "+44",
      image: ukFlag,
      imageAlt: "UK Flag"
    },
    {
      id: 'ROI',
      label: 'ROI',
      value: "+353",
      image: roiFlag,
      imageAlt: "ROI Flag"
    }
];
  
  const handleCountryCode = (event) => {
 
    setCountryCode(event);
    setPhone("");

    if(event === "+44"){
        setCountryCodeSelected("uk");
        setCharAllowed("10");
    } else {
        setCountryCodeSelected("roi");
        setCharAllowed("9");
    }
  }

  function onBlur(event) {
    event.persist();
    var currValue = event.target.value;

    if(currValue.substring(0, 1) === "0"){
        setPhone(event.target.value.substr(1).trim());
    } else {
        setPhone(event.target.value.trim());
    }
    
  }

  function onKeyUp(event) {
    event.persist();

    var currValue = event.target.value;

    if(currValue.substring(0, 1) === "0"){
        currValue = currValue.substr(1).trim();
    }

    setPhone(currValue.slice(0, charAllowed));
  }

  useEffect(() => {
    var response = countryCode + phone;
    
    if(countryCodeSelected === "uk" && response.length === 3){
        response = "";
    } else if(countryCodeSelected === "roi" && response.length === 4){
        response = "";
    }

    props.onChange(response);
  }, [phone, countryCode]);


  useEffect(() => {
   if(props.value === ""){
    setPhone("");
   }
  }, [props.value]);

  return <>

    <div className={classList(classMap)}>
       
        <label
            className="textfield__label"
            htmlFor={"phone"}>
            {props.label}
        </label>
        <div className="textfield__input-wrapper">
            <div className='textfield__inputs'>
                <div className={"textfield__inputs__codes"}>
                    
                    <CustomDropdownField
                      options={options}
                      onChange={handleCountryCode}
                      defaultCountry={props.country}
                    />
                

              {/*  <select
                        className="textfield__input textfield__input--select"
                        type="text" 
                        id="countryCode"
                        name="countryCode"
                        onChange={handleCountryCode}
                    >
                        <option defaultValue value="+44" className="textfield__input--select__uk">+44</option>
                        <option value="+353" className="textfield__input--select__roi">+353</option>
                    </select>*/}
                </div>
                <input
                    className="textfield__input"
                    type={"number"} 
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    onBlur={onBlur}
                    onKeyUp={onKeyUp}
                    maxLength={charAllowed}
                    />
            </div>
            <span className="textfield__focus"></span>
        </div>
       
    </div>
  </>
}