import React from 'react';
import classList from 'react-classlist-helper';
import { Link } from 'react-router-dom';
import './Link.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {
  const classMap = {
    "link": true,
    "link--lozenge": props.lozenge,
    [props.className]: !!props.className,
  };

  const handleOnClick = () => {

    if(props.analytics && (props.href || props.to)){
      let analytics = props.analytics;
      let route = (props.href ? props.href : (props.to ? props.to : "#"));
      
      analyticsService.logEvent(analytics.evnt, analytics.category, {
        location: analytics.location ? analytics.location : "",
        label: analytics.label ?  analytics.label : "",
        url: route
      });
    }

    if(props.onClick){
      props.onClick();
    }
  }

  if(!!props.route){
    
    return (
      <Link
        className={classList(classMap)} 
        to={props.to ? props.to : props.href ? props.href : "#"}
        title={props.title}
        onClick={handleOnClick}
        tabIndex={props.cancelFocus ? "-1":"0"}
      >
        
        {
          props.lozenge ? (
            <span>{props.children}</span>
          ) : (
            props.children
          )
        }
      </Link>
    )
  } else {
      return (<a 
        href={props.href ? props.href : props.to ? props.to : "#"}
        className={classList(classMap)}
        title={props.title}
        tabIndex={props.cancelFocus ? "-1":"0"}
        target={props.target ? props.target : "_self"}
        onClick={handleOnClick}
        {
          ...(props.target !== undefined && props.target === "_blank") && { rel: "noopener noreferrer"}
        } >
           {
            props.lozenge ? (
              <span>{props.children}</span>
            ) : (
              props.children
            )
          }
      </a>
    )
  }
}