import React from 'react';
import classList from 'react-classlist-helper';
import './Checkbox.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {

  const classMap = {
    "checkbox": true,
    "checkbox--error": props.hasErrors,
    "checkbox--simple": props.simpleDisplay,
  };

  function onChange(event) {
    if (props.onChange) {

      if(props.checkboxAnalytics && event.target.checked === true){
        var checkTitle = event.nativeEvent.target.title;
        var analytics = props.checkboxAnalytics;

        analyticsService.logEvent(analytics.evnt, analytics.category, {
          location: analytics.location ? analytics.location : "",
          label: analytics.label ? analytics.label : checkTitle,
          url: analytics.url ? analytics.url : ""
        });

        if(props.dropdownValue){
          props.onChange(event);
        } else {
          props.onChange(event.target.checked);
        }
        

      } else {
        if(props.dropdownValue){
          props.onChange(event);
        } else {
          props.onChange(event.target.checked);
        }
      }
    }
  }

  return (
    <div className={classList(classMap)}>
      <input 
        className="checkbox__input"
        type="checkbox" 
        id={props.id} 
        name={props.id}
        onChange={onChange}
        title={props.label}
        { ...( (props.value !== null && props.value === true) && { checked: "checked" } ) }
      />
      <div className="checkbox__fake">
        <span className="checkbox__focus"></span>
      </div>
      <label className="checkbox__label" htmlFor={props.id} dangerouslySetInnerHTML={{ __html: props.label}}></label>
    </div>
  );
}