import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import timer from 'utils/timer';
import prizeClaimService from 'services/prizeClaimService';
import otpService from 'services/otpService';
import useSettingsContext from 'contexts/useSettingsContext';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import OTPConfirmationForm from 'containers/Forms/OTPConfirmationForm';
import Claim from 'containers/Forms/Claim';
import AlertIcon from 'assets/images/alert.png';
import './OTP.scss';
import OTPConfirmationFormComplete from 'containers/Forms/OTPConfirmationFormComplete';

import analyticsService from 'services/analyticsService';

import {getOtpErrorMessages} from 'resources/otpErrorMessages';

export default (props) => {
    let settings = useSettingsContext();

    let { token } = props;
    let [claim, setClaim] = useState();
    let [country, setCountry] = useState(null);
    const MAX_ATTEMPTS = settings.otpMaxClaimTries;
    let [otpStatus, setOtpStatus] = useState("");
    let [modalStatus, setModalStatus] = useState("");
    let [modalStatusRetry, setModalStatusRetry] = useState(true);

    let [flowId, setFlowId] = useState("");
    let [claimOtp, setClaimOtp] = useState("");
    let [entryFlowToken, setEntryFlowToken] = useState("");
    let [disableOTPFields, setDisableOTPFields] = useState(true);
    let [clearData, setClearData] = useState(false);
    let [competitionEntryId, setCompetitionEntryId] = useState(""); 
    let [otpValidationError, setOtpValidationError] = useState(""); 
    let [currNumAttp, setCurrNumAttp] = useState(0);
        

    useEffect(() => {
        async function fetchData() {
            
            await prizeClaimService.getPrizeClaim(token)
            .then((data) => {                
                if(data) {               
                    setClaim(data);
                    setCountry(data.country);
                    setFlowId(data.flowId);
                    setCompetitionEntryId(data.competitionEntryId);
                    setEntryFlowToken(data.entryFlowToken);                    
                    
                    if(data.canClaim) {
                        if(data.prize.category.publicId === "tier-2"){
                            setOtpStatus("confirm-otp-complete");
                        } else {
                            setOtpStatus("confirm-otp");
                        }
                        setDisableOTPFields(false);
                    } else {
                        setOtpStatus("invalid");
                        setOtpValidationError(data.error.Message);
                    }

                    if(data.country) {
                        sessionStorage && sessionStorage.setItem('cadbury200.country', data.country);
                        settings.setCountry(data.country);
                    }
                    
                } 
            })
            .catch(err => console.log(err)) ;
        }
     
        if (token && !claim) {
          fetchData();
        }
    }, [claim, token]);
    
   if (token && !claim) {
        return (
            <div>Loading...</div>
        )
    }

    function setInvalidState(){
        setOtpValidationError("Something went wrong.");
        clearModalStatus(""); 
        setOtpStatus("invalid");
    }

    async function onConfirmOTPSubmit(data) {
        setModalStatus("Uploading");
        setDisableOTPFields(true);
        setClearData(false);
        await timer.delay(1000);
        
        await otpService.validateOTP(data).then((currOtpData) => {                        

            if (currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS ) {
                setEntryFlowToken(currOtpData.entryFlowToken);
                setClaimOtp(data.otp);
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("claim-form");

            }
            else if(!currOtpData.isValid && currOtpData.error == null) {                
                setOtpValidationError("Something went wrong.");
                clearModalStatus(""); 
                setOtpStatus("invalid");
            }  
            else if (!currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS) {                
                if (currOtpData.error && currOtpData.error.errorCode && currOtpData.error.errorCode === "cs-prize-claim-otp-wrong-code") 
                {                    
                    setCurrNumAttp(currOtpData.numTries);
                    clearModalStatus("NotFound");       
                } else {
                    setOtpValidationError(currOtpData.error.message);
                    clearModalStatus(""); 
                    setOtpStatus("invalid");
                }                             
            } else if (!currOtpData.isValid && currOtpData.numTries >= MAX_ATTEMPTS) {
                clearModalStatus(""); 
                setOtpStatus('TooManyAttempts');
            } 
        })
      
        .catch((err) => {   
            clearModalStatus("");       
            setOtpStatus("invalid");
        });

    }

    async function onConfirmOTPCompleteSubmit(data) {
        setModalStatus("Uploading");
        setDisableOTPFields(true);
        setClearData(false);
        await timer.delay(1000);
        
        await prizeClaimService.addPrizeClaim(data).then((currOtpData) => { 
            
            if (currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS ) {
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("otp-complete-thankyou");
            }
             
            else if (!currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS) {                
                if (currOtpData.error && currOtpData.error.errorCode) 
                {                    
                    var currErrorCode = getOtpErrorMessages(currOtpData.error.errorCode);
                    if(currErrorCode !== undefined && currErrorCode !== null) {

                        clearModalStatus("NotFound");  
                        setOtpValidationError(currErrorCode.message); 
                        setModalStatusRetry(currErrorCode.retry);
                        setCurrNumAttp(currOtpData.numTries);
                        
                    } else {
                        setInvalidState();
                        setOtpValidationError(currErrorCode.message);   
                    }    

                } else {
                    setInvalidState();
                    setOtpValidationError(currOtpData.error.message);
                }                             
            } 

            else if (!currOtpData.isValid && currOtpData.numTries >= MAX_ATTEMPTS) {
                clearModalStatus(""); 
                setOtpStatus('TooManyAttempts');
            } 

            else if(!currOtpData.isValid && currOtpData.error == null) {                
                setInvalidState();
            } 
            
        })
      
        .catch((err) => {
            setInvalidState();
        });

    }

    function onFormSuccessOTPComplete () {
        analyticsService.logEvent("button_click", "claim_tier2_submit", {
            location: "claim_tier2_page",
            label: "claim tier 2 prize",
            url: claim.prize.title
        });
    }

    function onDismissModal () {
        setDisableOTPFields(false);
        setClearData(true);

        timer.delay(500);
        clearModalStatus("");
    }

    function clearModalStatus (status) {
        document.body.classList.remove("overflow-hidden-mediumdown"); 
        setModalStatus(status);  
    }



    function renderStatus(otpStatus) {

        switch (otpStatus) {
            case "confirm-otp-complete": 
            return (<>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Claim your prize | Cadbury - Win a Day to Remember</title>
                </Helmet>
                <section className="layout layout-general layout-wrapper">
                    <div className="layout-container layout-container--slim layout-container--center otp-validation">
                        
                        <h1 className="type-h2">Now claim your {claim.prize.publicId !== "VISB" ? claim.prize.title : "£15 discount voucher off a Visit Britain shop experience, supplied by partner Golden Tours!"}</h1> 
                        <div className='form__intro' >
                            <p>Please complete your details to claim your voucher reward.</p>
                        </div>
                        
                        <OTPConfirmationFormComplete
                            onSubmit={onConfirmOTPCompleteSubmit} 
                            flowId={flowId}
                            actionId="claim"
                            competitionEntryId={competitionEntryId}
                            disableOTPFields={disableOTPFields}
                            clearData={clearData}
                            country={country} 
                            token={token}
                            entryFlowToken={entryFlowToken}
                            onFormSuccess={onFormSuccessOTPComplete}
                        />
                    </div>
                </section>
    
                {
                    modalStatus === "Uploading" &&
                    <Modal loading />
                }
                {
                    modalStatus === "Valid" &&
                    <Modal success />
                }
                {
                    modalStatus === "NotFound" &&
                    <Modal 
                        panel 
                        error
                        icon={AlertIcon} 
                        alt="Warning sign"
                        onConfirm={onDismissModal}
                        title="Oops the details you've entered don't match your entry, please can you try again?">                      
                        {
                            otpValidationError && 
                            <p>{otpValidationError}</p>
                        }
                        
                        {
                            (currNumAttp === (MAX_ATTEMPTS - 2) || currNumAttp === (MAX_ATTEMPTS - 1)) &&
                            <p>
                                Be aware that you only have {(MAX_ATTEMPTS - currNumAttp)} remaining {(MAX_ATTEMPTS - currNumAttp) === 1 ? "attempt" : "attempts"}.
                            </p>
                        }
                        
                        {
                            modalStatusRetry ? (
                                <Button title="Resubmit" onClick={onDismissModal} />
                            ) : (
                                <Button title="Back to homepage" href="/" />
                            )
                        }
                        
                    </Modal>
                }   
                    
                
            </>)
        case "otp-complete-thankyou":
        return (<>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Claim your prize | Cadbury - Win a Day to Remember</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    
                    <h1 className="type-h2">Thank you for claiming. The email with your voucher reward is on its way</h1> 
                    <div className='form__intro'>
                        <p>Thank you for validating your details. <b>Your voucher code has been sent to your email</b>. Don't forget to check your junk folder.</p>
                        <p>We hope you enjoy your reward!</p>
                        <p>To find out more about our Cadbury products and other promotions, please click below.</p>
                    </div>
                    {
                        country !== "ROI" ? (
                        <Link 
                        analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 2 successful", label: "cadbury.co.uk site"}}
                        to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
                    
                        ) : (
                        <Link 
                        analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 2 successful", label: "cadbury.ie site"}}
                        to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
                        )
                    }
                </div>
            </section>
        </>)
        case "confirm-otp": 
        return (<>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Claim your prize | Cadbury - Win a Day to Remember</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    
                    <h1 className="type-h2">Claim your prize</h1> 
                    <div className='form__intro' >
                        <p>Congratulations on winning one of our unforgettable prizes. Before we can share your prize details, we just need to check it's really you!</p>
                    </div>
                    
                    <OTPConfirmationForm
                        onSubmit={onConfirmOTPSubmit} 
                        flowId={flowId}
                        actionId="claimtier1"
                        competitionEntryId={competitionEntryId}
                        disableOTPFields={disableOTPFields}
                        clearData={clearData}
                        country={country}                        
                        otpLength={8}
                        entryFlowToken={entryFlowToken}
                    /> 
                </div>
            </section>

            {
                modalStatus === "Uploading" &&
                <Modal loading />
            }
            {
                modalStatus === "Valid" &&
                <Modal success />
            }
            {
                modalStatus === "NotFound" &&
                <Modal 
                    panel 
                    error
                    icon={AlertIcon} 
                    alt="Warning sign"
                    onConfirm={onDismissModal}
                    title="Mmmm - that hasn’t worked…">                      
                    <p>
                    Please check your code and try again.
                    </p>
                    {
                        (currNumAttp === 3 || currNumAttp === 4) &&
                        <p>
                            Be aware that you only have {(5 - currNumAttp)} remaining {(5 - currNumAttp) === 1 ? "attempt" : "attempts"}.
                        </p>
                    }
                    
                    <Button title="Try again" onClick={onDismissModal} />
                    
                </Modal>
            }   
                
            
        </>)
        case 'claim-form': return (
            <Claim 
                otpAvilable={true}
                token={token}
                otp={claimOtp}
                flowId={flowId}
                entryFlowToken={entryFlowToken}
            />
        )
        case 'TooManyAttempts': return (
            <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Error - Claim your prize | Cadbury - Win a Day to Remember</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    <img src={AlertIcon} alt="Warning sign" className='otp-validation__icon'/>
                    <h1 className="type-h3">OH,  we're sorry but you've entered the wrong details too many times and your claim link has expired.</h1> 
                    <div className='otp-validation__ctas'>
                        <Link title="FAQs" href="/faqs" 
                        analytics={{evnt: "button_click", category: "internal_link", location: "claim otp page - too many attempts", label: "faqs page"}}
                        lozenge route>FAQs</Link>
                    </div>
                </div>
            </section>
            </>
        )
       
        case 'invalid': return (
            <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Sorry - Claim your prize | Cadbury - Win a Day to Remember</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    <img src={AlertIcon} alt="Warning sign" className='otp-validation__icon'/>
                    {
                        (claim && claim.error != null && claim.error.errorCode === "cs-prize-claim-otp-confirmed") ? (
                            <>
                                <h1 className="type-h3">AHHHH, sorry but that claim link has expired. </h1> 
                                <div className="form__intro">
                                   <p>Please enter again for another chance to win! </p>
                                </div>
                                <div className='otp-validation__ctas'>
                                    <Link title="Try again" href="/" lozenge route>Try Again</Link>
                                </div>
                                </>
                        ) : (
                        <>
                            <h1 className="type-h3">Sorry!</h1> 
                            <div className="form__intro">
                                <p>
                                    { claim && claim.error!= null && claim.error.message ? claim.error.message : "Sorry, something went wrong"}                             
                                </p>
                                {
                                    otpValidationError && 
                                    <p>{otpValidationError}</p>
                                }
                            </div>
                            <div className='otp-validation__ctas'>
                            {
                                country !== "ROI" ? (
                                <Link 
                                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 2 invalid", label: "cadbury.co.uk site"}}
                                to="https://www.cadbury.co.uk/" lozenge title="Visit Cadbury.CO.UK" >Visit Cadbury.CO.UK</Link>
                            
                                ) : (
                                <Link 
                                analytics={{evnt: "button_click", category: "main_cadbury_site", location: "claim tier 2 invalid", label: "cadbury.ie site"}}
                                to="https://www.cadbury.ie/" lozenge title="Visit Cadbury.IE" >Visit Cadbury.IE</Link>
                                )
                            }
                                
                            </div>
                        </>)
                    }
                </div>
            </section>
            </>
            
        )
        
        }
        
    }

    return <>
        {
            renderStatus(otpStatus)
        }
        
    </>;
}